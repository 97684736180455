var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
export var List = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .listItem {\n    margin: 5px 0px;\n  }\n"], ["\n  .listItem {\n    margin: 5px 0px;\n  }\n"])));
export var Header = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: inline-flex;\n  align-items: left;\n  justify-content: start;\n  height: 28px;\n  width: 100%;\n  border-radius: 4px;\n  background-color: #d1dae2;\n  margin-bottom: 10px;\n  .selected {\n    font-weight: 600;\n  }\n  p {\n    padding: 5px;\n    margin: 5px;\n    font-weight: 400;\n  }\n  .button {\n    cursor: pointer;\n  }\n  .icon {\n    width: 12.5px;\n    height: 7.2px;\n    margin-left: 5px;\n  }\n"], ["\n  display: inline-flex;\n  align-items: left;\n  justify-content: start;\n  height: 28px;\n  width: 100%;\n  border-radius: 4px;\n  background-color: #d1dae2;\n  margin-bottom: 10px;\n  .selected {\n    font-weight: 600;\n  }\n  p {\n    padding: 5px;\n    margin: 5px;\n    font-weight: 400;\n  }\n  .button {\n    cursor: pointer;\n  }\n  .icon {\n    width: 12.5px;\n    height: 7.2px;\n    margin-left: 5px;\n  }\n"])));
export var ListItem = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: inline-flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  height: 60px;\n  background-color: #ffffff;\n  border-radius: 5px;\n  border: 0px;\n\n  .role {\n    width: 110px;\n  }\n\n  .name {\n    width: 300px;\n  }\n\n  .right {\n    display: flex;\n    justify-content: flex-end;\n    margin: 0;\n    padding: 0;\n    margin-right: 20px;\n    overflow: hidden;\n  }\n\n  .link {\n    color: #133a72;\n    cursor: pointer;\n    font-size: 10px;\n    font-weight: 600;\n    img {\n      margin: 0 6px;\n    }\n  }\n  .trash-box {\n    img {\n      width: 18px;\n      height: 20px;\n    }\n    p {\n      width: 35px;\n    }\n  }\n"], ["\n  display: inline-flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  height: 60px;\n  background-color: #ffffff;\n  border-radius: 5px;\n  border: 0px;\n\n  .role {\n    width: 110px;\n  }\n\n  .name {\n    width: 300px;\n  }\n\n  .right {\n    display: flex;\n    justify-content: flex-end;\n    margin: 0;\n    padding: 0;\n    margin-right: 20px;\n    overflow: hidden;\n  }\n\n  .link {\n    color: #133a72;\n    cursor: pointer;\n    font-size: 10px;\n    font-weight: 600;\n    img {\n      margin: 0 6px;\n    }\n  }\n  .trash-box {\n    img {\n      width: 18px;\n      height: 20px;\n    }\n    p {\n      width: 35px;\n    }\n  }\n"])));
export var Box = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: inline-flex;\n  align-items: center;\n  padding: 5px;\n  margin: 5px;\n  width: ", ";\n  min-width: ", ";\n"], ["\n  display: inline-flex;\n  align-items: center;\n  padding: 5px;\n  margin: 5px;\n  width: ", ";\n  min-width: ", ";\n"])), function (_a) {
    var width = _a.width;
    return width;
}, function (_a) {
    var width = _a.width;
    return width;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

export var ModuleType;
(function (ModuleType) {
    ModuleType["CHECKLIST"] = "CHECKLIST";
    ModuleType["SCALE"] = "SCALE";
    ModuleType["SCHEDULE"] = "SCHEDULE";
    ModuleType["INFORMATION"] = "INFORMATION";
    ModuleType["CALENDAR"] = "CALENDAR";
})(ModuleType || (ModuleType = {}));
export var ModuleDisplayType;
(function (ModuleDisplayType) {
    ModuleDisplayType["SMALL"] = "SMALL";
    ModuleDisplayType["LARGE"] = "LARGE";
})(ModuleDisplayType || (ModuleDisplayType = {}));
export var AuthStatus;
(function (AuthStatus) {
    AuthStatus["INVALID_CREDENTIALS"] = "INVALID_CREDENTIALS";
    AuthStatus["DEFAULT"] = "DEFAULT";
    AuthStatus["AUTHENTICATED"] = "AUTHENTICATED";
    AuthStatus["UNAUTHORIZED"] = "UNAUTHORIZED";
})(AuthStatus || (AuthStatus = {}));
export var Permission;
(function (Permission) {
    Permission["ROOT"] = "ROOT";
    Permission["ADMIN"] = "ADMIN";
    Permission["PRACTITIONER"] = "PRACTITIONER";
    Permission["NONE"] = "NONE";
})(Permission || (Permission = {}));
export var CareView;
(function (CareView) {
    CareView["PATIENT_OVERVIEW"] = "PATIENT_OVERVIEW";
    CareView["PATIENT_EXPORT"] = "PATIENT_EXPORT";
    CareView["ACTIVATE_MONITOR"] = "ACTIVATE_MONITOR";
    CareView["CALENDAR_PRACTITIONER"] = "CALENDAR_PRACTITIONER";
    CareView["CALENDAR_ADMIN"] = "CALENDAR_ADMIN";
    CareView["PERSONNEL_OVERVIEW"] = "PERSONNEL_OVERVIEW";
    CareView["ORGANIZATION_OVERVIEW"] = "ORGANIZATION_OVERVIEW";
    CareView["CHECKLIST_OVERVIEW"] = "CHECKLIST_OVERVIEW";
})(CareView || (CareView = {}));
export var MonitorPairingStatus;
(function (MonitorPairingStatus) {
    MonitorPairingStatus["REJECTED"] = "REJECTED";
    MonitorPairingStatus["ACCEPTED"] = "ACCEPTED";
    MonitorPairingStatus["PENDING"] = "PENDING";
})(MonitorPairingStatus || (MonitorPairingStatus = {}));

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createIntl, createIntlCache } from 'react-intl';
import msgEn from '@translations/en.json';
import msgSv from '@translations/sv.json';
import messages from './messages';
/*
 * The code below is an implementation of Internationalization in non react components
 * Documentation: https://formatjs.io/docs/react-intl/api#createintl
 *
 */
// Object that holds the translations for the different languages (imported from the json files on top of this file )
var i18nMessages = {
    en: msgEn,
    sv: msgSv,
};
var cache = createIntlCache();
var intl = createIntl({
    locale: navigator.language,
    messages: i18nMessages[navigator.language.substring(0, 2)],
}, cache);
export var isValidTitle = function (title) { return !!title.match(/^([\S| ]){1,40}$/); };
export var getTitleWarningText = function (title) {
    return isValidTitle(title)
        ? ''
        : title
            ? intl.formatMessage(messages.text40MaxLengthValidationError)
            : intl.formatMessage(messages.nameChecklistValidationError);
};
export var validatedTitle = function (title) { return ({
    value: title,
    valid: isValidTitle(title),
    warning: getTitleWarningText(title),
}); };
export var isValidDescription = function (description) { return !!description.match(/^([\S| ]){1,40}$/); };
export var getDescriptionWarningText = function (description) {
    return isValidDescription(description)
        ? ''
        : description
            ? intl.formatMessage(messages.text40MaxLengthValidationError)
            : intl.formatMessage(messages.descriptionChecklistValidationError);
};
export var validatedDescription = function (description) { return ({
    value: description,
    valid: isValidDescription(description),
    warning: getDescriptionWarningText(description),
}); };
export var isValidItemText = function (text) { return !!text.match(/^([\S| ]){1,250}$/); };
export var getItemTextWarningText = function (text) {
    return isValidItemText(text)
        ? ''
        : text
            ? intl.formatMessage(messages.text250MaxLengthValidationError)
            : intl.formatMessage(messages.missingTextChecklistValidationError);
};
export var validatedItem = function (item) { return ({
    value: item,
    valid: isValidItemText(item.text),
    warning: getItemTextWarningText(item.text),
}); };
export var validatedItemList = function (items) { return ({
    value: items.map(function (i) { return validatedItem(i.value); }),
    valid: items.length > 0,
    warning: items.length > 0 ? '' : intl.formatMessage(messages.missingContentChecklistValidationError),
}); };
export var validate = function (form) {
    var validated = __assign({}, form);
    var isValid = true;
    validated.title = validatedTitle(form.title.value);
    if (!validated.title.valid) {
        isValid = false;
    }
    validated.description = validatedDescription(form.description.value);
    if (!validated.description.valid) {
        isValid = false;
    }
    validated.items = validatedItemList(form.items.value);
    if (!validated.items.valid) {
        isValid = false;
    }
    validated.items.value.forEach(function (item, idx) {
        item.value.order = idx;
        if (!item.valid) {
            isValid = false;
        }
    });
    validated.valid = isValid;
    return validated;
};
export var toRequestData = function (form) {
    return {
        id: form.id,
        identifier: form.identifier,
        title: form.title.value,
        description: form.description.value,
        items: form.items.value.map(function (i) { return i.value; }),
        status: form.status,
        version: form.version,
    };
};

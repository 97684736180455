import React, { PureComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle } from '@fortawesome/free-regular-svg-icons';
import PropTypes from 'prop-types';
import { EventLabelIcon } from './EventLabelIcon';

export default class EventParticipantsBox extends PureComponent {
  static propTypes = {
    title: PropTypes.node,
    iconName: PropTypes.string,
    disabled: PropTypes.bool,
    invalid: PropTypes.bool,
    errorMessage: PropTypes.node,
    selectable: PropTypes.array.isRequired,
    onValueChange: PropTypes.func,
  };

  render() {
    return (
      <div>
        <label>
          <EventLabelIcon name={this.props.iconName} />
          <span>{this.props.title}</span>
        </label>
        <div className={'selected-users'}>
          {this.props.selectable.map((user) => (
            <div className={'selected-user'} key={`event-pane-selected-patient_${user.id}`}>
              <span className="selected-user-name">{user.displayName}</span>
              <div className="selected-user-unselect" onClick={() => this.props.onValueChange(user.id, user.selected)}>
                <FontAwesomeIcon icon={user.selected ? faCheckCircle : faCircle} />
              </div>
            </div>
          ))}
        </div>
        {this.props.invalid && this.props.errorMessage && <span className="error">{this.props.errorMessage}</span>}
      </div>
    );
  }
}

import * as actions from '../constants/actions';

const initialState = { show: false, message: '', title: '' };

export default function NotificationsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SHOW_NOTIFICATION: {
      return {
        ...state,
        show: true,
        message: action.data.message,
        title: action.data.title,
      };
    }

    case actions.HIDE_NOTIFICATION: {
      return {
        ...state,
        show: initialState.show,
        message: initialState.message,
        title: initialState.title,
      };
    }

    case actions.CLEAR_STATE: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
}

import { useContext } from 'react';
import { ErrorActionContext } from './ErrorProvider';
var useErrorAction = function () {
    var context = useContext(ErrorActionContext);
    if (context === undefined) {
        throw new Error('useErrorAction must be used within a ErrorProvider');
    }
    return context;
};
export default useErrorAction;

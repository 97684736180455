var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import InformationBox from './InformationBox';
import iconEdit from '../../resources/img/ikon-andra-200.png';
import BedRoomModal from 'components/Boarding/BedRoomModal';
import globalMessages from '@src/globalMessages';
import messages from '@src/pages/PatientListPage/PatientList/messages';
var BedBox = function (_a) {
    var _b;
    var patient = _a.patient;
    var _c = useState(false), showModal = _c[0], setShowModal = _c[1];
    var formatMessage = useIntl().formatMessage;
    return (_jsxs(_Fragment, { children: [_jsx(InformationBox, __assign({ textLeft: formatMessage(messages.room), textRight: formatMessage(globalMessages.edit), icon: iconEdit, iconStyle: { width: '9px', height: '9px', marginRight: '6px' }, onBoxClick: function () { return setShowModal(true); }, onLinkClick: function () { return setShowModal(true); } }, { children: _jsx(StyledBedBox, __assign({ className: "bedBox" }, { children: _jsxs("p", { children: [((_b = patient.room) === null || _b === void 0 ? void 0 : _b.name) || '', patient.bed ? ":".concat(patient.bed.name) : ''] }) })) })), showModal && _jsx(BedRoomModal, { patient: patient, dismiss: function () { return setShowModal(false); } })] }));
};
export default BedBox;
var StyledBedBox = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  p {\n    font-size: 20px;\n    font-weight: bold;\n    line-height: 27px;\n    letter-spacing: 0.09px;\n    margin: 0;\n    padding: 16px 15px 15px 15px;\n  }\n"], ["\n  color: ", ";\n  p {\n    font-size: 20px;\n    font-weight: bold;\n    line-height: 27px;\n    letter-spacing: 0.09px;\n    margin: 0;\n    padding: 16px 15px 15px 15px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.cello;
});
var templateObject_1;

import React from 'react';
import styled from '@emotion/styled';

export default class Privacy extends React.PureComponent {
  render() {
    return (
      <PrivacyWrapper>
        <h1>Integritetspolicy</h1>
        <h2>1. Introduktion</h2>
        <p>
          Zengio (”<b>Tjänsten</b>”) är en applikation som används av patienter och personal hos vårdgivare för att under ett besök på en vårdenhet förenkla
          kommunikationen mellan personal och patienter.
        </p>
        <p>
          I denna integritetspolicy redogörs för hanteringen av personuppgifter och hur Tjänsten fungerar för användaren som besöker vårdenheten
          (”<b>du</b>”/ ”<b>patienten</b>”) samt vem som ansvarar för den personuppgiftsbehandling som sker i samband med att Tjänsten används.
        </p>
        <p>
          Integritetspolicyn beskriver även vilka personuppgifter som behandlas när Tjänsten används, hur personuppgifterna behandlas och varför. Här
          beskrivs också vilket lagstöd som motiverar behandlingen i fråga samt vilka utomstående parter som kan komma att hantera patienternas
          personuppgifter för att Tjänsten ska kunna tillhandahållas. Vidare ges även information om patientens rättigheter och hur en patient kan gå
          tillväga för att utöva dessa rättigheter.
        </p>
        <h2>2. Vem är ansvarig för personuppgiftsbehandlingen?</h2>
        <p>
          Personuppgiftsansvarig för behandlingen av de personuppgifter som registreras i Tjänsten är den vårdgivare som patienten är under besök hos
          och som är ansvarig för utförandet av den hälso- och sjukvård som patienten får (”<b>Vårdgivare</b>”). För information om vem som är din Vårdgivare
          och personuppgiftsansvarig för behandling av dina personuppgifter inom ramen för Tjänsten vänligen fråga personalen på den vårdenhet du
          besöker.
        </p>
        <p>
          Chorus AB, org.nr 556739–7236, (”<b>Chorus</b>”) äger och tillhandahåller Tjänsten i egenskap av tjänsteleverantör och agerar därför som
          personuppgiftsbiträde för behandling av patientens personuppgifter som sker inom ramen för Tjänsten. Detta då Chorus enbart behandlar
          personuppgifter på instruktion från Vårdgivaren.
        </p>
        <h2>3. Vilka personuppgifter behandlas i Tjänsten och varifrån kommer de?</h2>
        <h3>3.1 Personuppgifter som Vårdgivaren registrerar och som delas med Tjänsten</h3>
        <p>Vårdgivaren registrerar följande personuppgifter i samband med att patienten läggs till i Tjänsten:</p>
        <ul>
          <li>För- och efternamn</li>
          <li>Personnummer</li>
          <li>Ålder</li>
          <li>Diagnoskod</li>
          <li>Vilket rum patienten befinner sig i</li>
          <li>Lista över mediciner som patienten får</li>
        </ul>
        <h3>3.2 Personuppgifter som patienten registrerar och som delas med Vårdgivaren</h3>
        <p>
          I samband med att patienten använder Tjänsten delar denne de personuppgifter som Vårdgivaren efterfrågar med Vårdgivaren. Dessa
          personuppgifter är:
        </p>
        <ul>
          <li> Upplevd smärta över tid</li>
          <li> Svar på checklista utformad av Vårdgivaren</li>
        </ul>
        <h2>4. För vilka syften behandlas personuppgifterna och med vilken laglig grund?</h2>
        <h3>4.1 Vårdgivarens behandling av personuppgifter</h3>
        <p>Vårdgivaren behandlar personuppgifter i Tjänsten för att kunna</p>
        <ul>
          <li>Ge patienten tillgång till användning av Tjänsten</li>
        </ul>
        <p>
          Vårdgivaren behandlar dina personuppgifter i syfte att kunna tillhandahålla Tjänsten och möjliggöra att du som patient kan använda Tjänsten
          samt att Vårdgivaren får del av den viktiga information som tillhandahålls genom Tjänsten. Detta sker med stöd av ditt samtycke.
        </p>
        <ul>
          <li>Upprätthålla korrekta och uppdaterade uppgifter om patienten</li>
          <li>För att kunna uppfylla sina skyldigheter enligt lag</li>
        </ul>
        <p>
          Vårdgivaren behandlar även dina personuppgifter inom ramen för den hälso- och sjukvård och annan nödvändig behandling eller rådgivning som
          ges dig. Som vårdgivare lyder verksamheten under gällande nationell lagstiftning varför personuppgifter som rör hälsotillstånd, och övrig
          därtill nödvändig information, behandlas med stöd av gällande lag exempelvis Patientdatalagen (2008:355). I Vårdgivarens rättsliga
          skyldigheter ingår att de smärtskattningar som patienten tillhandahållit Vårdgivaren i Tjänsten journalförs i vårdgivarens journalsystem
          innan utskrivning från Tjänsten sker. Uppgifterna lagras i vårdgivaren journalsystem då vårdgivaren är skyldig att spara journaluppgifter
          under en viss tid. Därutöver kommer Vårdgivaren att behandla personuppgifter i den utsträckning som behövs för att efterleva eventuella
          domar eller myndighetsbeslut (t.ex. avseende krav från Inspektionen för Vård och Omsorg, IVO eller Socialstyrelsen).
        </p>
        <ul>
          <li>För att kvalitetssäkra vården och Tjänsten</li>
        </ul>
        <p>Vårdgivaren behandlar även personuppgifter i syfte att utveckla och förbättra vården och i förekommande fall Tjänsten.</p>
        <p>
          Känsliga personuppgifter behandlas endast i syfte att kunna tillhandahålla Tjänsten, vården som ges samt för att kunna kvalitetssäkra och
          utveckla vården i enlighet med gällande lagstiftning. Övrig vidareutveckling av Tjänsten sker med stöd av avidentifierade uppgifter.
        </p>
        <ul>
          <li>För att skydda sig eller andra, inklusive dig, mot rättsliga anspråk eller för att göra gällande rättsliga anspråk.</li>
        </ul>
        <p>
          Vårdgivaren kan även komma att behandla patientens personuppgifter för det fall det skulle behövas för att göra gällande eller försvara mot
          rättsliga anspråk. Denna behandling sker i sådant fall med stöd i Vårdgivarens berättigade intresse av att kunna försvara sig mot eller göra
          gällande rättsliga anspråk.
        </p>
        <h3>4.2 Chorus behandling av personuppgifter</h3>
        <p>Chorus behandlar endast dina personuppgifter på instruktion från Vårdgivaren.</p>
        <p>
          Chorus främsta uppdrag förutom att tillhandahålla Tjänsten till dig och Vårdgivaren är att kvalitetssäkra och utveckla Tjänsten inklusive
          att öka säkerheten och utvärdera hanteringen av personuppgifter. Chorus kan genom detta behandla (tekniskt bearbeta och tekniskt lagra)
          känsliga personuppgifter om dig som patient i syfte att kvalitetssäkra och utveckla Tjänsten i enlighet med gällande lagstiftning. Denna
          behandling av känsliga personuppgifter sker dock enbart inom ramen för Vårdgivarens instruktion och på uppdrag av Vårdgivaren.
        </p>
        <h2>5. Tredje parter som personuppgifter kan komma att delas med när Tjänsten används</h2>
        <h3>5.1 Underleverantör till Chorus</h3>
        <p>
          För att kunna erbjuda Tjänsten används en extern underleverantör som behandlar personuppgifter. Det gäller driftleverantören av den
          molnplattform som Tjänsten lagras på, Elastx org.nr 556906-5617. Elastx arbetar på uppdrag och instruktion av Vårdgivaren och Chorus i egenskap av s.k.
          underbiträde till Chorus. Chorus kommer att iaktta tillämplig dataskyddslagstiftning vid anlitande av underleverantörer och kommer att
          ansvara gentemot Vårdgivaren för underleverantörernas skyldigheter.
        </p>
        <h3>5.2 Underleverantörer till Vårdgivaren</h3>
        <p>
          Vårdgivaren tillser att journal förs inom ramen för Tjänsten i enlighet med gällande lagstiftning. Journalerna sparas i journalsystem
          utanför Tjänsten hos en tredje part på uppdrag av Vårdgivaren och enligt Vårdgivarens instruktioner. Vårdgivaren är ansvarig för de
          personuppgifter som lagras i journalerna.
        </p>
        <h2>6. Var lagras personuppgifter och hur länge?</h2>
        <p>
          Personuppgifter som samlas in via Tjänsten lagras på en säker server i Sverige. Vare sig Tjänsten används på en av Vårdgivarens mobila
          enheter eller i de fall då patienten väljer att använda en egen mobil enhet, lagras inga personuppgifter lokalt på den mobila enheten. Under
          tiden patienten är inskriven och använder Tjänsten finns personuppgifter tillgängliga i Tjänsten. När patienten skrivs ut från Tjänsten kan
          denne inte längre använda Tjänsten.
        </p>
        <p>
          Efter journalföring av Vårdgivaren (personuppgiftsansvarig) och trettio dagar efter utskrivning av patienten från Tjänsten tas alla
          personuppgifter bort från de uppgifter som behandlats i Tjänsten. Därefter kommer följande uppgifter att kvar:
        </p>
        <ul>
          <li>Information om att en man/kvinna i en viss ålder har behandlats för en diagnos</li>
        </ul>
        <ul>
          <li>Läkemedelslista</li>
        </ul>
        <ul>
          <li>Vilka eventuella smärtskattningar som patienten har registrerat.</li>
        </ul>
        <p>
          Dessa avidentifierade uppgifter har Vårdgivaren tillgång till i sin applikation och behandlas av Vårdgivaren för kvalitetssäkring av vården.
        </p>
        <p>
          Personuppgifter hanteras därmed endast så länge det är nödvändigt för de ändamål som uppgifterna samlats in för d.v.s. så länge det behövs
          för att kunna tillhandahålla god vård eller i övrigt kunna leverera Tjänsten eller för att uppfylla de rättsliga skyldigheter som åligger
          Vårdgivaren.
        </p>
        <p>
          Vårdgivaren har en rättslig skyldighet att spara patientjournaler under en viss specifik tid. Chorus har därför på instruktion från
          Vårdgivaren rutiner för hur personuppgifter lagras och avidentifieras.
        </p>
        <p>
          För förtydligande, när patienten skrivs ut kan denne inte längre använda Tjänsten, inga personuppgifter lagras på den mobila enheten. De
          uppgifter som lagras på den mobila enheten är påminnelser att smärtskatta, dessa försvinner vid radering av Tjänsten på enheten eller
          inaktivering av notifieringar för Tjänsten.
        </p>
        <p>
          Personuppgifter lagras, efter patientens utskrivning, på en server och raderas eller avidentifieras senast trettio dagar från det att
          Vårdgivaren skrivit ut patienten från Tjänsten. Viss information kan dock komma att sparas längre av Vårdgivaren i dennes journalsystem med
          hänsyn till de lagar om hälso- och sjukvård som Vårdgivaren måste följa.
        </p>
        <p>
          Vårdgivaren strävar alltid efter att personuppgifterna ska behandlas på servrar belägna inom EU/EES. Vårdgivaren har dock under vissa
          omständigheter rätt att överföra patienters personuppgifter till molntjänster belägna utanför EU/EES, under förutsättning att (i)
          mottagarlandet säkerställer en adekvat skyddsnivå för personuppgifter enligt ett beslut meddelat av EU-kommissionen, (ii) Vårdgivaren
          säkerställer att det finns lämpliga skyddsåtgärder på plats enligt tillämplig dataskyddslagstiftning; eller (iii) om något annat undantag
          enligt tillämplig dataskyddslagstiftning föreligger.
        </p>
        <h2>7. Patientens rättigheter rörande sina personuppgifter</h2>
        <p>
          Patienten har rätt att erhålla information om vilka av personuppgifter som Vårdgivaren och Chorus behandlar, i vilket syfte de behandlas
          samt vilka tredje parter som mottagit patientens personuppgifter. För att förtydliga kan du som patient närsomhelst vända dig till
          Vårdgivaren för att:
        </p>
        <ul>
          <li>
            Begära tillgång till och få information om vilka personuppgifter som behandlas i samband med användandet av Tjänsten. I den mån det är
            möjligt enligt lag har du rätt att få en kopia av dina personuppgifter gratis
          </li>
          <li>
            Begära att dina personuppgifter raderas. Notera dock att Vårdgivaren har skyldigheter enligt lag att spara vissa personuppgifter, särskilt
            relaterade till hälsoinformation inklusive att föra journal i samband med nyttjande av Tjänsten På din förfrågan kommer alla
            personuppgifter rörande dig som inte måste lagras enligt en rättslig skyldighet att raderas, vilket annars görs automatiskt trettio dagar
            efter utskrivning)
          </li>
          <li>Invända mot behandling av personuppgifter som sker med stöd av våra berättigade intressen</li>
          <li>
            Begära att få flytta dina personuppgifter till annan personuppgiftsansvarig genom att erhålla dina personuppgifter, i den omfattning som
            de tillhandahållits från dig, i ett elektroniskt format som är allmänt använt för att kunna överföra dem till annan part
          </li>
          <li>
            Återkalla det samtycke som givits. Notera dock att detta återkallande inte påverkar lagligheten i behandlingen innan samtycket
            återkallades
          </li>
          <li>
            Rätta felaktiga uppgifter, i den mån personuppgiftslagstiftningen inom hälso- och sjukvården tillåter Vårdgivaren att tillmötesgå en
            begäran om rättelse eller komplettering av ofullständiga personuppgifter
          </li>
          <li>Begränsa behandlingen till dess att en invändning har hanterats</li>
        </ul>
        <h2>8. Rätt att klaga till tillsynsmyndigheten</h2>
        <p>
          Vi vill här även upplysa om att patienten har rätt att ge in klagomål till Datainspektionen eller annan relevant tillsynsmyndighet för det
          fall denne anser att personuppgiftsbehandlingen är felaktig och inte uppfyller lagkraven.
        </p>
        <h2>Frågor?</h2>
        <p>
          Vi hoppas att vi i denna Integritetspolicy har tydliggjort hur dina personuppgifter hanteras i samband med att Tjänsten används, men om
          någonting är oklart vänligen ta kontakt med Vårdgivaren för mer information.
        </p>
      </PrivacyWrapper>
    );
  }
}

const PrivacyWrapper = styled.div`
  max-width: 800px;
  margin: 20px auto;
  padding: 0 1em;
  h2 {
    font-size: 18px;
    font-weight: bold;
  }
  h3 {
    font-size: 16px;
    font-weight: bold;
  }
  li {
    margin: 5px 0;
  }
  p,
  li {
    font-size: 16px;
    line-height: 20px;
  }
`;

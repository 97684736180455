import { useReducer } from 'react';
import produce from 'immer';
import { PatientListTypeKeys } from './types';
var initialState = {
    patients: undefined,
    showAtHome: false,
};
var patientListReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    return produce(state, function (draft) {
        switch (action.type) {
            case PatientListTypeKeys.SET_PATIENTS:
                draft.patients = action.patients;
                return;
            case PatientListTypeKeys.SET_SHOW_AT_HOME:
                draft.showAtHome = action.value;
                return;
            case PatientListTypeKeys.CLEAR_PATIENT_LIST:
                return initialState;
        }
    });
};
export var usePatientList = function () {
    var _a = useReducer(patientListReducer, initialState), state = _a[0], dispatch = _a[1];
    return [state, dispatch];
};

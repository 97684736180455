var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Redirect, Route, Switch } from 'react-router-dom';
import useAuthState from '@hooks/useAuth/useAuthState';
import { canShowCareView, isRoot } from '@helpers/permissions';
import Support from '@components/Support';
import CalendarContainerHelper from '@containers/CalendarContainer/CalendarContainerHelper';
import NotFoundContainer from '@containers/NotFoundContainer';
import Privacy from '@components/Privacy/Privacy';
import { CareView } from '@api/auth/types';
import PersonnelPage from '@pages/Admin/PersonnelPage';
import OrganizationPage from '@pages/Admin/OrganizationPage';
import MonitorPage from '@pages/MonitorPage';
import LoginPage from '@pages/LoginPage';
import MonitorConnectPage from '@pages/MonitorConnectPage';
import LogoutPage from '@pages/LogoutPage';
import ChecklistPage from '@pages/Admin/ChecklistPage/ChecklistPage';
import ExportPage from '@pages/Export';
import PatientListPage from '@pages/PatientListPage';
import PatientDetailPage from '@pages/PatientDetailPage';
var getUserStartPage = function (user) {
    if (user.views.includes(CareView.PATIENT_OVERVIEW)) {
        return '/';
    }
    else if (user.views.includes(CareView.PERSONNEL_OVERVIEW)) {
        return '/admin/personnel';
    }
    else {
        return '/';
    }
};
var LoginRoute = function (_a) {
    var children = _a.children, user = _a.user, rest = __rest(_a, ["children", "user"]);
    return (_jsx(Route, __assign({}, rest, { render: function (_a) {
            var location = _a.location;
            return user ? (_jsx(Redirect, { to: {
                    pathname: getUserStartPage(user),
                    state: { from: '/login' },
                } })) : (children);
        } })));
};
var RestrictedRoute = function (_a) {
    var children = _a.children, user = _a.user, view = _a.view, rest = __rest(_a, ["children", "user", "view"]);
    return (_jsx(Route, __assign({}, rest, { render: function (_a) {
            var location = _a.location;
            return user ? (canShowCareView(user, view) ? (children) : (_jsx(NotFoundContainer, {}))) : (_jsx(Redirect, { to: {
                    pathname: '/login',
                    state: { from: location },
                } }));
        } })));
};
var RootRoute = function (_a) {
    var children = _a.children, user = _a.user, rest = __rest(_a, ["children", "user"]);
    return (_jsx(Route, __assign({}, rest, { render: function (_a) {
            var location = _a.location;
            return user ? (isRoot(user) ? (children) : (_jsx(NotFoundContainer, {}))) : (_jsx(Redirect, { to: {
                    pathname: '/login',
                    state: { from: location },
                } }));
        } })));
};
var Routes = function (_a) {
    var user = useAuthState().user;
    return (_jsxs(Switch, { children: [_jsx(RestrictedRoute, __assign({ exact: true, path: "/", user: user, view: CareView.PATIENT_OVERVIEW }, { children: _jsx(PatientListPage, {}) })), _jsx(RestrictedRoute, __assign({ exact: true, path: "/details/:id", user: user, view: CareView.PATIENT_OVERVIEW }, { children: _jsx(PatientDetailPage, {}) })), _jsx(RestrictedRoute, __assign({ exact: true, path: "/history", user: user, view: CareView.PATIENT_EXPORT }, { children: _jsx(ExportPage, {}) })), _jsx(RestrictedRoute, __assign({ exact: true, path: "/tv-connect", user: user, view: CareView.ACTIVATE_MONITOR }, { children: _jsx(MonitorConnectPage, {}) })), _jsx(RestrictedRoute, __assign({ exact: true, path: "/calendar", user: user, view: CareView.CALENDAR_PRACTITIONER }, { children: _jsx(CalendarContainerHelper, {}) })), _jsx(RootRoute, __assign({ exact: true, path: "/admin/organization", user: user }, { children: _jsx(OrganizationPage, {}) })), _jsx(RestrictedRoute, __assign({ exact: true, path: "/admin/personnel", user: user, view: CareView.PERSONNEL_OVERVIEW }, { children: _jsx(PersonnelPage, {}) })), _jsx(RestrictedRoute, __assign({ exact: true, path: "/admin/checklist", user: user, view: CareView.CHECKLIST_OVERVIEW }, { children: _jsx(ChecklistPage, {}) })), _jsx(Route, { exact: true, path: "/tv", component: MonitorPage }), _jsx(Route, { exact: true, path: "/privacy", component: Privacy }), _jsx(Route, { exact: true, path: "/support", component: Support }), _jsx(Route, { exact: true, path: "/logout", component: LogoutPage }), _jsx(LoginRoute, __assign({ exact: true, path: "/login", user: user }, { children: _jsx(LoginPage, {}) })), _jsx(Route, { component: NotFoundContainer })] }));
};
export default Routes;

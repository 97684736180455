var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';
import Button from '../Button';
import UserIcon from '../../resources/img/user_large.png';
import Modal from '../Modal/Modal';
import ModalFooter from 'components/Modal/ModalFooterButtons';
import globalMessages from '@src/globalMessages';
var UserInfoModal = function (_a) {
    var title = _a.title, dismiss = _a.dismiss, user = _a.user, logout = _a.logout;
    return (_jsx(Modal, __assign({ maxWidth: "652px", title: title, dismiss: dismiss, disableBoxShadow: true }, { children: _jsxs(StyledContent, { children: [_jsx("img", { src: UserIcon, alt: "user icon", className: "userIcon" }), _jsxs("h2", { children: [user.firstName, " ", user.surname] }), _jsx("p", { children: user.organization.name }), _jsxs(ModalFooter, __assign({ className: "modalFooter" }, { children: [_jsx(Button, __assign({ medium: true, hover: true, onClick: function () { return dismiss(); } }, { children: _jsx(FormattedMessage, __assign({}, globalMessages.close)) })), _jsx(Button, __assign({ medium: true, primary: true, hover: true, onClick: function () { return logout(); } }, { children: _jsx(FormattedMessage, __assign({}, globalMessages.logout)) }))] }))] }) })));
};
var StyledContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-top: 0;\n  text-align: center;\n  color: ", ";\n  font-size: 16px;\n  line-height: 22px;\n\n  .userIcon {\n    margin-top: 21px;\n    width: 120;\n    height: 120;\n  }\n  h2 {\n    margin-bottom: 28px;\n    font-size: 32px;\n    font-weight: bold;\n    line-height: 43px;\n  }\n  p {\n    margin: 0;\n  }\n\n  .modalFooter {\n    box-shadow: none;\n    margin-top: 60px;\n    margin-bottom: 41px;\n  }\n"], ["\n  padding-top: 0;\n  text-align: center;\n  color: ", ";\n  font-size: 16px;\n  line-height: 22px;\n\n  .userIcon {\n    margin-top: 21px;\n    width: 120;\n    height: 120;\n  }\n  h2 {\n    margin-bottom: 28px;\n    font-size: 32px;\n    font-weight: bold;\n    line-height: 43px;\n  }\n  p {\n    margin: 0;\n  }\n\n  .modalFooter {\n    box-shadow: none;\n    margin-top: 60px;\n    margin-bottom: 41px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.cello;
});
export default UserInfoModal;
var templateObject_1;

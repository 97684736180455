/*
 * User Form Modal Messages
 *
 * This contains the message translations that are used in the user form
 * modal and associated validation errors
 */
import { defineMessages } from 'react-intl';
export var scope = 'app.pages.Admin.PersonnelPage.Modal';
var errorScope = 'app.pages.Admin.PersonnelPage.Modal.Errors';
export default defineMessages({
    headerAdd: {
        id: "".concat(scope, ".headerAdd"),
        defaultMessage: 'Lägg till användare',
    },
    headerEdit: {
        id: "".concat(scope, ".headerEdit"),
        defaultMessage: 'Redigera användare',
    },
    firstNameFieldLabel: {
        id: "".concat(scope, ".firstNameFieldLabel"),
        defaultMessage: 'FÖRNAMN',
    },
    surnameFieldLabel: {
        id: "".concat(scope, ".surnameFieldLabel"),
        defaultMessage: 'EFTERNAMN',
    },
    hsaidFieldLabel: {
        id: "".concat(scope, ".hsaidFieldLabel"),
        defaultMessage: 'HSA-ID',
    },
    conflictValidationError: {
        id: "".concat(errorScope, ".conflictValidationError"),
        defaultMessage: 'Konflikt',
    },
    conflictValidationErrorMessage: {
        id: "".concat(errorScope, ".conflictValidationErrorMessage"),
        defaultMessage: 'Användaren har redan behörigheter på vårdenheten.',
    },
    conflictPersonnelValidationErrorMessage: {
        id: "".concat(errorScope, ".conflictPersonnelValidationErrorMessage"),
        defaultMessage: 'En användare med samma HSA-ID finns redan i systemet, vill du ge behörighet till nedanstående användare istället?',
    },
    firstNameValidationError: {
        id: "".concat(errorScope, ".firstNameValidationError"),
        defaultMessage: 'Skriv in ett förnamn',
    },
    hsaidValidationError: {
        id: "".concat(errorScope, ".hsaidValidationError"),
        defaultMessage: 'Skriv in ett hsa-id',
    },
    surnameValidationError: {
        id: "".concat(errorScope, ".surnameValidationError"),
        defaultMessage: 'Skriv in ett efternamn',
    },
    systemUnexpectedError: {
        id: "".concat(errorScope, ".systemUnexpectedError"),
        defaultMessage: 'Ett oväntat fel inträffade',
    },
    systemUnexpectedErrorMessage: {
        id: "".concat(errorScope, ".systemUnexpectedErrorMessage"),
        defaultMessage: 'Det gick inte att skapa användaren',
    },
    unauthorizedError: {
        id: "".concat(errorScope, ".notAuthorizedError"),
        defaultMessage: 'Otillräcklig behörighet',
    },
    unauthorizedErrorMessage: {
        id: "".concat(errorScope, ".notAuthorizedErrorMessage"),
        defaultMessage: 'Du kan inte lägga till denna användare.',
    },
    permissionsValidationError: {
        id: "".concat(errorScope, ".permissionsValidationError"),
        defaultMessage: 'Välj behörighetsnivå',
    },
});

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { usePatientDetail } from './usePatientDetail';
export var PatientDetailStateContext = React.createContext(undefined);
export var PatientDetailActionContext = React.createContext(undefined);
var PatientDetailProvider = function (_a) {
    var children = _a.children;
    var _b = usePatientDetail(), state = _b[0], actions = _b[1];
    return (_jsx(PatientDetailStateContext.Provider, __assign({ value: state }, { children: _jsx(PatientDetailActionContext.Provider, __assign({ value: actions }, { children: children })) })));
};
export default PatientDetailProvider;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import Cell from './Cell';
import Unlinked from '@resources/img/Link_missing@1x.png';
var StyledCornerIconCell = styled(Cell)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  border-radius: 5px 0 0 5px;\n  box-shadow: 0 3px 5px 1px rgba(55, 74, 106, 0.05);\n  text-align: center;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n  margin-right: 5px;\n  width: ", ";\n\n  .corner-icon {\n    --size: ", ";\n    position: absolute;\n    top: 0;\n    left: var(--size);\n    margin-left: calc(-1 * var(--size));\n    vertical-align: top;\n    float: left;\n    width: var(--size);\n    height: var(--size);\n    background: linear-gradient(to top left, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 0) 50%, #d2d8dd 50%);\n    box-shadow: none;\n    border-top-left-radius: 5px;\n    img {\n      vertical-align: top;\n      position: absolute;\n      top: ", ";\n      left: ", ";\n      width: ", ";\n      height: ", ";\n    }\n  }\n"], ["\n  background: ", ";\n  border-radius: 5px 0 0 5px;\n  box-shadow: 0 3px 5px 1px rgba(55, 74, 106, 0.05);\n  text-align: center;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n  margin-right: 5px;\n  width: ", ";\n\n  .corner-icon {\n    --size: ", ";\n    position: absolute;\n    top: 0;\n    left: var(--size);\n    margin-left: calc(-1 * var(--size));\n    vertical-align: top;\n    float: left;\n    width: var(--size);\n    height: var(--size);\n    background: linear-gradient(to top left, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 0) 50%, #d2d8dd 50%);\n    box-shadow: none;\n    border-top-left-radius: 5px;\n    img {\n      vertical-align: top;\n      position: absolute;\n      top: ", ";\n      left: ", ";\n      width: ", ";\n      height: ", ";\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.blueGrey;
}, function (_a) {
    var compact = _a.compact;
    return (compact ? '174px' : '125px');
}, function (_a) {
    var compact = _a.compact;
    return (compact ? '40px' : '50px');
}, function (_a) {
    var compact = _a.compact;
    return (compact ? '5px' : '7px');
}, function (_a) {
    var compact = _a.compact;
    return (compact ? '5px' : '7px');
}, function (_a) {
    var compact = _a.compact;
    return (compact ? '15px' : '20px');
}, function (_a) {
    var compact = _a.compact;
    return (compact ? '15px' : '20px');
});
var CornerIconCell = function (_a) {
    var compact = _a.compact, text = _a.text, unlinked = _a.unlinked, rest = __rest(_a, ["compact", "text", "unlinked"]);
    return (_jsxs(StyledCornerIconCell, __assign({ compact: compact }, rest, { children: [unlinked && (_jsx("div", __assign({ className: "corner-icon" }, { children: _jsx("img", { src: Unlinked }) }))), text] })));
};
export default CornerIconCell;
var templateObject_1;

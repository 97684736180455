var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { io } from 'socket.io-client';
import styled from '@emotion/styled';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAuthState from '@hooks/useAuth/useAuthState';
import usePatientDetailState from '@hooks/usePatientDetail/usePatientDetailState';
import usePatientListAction from '@hooks/usePatientList/usePatientListAction';
import usePatientDetailAction from '@hooks/usePatientDetail/usePatientDetailAction';
import { setPatientsAction } from '@hooks/usePatientList/actions';
import { clearPatientDetailAction, setPatientAction } from '@hooks/usePatientDetail/actions';
import { CareView } from '@api/auth/types';
import useAdminAction from '@hooks/useAdmin/useAdminAction';
import { clearPersonnelAction } from '@hooks/useAdmin/actions';
import { getPatients } from '@api/patient/patients';
import { getPatient } from '@api/patient/patient';
import { getMe } from '@api/auth/actions';
import { clearAuthAction, setUserAction } from '@hooks/useAuth/actions';
import useAuthAction from '@hooks/useAuth/useAuthAction';
import usePatientListState from '@hooks/usePatientList/usePatientListState';
var socket;
var WebSocketComponent = React.memo(function (_a) {
    var _b = useState(true), connected = _b[0], setConnected = _b[1];
    var user = useAuthState().user;
    var patient = usePatientDetailState().patient;
    var showAtHome = usePatientListState().showAtHome;
    var listDispatch = usePatientListAction();
    var detailDispatch = usePatientDetailAction();
    var adminDispatch = useAdminAction();
    var authDispatch = useAuthAction();
    var history = useHistory();
    var getUserData = function () {
        getMe()
            .then(function (user) {
            authDispatch(setUserAction(user));
        })
            .catch(function () {
            authDispatch(clearAuthAction());
        });
    };
    var reloadPatients = function () {
        getPatients(showAtHome).then(function (value) {
            listDispatch(setPatientsAction(value));
        });
    };
    var onPatientCreate = function () {
        reloadPatients();
    };
    var onPatientUpdate = function (event, selectedPatientId) {
        reloadPatients();
        if (selectedPatientId && selectedPatientId === event.id) {
            getPatient(selectedPatientId).then(function (value) {
                detailDispatch(setPatientAction(value));
            });
        }
    };
    var onPatientDelete = function (event, selectedPatientId) {
        reloadPatients();
        if (selectedPatientId && selectedPatientId === event.id) {
            detailDispatch(clearPatientDetailAction());
        }
    };
    var onPersonnelUpdate = function (u) {
        var views = u && u.views;
        if (views && views.includes(CareView.PERSONNEL_OVERVIEW)) {
            adminDispatch(clearPersonnelAction());
        }
    };
    var onPersonnelCreate = function (u) {
        onPersonnelUpdate(u);
    };
    var onPersonnelDelete = function (u) {
        onPersonnelUpdate(u);
    };
    /**
     * Initiate socket connection and handle disconnect when component unmounts.
     * Should only execute once.
     */
    useEffect(function () {
        socket = io();
        socket.on('connect', function () {
            // console.log("Websocket connected")
            setConnected(true);
        });
        socket.on('disconnect', function () {
            // console.log("Websocket disconnected")
            setConnected(false);
        });
        socket.on('connect_error', function () {
            setConnected(false);
        });
        return function () {
            // console.log("Websocket disconnecting")
            socket.disconnect();
        };
    }, []);
    /**
     * Register listeners without dependencies.
     * Should only execute once.
     */
    useEffect(function () {
        // console.log("Register common listeners");
        socket.on('ME#UPDATE', function () { return getUserData(); });
        return function () {
            // console.log("Unregister common listeners");
            socket.removeAllListeners('ME#UPDATE');
        };
    }, []);
    /**
     * Register listeners depending on showAtHome.
     * Should only execute when 'showAtHome' changes.
     */
    useEffect(function () {
        // console.log("Register showAtHome listeners");
        socket.on('PATIENT#CREATE', function () { return onPatientCreate(); });
        return function () {
            // console.log("Unregister showAtHome listeners");
            socket.removeAllListeners('PATIENT#CREATE');
        };
    }, [showAtHome]);
    /**
     * Register listeners depending on selected patient.
     * Should only execute when 'selectedPatientId' changes.
     */
    useEffect(function () {
        // console.log(`Register patient listeners with patientId ${  selectedPatientId}.`);
        socket.on('PATIENT#UPDATE', function (event) { return onPatientUpdate(event, patient === null || patient === void 0 ? void 0 : patient.id); });
        socket.on('PATIENT#DELETE', function (event) { return onPatientDelete(event, patient === null || patient === void 0 ? void 0 : patient.id); });
        return function () {
            // console.log("Unregister patient listeners");
            socket.removeAllListeners('PATIENT#UPDATE');
            socket.removeAllListeners('PATIENT#DELETE');
        };
    }, [showAtHome, patient === null || patient === void 0 ? void 0 : patient.id]);
    /**
     * Register listeners depending on current user.
     * Should only execute when 'user' changes.
     */
    useEffect(function () {
        // console.log(`Register user listeners with userId ${  user?.id}.`);
        socket.on('PERSONNEL#UPDATE', function () { return onPersonnelUpdate(user); });
        socket.on('PERSONNEL#CREATE', function () { return onPersonnelCreate(user); });
        socket.on('PERSONNEL#DELETE', function () { return onPersonnelDelete(user); });
        socket.on('ME#DELETE', function (event) {
            var organizationId = event.id;
            if (user && user.organization.id === organizationId) {
                console.warn('User access removed, logging out.');
                history.push('/logout');
            }
        });
        return function () {
            // console.log("Unregister user listeners");
            socket.removeAllListeners('PERSONNEL#UPDATE');
            socket.removeAllListeners('PERSONNEL#DELETE');
            socket.removeAllListeners('PERSONNEL#CREATE');
            socket.removeAllListeners('ME#DELETE');
        };
    }, [user]);
    return connected ? null : (_jsxs(WebSocketBanner, { children: [_jsx(FontAwesomeIcon, { icon: faExclamationTriangle }), " N\u00E4tverksuppkoppling f\u00F6rlorad"] }));
});
export default WebSocketComponent;
var WebSocketBanner = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  z-index: 999;\n  top: 0px;\n  left: 50%;\n  margin-left: -250px;\n  width: 500px;\n  height: 40px;\n  padding-top: 8px;\n  font-size: 16px;\n  font-weight: 600;\n  color: red;\n  pointer-events: none;\n  text-align: center;\n  background: #FFDDDD;\n  border: 1px solid red;\n  border-top: 0px;\n  border-bottom-left-radius: 5px;\n  border-bottom-right-radius: 5px;\n  box-shadow: 0px 1px 5px 0px #d5d5d5;\n}}\n"], ["\n  position: fixed;\n  z-index: 999;\n  top: 0px;\n  left: 50%;\n  margin-left: -250px;\n  width: 500px;\n  height: 40px;\n  padding-top: 8px;\n  font-size: 16px;\n  font-weight: 600;\n  color: red;\n  pointer-events: none;\n  text-align: center;\n  background: #FFDDDD;\n  border: 1px solid red;\n  border-top: 0px;\n  border-bottom-left-radius: 5px;\n  border-bottom-right-radius: 5px;\n  box-shadow: 0px 1px 5px 0px #d5d5d5;\n}}\n"])));
var templateObject_1;

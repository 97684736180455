var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import InformationBox from './InformationBox';
import iconEdit from '../../resources/img/ikon-andra-200.png';
import DiagnosisModal from '../Diagnosis/DiagnosisModal';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import messages from '@src/pages/PatientListPage/PatientList/messages';
import globalMessages from '@src/globalMessages';
var DiagnosisBox = function (_a) {
    var _b, _c;
    var patient = _a.patient;
    var _d = useState(false), showModal = _d[0], setShowModal = _d[1];
    var formatMessage = useIntl().formatMessage;
    return (_jsxs(React.Fragment, { children: [_jsx(InformationBox, __assign({ textLeft: formatMessage(messages.diagnosis), textRight: formatMessage(globalMessages.edit), icon: iconEdit, iconStyle: { width: '9px', height: '9px', marginRight: '6px' }, onBoxClick: function () { return setShowModal(true); }, onLinkClick: function () { return setShowModal(true); } }, { children: _jsxs(StyledContent, { children: [_jsx("div", __assign({ className: "code" }, { children: _jsx("span", { children: (_b = patient.diagnosis) === null || _b === void 0 ? void 0 : _b.code }) })), _jsx("div", __assign({ className: "name" }, { children: _jsx("p", { children: (_c = patient.diagnosis) === null || _c === void 0 ? void 0 : _c.description }) }))] }) })), showModal && _jsx(DiagnosisModal, { patient: patient, dismiss: function () { return setShowModal(false); } })] }));
};
export default DiagnosisBox;
var StyledContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n\n  vertical-align: top;\n  div {\n    display: inline-block;\n  }\n\n  .code {\n    vertical-align: top;\n    font-size: 20px;\n    line-height: 27px;\n    font-weight: bold;\n    margin-right: 15px;\n    padding: 16px 16px 16px 16px;\n  }\n\n  .name {\n    font-size: 14px;\n    line-height: 18px;\n    margin: 0;\n    padding: 0px 16px 0px 0px;\n    max-width: 300px;\n    min-width: 230px;\n    p {\n      margin: 0;\n    }\n  }\n"], ["\n  color: ", ";\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n\n  vertical-align: top;\n  div {\n    display: inline-block;\n  }\n\n  .code {\n    vertical-align: top;\n    font-size: 20px;\n    line-height: 27px;\n    font-weight: bold;\n    margin-right: 15px;\n    padding: 16px 16px 16px 16px;\n  }\n\n  .name {\n    font-size: 14px;\n    line-height: 18px;\n    margin: 0;\n    padding: 0px 16px 0px 0px;\n    max-width: 300px;\n    min-width: 230px;\n    p {\n      margin: 0;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.cello;
});
var templateObject_1;

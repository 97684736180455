import { defineMessages } from 'react-intl';
var scope = 'app.components.Menu';
export default defineMessages({
    admittedInPatients: {
        id: "".concat(scope, ".admittedInPatients"),
        defaultMessage: 'Inskrivna patienter',
    },
    calendar: {
        id: "".concat(scope, ".calendar"),
        defaultMessage: 'Kalender',
    },
    checklists: {
        id: "".concat(scope, ".checklists"),
        defaultMessage: 'Checklistor',
    },
    exportHistory: {
        id: "".concat(scope, ".exportHistory"),
        defaultMessage: 'Exportera historik',
    },
    helpFeedback: {
        id: "".concat(scope, ".helpFeedback"),
        defaultMessage: 'Hjälp & Förslag',
    },
    homePatients: {
        id: "".concat(scope, ".homePatients"),
        defaultMessage: 'Hemmavarande patienter',
    },
    organizations: {
        id: "".concat(scope, ".organizations"),
        defaultMessage: 'Organisationer',
    },
    patientOverview: {
        id: "".concat(scope, ".patientOverview"),
        defaultMessage: 'Patientöversikt',
    },
    startTVMonitor: {
        id: "".concat(scope, ".startTVMonitor"),
        defaultMessage: 'Starta TV-monitor',
    },
    users: {
        id: "".concat(scope, ".users"),
        defaultMessage: 'Användare',
    },
});

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import iconHome from '@resources/img/ikon-home.png';
import iconHospital from '@resources/img/hospital.png';
import Button from '@components/Button';
import PlusIcon from '@resources/img/ikon-laggtill.png';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '@pages/PatientListPage/PatientList/messages';
import { ListHeader, ListHeaderItem } from '@pages/PatientListPage/PatientList/PatientListItem/components/ListHeader';
import { MainContentHeading } from '@pages/PatientListPage/PatientList/PatientListItem/components/MainContentHeading';
import PatientListItem from '@pages/PatientListPage/PatientList/PatientListItem';
import styled from '@emotion/styled';
import PatientFormModal from '@pages/PatientListPage/PatientList/PatientFormModal';
import Toggle from '@src/components/Toggle/Toggle';
import usePatientListState from '@hooks/usePatientList/usePatientListState';
import usePatientListAction from '@hooks/usePatientList/usePatientListAction';
import { setShowAtHomeAction } from '@hooks/usePatientList/actions';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 3rem;\n"], ["\n  padding: 3rem;\n"])));
var Heading = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  text-align: left;\n  margin-top: 10px;\n  margin-bottom: 0.67rem;\n  .button {\n    display: flex;\n    align-items: center;\n    float: right;\n    margin-top: 9px;\n    height: 34px;\n    border-radius: 5px;\n  }\n  .button {\n    background-color: ", ";\n\n    .addPatientIcon {\n    }\n\n    .addPatientInner {\n      display: inline-flex;\n      justify-content: center;\n      align-items: center;\n      position: relative;\n      top: -3px;\n    }\n\n    span {\n      display: inline-block;\n      padding-left: 7px;\n      font-size: 14px;\n      font-weight: 600;\n      letter-spacing: -0.3px;\n      line-height: 19px;\n    }\n  }\n  h1 {\n    display: inline-block;\n    height: 43px;\n    font-family: 'Open Sans';\n    font-size: 32px;\n    font-weight: bold;\n    line-height: 43px;\n    margin-top: 0;\n    margin-bottom: 19px;\n  }\n"], ["\n  text-align: left;\n  margin-top: 10px;\n  margin-bottom: 0.67rem;\n  .button {\n    display: flex;\n    align-items: center;\n    float: right;\n    margin-top: 9px;\n    height: 34px;\n    border-radius: 5px;\n  }\n  .button {\n    background-color: ", ";\n\n    .addPatientIcon {\n    }\n\n    .addPatientInner {\n      display: inline-flex;\n      justify-content: center;\n      align-items: center;\n      position: relative;\n      top: -3px;\n    }\n\n    span {\n      display: inline-block;\n      padding-left: 7px;\n      font-size: 14px;\n      font-weight: 600;\n      letter-spacing: -0.3px;\n      line-height: 19px;\n    }\n  }\n  h1 {\n    display: inline-block;\n    height: 43px;\n    font-family: 'Open Sans';\n    font-size: 32px;\n    font-weight: bold;\n    line-height: 43px;\n    margin-top: 0;\n    margin-bottom: 19px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.blueGrey;
});
var PatientList = function (_a) {
    var patients = _a.patients, organization = _a.organization, diagnoses = _a.diagnoses, compact = _a.compact;
    var patientListDispatch = usePatientListAction();
    var _b = useState(false), showCreatePatient = _b[0], setShowCreatePatient = _b[1];
    var showAtHome = usePatientListState().showAtHome;
    var formatMessage = useIntl().formatMessage;
    var locationFiltering = [
        {
            text: formatMessage(messages.admittedPatients),
            state: true,
            icon: iconHospital,
        },
        {
            text: formatMessage(messages.home),
            state: false,
            icon: iconHome,
        },
    ];
    var toggleAtHome = function () {
        patientListDispatch(setShowAtHomeAction(!showAtHome));
    };
    return (_jsxs(Wrapper, { children: [_jsxs(Heading, { children: [_jsx("h1", { children: formatMessage(messages.overviewHeader) }), _jsx(Toggle, { options: locationFiltering, onToggle: toggleAtHome, value: showAtHome }), !showAtHome && (_jsx(_Fragment, { children: _jsxs(Button, __assign({ hover: true, onClick: function () { return setShowCreatePatient(true); }, className: "button" }, { children: [_jsx("img", { src: PlusIcon, width: "14px", height: "14px" }), _jsx("span", { children: _jsx(FormattedMessage, __assign({}, messages.addPatient)) })] })) })), _jsxs(ListHeader, __assign({ className: "headline" }, { children: [_jsx(ListHeaderItem, __assign({ style: { display: 'flex', justifyContent: 'center' }, width: compact ? '174px' : '125px' }, { children: organization.medicalBoardingEnabled && !showAtHome && _jsx("span", { children: formatMessage(messages.room) }) })), _jsxs(MainContentHeading, __assign({ compact: compact }, { children: [_jsx(ListHeaderItem, __assign({ width: '120px', style: { marginLeft: '40px' } }, { children: _jsx("span", { children: _jsx(FormattedMessage, __assign({}, messages.name)) }) })), _jsx(ListHeaderItem, { children: _jsx("span", { children: _jsx(FormattedMessage, __assign({}, messages.patient)) }) }), _jsx(ListHeaderItem, __assign({ width: '125px', style: { marginLeft: '40px' } }, { children: _jsx("span", { children: _jsx(FormattedMessage, __assign({}, messages.created)) }) })), organization.modules
                                        .filter(function (m) { return m.type === 'CHECKLIST'; })
                                        .map(function (o, i) { return (_jsx(ListHeaderItem, __assign({ width: '90px' }, { children: _jsx("span", { children: _jsx(FormattedMessage, __assign({}, messages.checklist)) }) }), "checklist_header_".concat(i))); }), organization.diagnosisEnabled && (_jsx(ListHeaderItem, __assign({ width: '125px' }, { children: _jsx("span", { children: _jsx(FormattedMessage, __assign({}, messages.diagnosis)) }) })))] })), organization.modules
                                .filter(function (m) { return m.type === 'SCALE'; })
                                .map(function (module) {
                                var _a;
                                return (_jsx(ListHeaderItem, __assign({ width: compact ? '160px' : '104px', style: { marginLeft: '5px' } }, { children: _jsx("span", { children: ((_a = module.templates[0].scaleTemplate) === null || _a === void 0 ? void 0 : _a.scaleType) === 'NRS_NAUSEA' ? (_jsx(FormattedMessage, __assign({}, messages.nausea))) : (_jsx(FormattedMessage, __assign({}, messages.pain))) }) }), "scale_".concat(module.id)));
                            })] }))] }), _jsx("div", { children: patients.map(function (patient) { return (_jsx(PatientListItem, { patient: patient, organization: organization }, "patient_".concat(patient.id))); }) }), showCreatePatient && _jsx(PatientFormModal, { diagnoses: diagnoses, dismiss: function () { return setShowCreatePatient(false); }, organization: organization })] }));
};
export default PatientList;
var templateObject_1, templateObject_2;

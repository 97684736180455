import { AuthStatus } from '@api/auth/types';
import useAuthState from '@hooks/useAuth/useAuthState';
import useAuthAction from '@hooks/useAuth/useAuthAction';
import { useInterval } from '@hooks/useInterval/useInterval';
import { refreshJwt } from '@api/auth/actions';
import { setJwtAction } from '@hooks/useAuth/actions';
import { useHistory } from 'react-router-dom';
var JwtRefreshComponent = function (_a) {
    var authDispatch = useAuthAction();
    var status = useAuthState().status;
    var history = useHistory();
    useInterval(function () {
        refreshJwt()
            .then(function (response) {
            authDispatch(setJwtAction(response));
        })
            .catch(function (reason) {
            if (status !== AuthStatus.DEFAULT) {
                console.log('Jwt refresh failed, logging out', reason);
                history.push('/logout');
            }
        });
    }, 30000);
    return null;
};
export default JwtRefreshComponent;

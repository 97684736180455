var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useIntl } from 'react-intl';
import { List, Header, Box } from '@components/Admin/common/styling/list';
import ChecklistListItem from './ChecklistListItem/ChecklistListItem';
import messages from './messages';
var ChecklistList = function (_a) {
    var filter = _a.filter, checklistTemplates = _a.checklistTemplates, showChecklistModal = _a.showChecklistModal;
    // const toggleSortOrder = () => {
    //   setSortOrder(sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC);
    // };
    var formatMessage = useIntl().formatMessage;
    var templates = checklistTemplates.filter(function (template) { return template.title.match(new RegExp(filter, 'i')); });
    var grouped = templates.reduce(function (result, template) {
        var list = result.get(template.identifier) || [];
        list.push(template);
        result.set(template.identifier, list);
        return result;
    }, new Map());
    var sortedTemplates = [];
    grouped.forEach(function (value) {
        sortedTemplates = sortedTemplates.concat(value.sort(function (a, b) { return b.version - a.version; }));
    });
    return (_jsxs(List, { children: [_jsxs(Header, __assign({ className: "unselectable" }, { children: [_jsx(Box, __assign({ width: "100px" }, { children: _jsx("p", __assign({ className: "selected text-14" }, { children: formatMessage(messages.headerVersion) })) })), _jsx(Box, __assign({ width: "400px" }, { children: _jsx("p", __assign({ className: "selected title-14" }, { children: formatMessage(messages.headerName) })) })), _jsx(Box, { children: _jsx("p", __assign({ className: "selected text-14" }, { children: formatMessage(messages.headerStatus) })) })] })), sortedTemplates.map(function (template, index) { return (_jsx(ChecklistListItem, { checklistTemplate: template, showChecklistModal: showChecklistModal }, index)); })] }));
};
// enum SORT_ORDER {
//   ASC = 'ASCEND',
//   DESC = 'DESCEND',
// }
//
// function stringComparator(a: string, b: string, order: SORT_ORDER) {
//   const A = a.toUpperCase();
//   const B = b.toUpperCase();
//   switch (order) {
//     case SORT_ORDER.ASC:
//       return A < B ? -1 : A > B ? 1 : 0;
//     case SORT_ORDER.DESC:
//       return A > B ? -1 : A < B ? 1 : 0;
//   }
// }
export default ChecklistList;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';
import Button from '../Button';
import ModalFooter from './ModalFooterButtons';
import Modal from './Modal';
import globalMessages from '@src/globalMessages';
var ConfirmationModal = function (_a) {
    var title = _a.title, text = _a.text, onConfirm = _a.onConfirm, onCancel = _a.onCancel, dismiss = _a.dismiss, children = _a.children;
    var confirm = function () {
        if (onConfirm) {
            onConfirm();
        }
        dismiss();
    };
    var cancel = function () {
        if (onCancel) {
            onCancel();
        }
        dismiss();
    };
    var formattedText = text && text.split(/\n/).map(function (line, index) { return _jsx("p", { children: line }, index); });
    return (_jsx(StyledContent, { children: _jsxs(Modal, __assign({ maxWidth: "600px", title: title, dismiss: dismiss, disableBoxShadow: true }, { children: [text && _jsx("div", __assign({ className: "text" }, { children: formattedText })), children && _jsx("div", { children: children }), _jsxs(ModalFooter, __assign({ className: "modalFooter" }, { children: [_jsx(Button, __assign({ type: "button", medium: true, hover: true, primary: true, onClick: confirm }, { children: _jsx(FormattedMessage, __assign({}, globalMessages.confirm)) })), _jsx(Button, __assign({ type: "button", medium: true, hover: true, onClick: cancel }, { children: _jsx(FormattedMessage, __assign({}, globalMessages.cancel)) }))] }))] })) }));
};
export default ConfirmationModal;
var StyledContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .modalFooter {\n    box-shadow: none;\n  }\n\n  .text {\n    text-align: center;\n  }\n"], ["\n  .modalFooter {\n    box-shadow: none;\n  }\n\n  .text {\n    text-align: center;\n  }\n"])));
var templateObject_1;

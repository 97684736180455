var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Controls, HorizontalRule, Title, Wrapper } from '@components/Admin/common/styling/main';
import { theme } from '@constants/styling';
import { pushErrorAction } from '@hooks/useError/actions';
import { setPersonnelAction } from '@hooks/useAdmin/actions';
import { loadPersonnel } from '@api/admin/actions';
import IconSearch from '@resources/img/search.png';
import Button from '@components/Button';
import useAdminState from '@hooks/useAdmin/useAdminState';
import PersonnelList from '@pages/Admin/PersonnelPage/List/PersonnelList';
import LoadingIndicatorComponent from '@components/Common/LoadingIndicatorComponent/LoadingIndicatorComponent';
import useAdminAction from '@hooks/useAdmin/useAdminAction';
import useErrorAction from '@hooks/useError/useErrorAction';
import CreatePersonnelModal from '@pages/Admin/PersonnelPage/Modal/CreatePersonnelModal';
import messages from './messages';
import globalMessages from '@src/globalMessages';
var PersonnelPage = function () {
    var adminDispatch = useAdminAction();
    var errorDispatch = useErrorAction();
    var personnel = useAdminState().personnel;
    var formatMessage = useIntl().formatMessage;
    var _a = useState(false), showCreateModal = _a[0], setShowCreateModal = _a[1];
    var _b = useState(''), filter = _b[0], setFilter = _b[1];
    useEffect(function () {
        if (!personnel) {
            loadPersonnel()
                .then(function (response) {
                adminDispatch(setPersonnelAction(response));
            })
                .catch(function () {
                errorDispatch(pushErrorAction({
                    title: 'Ett oväntat fel inträffade',
                    message: 'Vänligen ladda om sidan.',
                }));
            });
        }
    }, [personnel]);
    return (_jsxs(Wrapper, { children: [_jsx(Title, __assign({ className: "headlineH1" }, { children: _jsx("p", { children: formatMessage(messages.usersPageHeader) }) })), _jsx(HorizontalRule, {}), _jsxs(Controls, __assign({ className: "text-14" }, { children: [_jsx("div", __assign({ className: "left" }, { children: _jsx("span", { children: formatMessage(messages.usersPageSubheader) }) })), _jsxs("div", __assign({ className: "right" }, { children: [_jsxs("div", __assign({ className: "search" }, { children: [_jsx("input", { type: "text", placeholder: formatMessage(messages.usersSearchPlaceholder), className: "text-14", onChange: function (e) { return setFilter(e.target.value); } }), _jsx("img", { src: IconSearch })] })), _jsx(Button, __assign({ className: "button", medium: true, borderColor: theme.dodgerBlue, width: "114", height: "34", onClick: function () { return setShowCreateModal(true); } }, { children: formatMessage(globalMessages.add) }))] }))] })), personnel ? _jsx(PersonnelList, { personnel: personnel, filter: filter }) : _jsx(LoadingIndicatorComponent, {}), showCreateModal && _jsx(CreatePersonnelModal, { className: "modal", dismiss: function () { return setShowCreateModal(false); } })] }));
};
export default PersonnelPage;

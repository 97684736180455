var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import styled from '@emotion/styled';
import moment from 'moment';
var SSNBox = function (_a) {
    var value = _a.value, readonly = _a.readonly, onChange = _a.onChange;
    var _b = useState(value), inputValue = _b[0], setInputValue = _b[1];
    var onValueChange = function (e) {
        var ssn = e.target.value;
        var cursorIsAtEnd = e.target.selectionStart === ssn.length && e.target.selectionEnd === ssn.length;
        if (cursorIsAtEnd) {
            ssn = ssn.replace(/[^0-9]/g, '');
            if (ssn.length > 8) {
                ssn = "".concat(ssn.substring(0, 8), "-").concat(ssn.substring(8, 12));
            }
        }
        var isValid = checkValidSSN(ssn);
        onChange(ssn, isValid);
        setInputValue(ssn);
    };
    var checkValidSSN = function (ssn) {
        var invalid = false;
        if (!(ssn.length === 13) || !(ssn.charAt(8) === '-')) {
            invalid = true;
        }
        else {
            var yymd = ssn.slice(0, 8);
            var ymd = ssn.slice(2, 8);
            var nnnn = ssn.slice(ssn.length - 4);
            var parsed = moment(yymd, 'YYYYMMDD', true);
            var formatted = parsed.format('YYYYMMDD');
            if (formatted === 'Invalid date' || formatted !== yymd) {
                invalid = true;
            }
            else {
                var valString = '', sum = 0, ymdn = ymd + nnnn;
                for (var i = 0; i < ymdn.length; i++) {
                    if (i % 2 === 0) {
                        valString += Number(ymdn[i]) * 2;
                    }
                    else {
                        valString += ymdn[i];
                    }
                }
                for (var i = 0; i < valString.length; i++) {
                    sum += Number(valString[i]);
                }
                if (sum % 10 !== 0) {
                    invalid = true;
                }
            }
        }
        return !invalid;
    };
    return (_jsx(SSNBoxContent, { children: !readonly ? (_jsx("input", { autoComplete: "off", placeholder: "\u00C5\u00C5\u00C5\u00C5MMDD-NNNN", type: "text", id: "ssn", className: "textInput", value: inputValue || '', onChange: onValueChange })) : (_jsx("p", __assign({ id: "ssnReadonly" }, { children: inputValue }))) }));
};
export default SSNBox;
var SSNBoxContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  #ssnReadonly {\n    padding: 6px 0 6px 10px;\n    font-size: 16px;\n  }\n\n  #ssn {\n    width: 170px;\n    letter-spacing: -0.34px;\n    padding: 0 0 0 10px;\n  }\n"], ["\n  #ssnReadonly {\n    padding: 6px 0 6px 10px;\n    font-size: 16px;\n  }\n\n  #ssn {\n    width: 170px;\n    letter-spacing: -0.34px;\n    padding: 0 0 0 10px;\n  }\n"])));
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import InformationBox from './InformationBox';
import IconChecklistStarted from '../../resources/img/ikon-checklista-invantar.png';
import IconChecklistComplete from '../../resources/img/ikon-checklista-ansluten.png';
import IconChecklistPending from '../../resources/img/ikon-checklista-ejansluten.png';
import ChecklistDetailModal from 'components/ChecklistDetail/ChecklistDetailModal';
import iconShow from '../../resources/img/ikon-visa.png';
import globalMessages from '@src/globalMessages';
import messages from '@src/pages/PatientListPage/PatientList/messages';
var ChecklistBox = function (_a) {
    var checklist = _a.checklist, patient = _a.patient;
    var formatMessage = useIntl().formatMessage;
    var _b = useState(false), showChecklistModal = _b[0], setShowChecklistModal = _b[1];
    var onClick = function () {
        setShowChecklistModal(true);
    };
    return (_jsxs(React.Fragment, { children: [_jsx(InformationBox, __assign({ textLeft: formatMessage(messages.checklist), textRight: formatMessage(globalMessages.show), icon: iconShow, iconStyle: { width: '14px', height: '10px', marginRight: '4px' }, onLinkClick: onClick, onBoxClick: onClick }, { children: _jsx(StyledContent, { children: checklist && checklist && checklist.completed ? (_jsx("img", { src: IconChecklistComplete, className: "checklistIcon" })) : checklist.completedItems > 0 ? (_jsx("img", { src: IconChecklistStarted, className: "checklistIcon" })) : (_jsx("img", { src: IconChecklistPending, className: "checklistIcon" })) }) })), showChecklistModal && _jsx(ChecklistDetailModal, { patient: patient, checklist: checklist, dismiss: function () { return setShowChecklistModal(false); } })] }));
};
export default ChecklistBox;
var StyledContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 18px 15px 17px 15px;\n  .checklistIcon {\n    width: 25px;\n    height: 25px;\n  }\n"], ["\n  padding: 18px 15px 17px 15px;\n  .checklistIcon {\n    width: 25px;\n    height: 25px;\n  }\n"])));
var templateObject_1;

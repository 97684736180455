import React, { PureComponent } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import uniqueId from 'lodash.uniqueid';
import { EventLabelIcon } from './EventLabelIcon';

export default class EventDropDownBox extends PureComponent {
  static propTypes = {
    title: PropTypes.node,
    iconName: PropTypes.string,
    disabled: PropTypes.bool,
    invalid: PropTypes.bool,
    errorMessage: PropTypes.node,
    placeholder: PropTypes.node,
    options: PropTypes.array,
    value: PropTypes.any,
    onValueChange: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.id = uniqueId('event-drop-down-box-');
  }

  render() {
    return (
      <div>
        <label htmlFor={this.id}>
          <EventLabelIcon name={this.props.iconName} />
          {this.props.title}
        </label>
        <div className="select">
          <Select
            id={this.id}
            classNamePrefix="dropdown"
            isClearable={true}
            isDisabled={this.props.disabled}
            value={this.props.value}
            clearValue={() => this.props.onValueChange(undefined)}
            onChange={(option) => this.props.onValueChange(option)}
            options={this.props.options}
            placeholder={this.props.placeholder}
          />
        </div>
        {this.props.invalid && this.props.errorMessage && <span className="error">{this.props.errorMessage}</span>}
      </div>
    );
  }
}

import { useContext } from 'react';
import { ErrorStateContext } from './ErrorProvider';
var useErrorState = function () {
    var context = useContext(ErrorStateContext);
    if (context === undefined) {
        throw new Error('useErrorState must be used within a ErrorProvider');
    }
    return context;
};
export default useErrorState;

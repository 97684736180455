var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import IconChecked from '../../../resources/img/Checked.png';
import IconEmptybox from '../../../resources/img/emptybox.png';
var Checkbox = function (_a) {
    var className = _a.className, title = _a.title, description = _a.description, checked = _a.checked, onClick = _a.onClick;
    return (_jsxs(Container, __assign({ className: className }, { children: [_jsxs("div", __assign({ className: "left" }, { children: [_jsx("p", __assign({ className: "title-16" }, { children: title })), _jsx("p", __assign({ className: "text-14" }, { children: description }))] })), _jsx(Box, __assign({ onClick: onClick }, { children: _jsx("img", { className: "unselectable", src: checked ? IconChecked : IconEmptybox }) }))] })));
};
export default Checkbox;
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-flex;\n  overflow: hidden;\n  justify-content: space-between;\n  align-items: center;\n  height: 80px;\n  width: 100%;\n  background-color: white;\n  border: 1px solid;\n  border-radius: 2px;\n  border-color: ", ";\n  .left {\n    display: block;\n    width: auto;\n\n    p {\n      margin: 0 0 0 20px;\n      padding: 2px 0;\n    }\n  }\n  .no-margin {\n    margin: 0;\n  }\n"], ["\n  display: inline-flex;\n  overflow: hidden;\n  justify-content: space-between;\n  align-items: center;\n  height: 80px;\n  width: 100%;\n  background-color: white;\n  border: 1px solid;\n  border-radius: 2px;\n  border-color: ", ";\n  .left {\n    display: block;\n    width: auto;\n\n    p {\n      margin: 0 0 0 20px;\n      padding: 2px 0;\n    }\n  }\n  .no-margin {\n    margin: 0;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.loblolly;
});
var Box = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n  height: 100%;\n  width: 78px;\n  margin: 0;\n  ", "\n  border-radius: 2px 0px 0px 2px;\n  background-color: ", ";\n  :hover {\n    cursor: pointer;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n  height: 100%;\n  width: 78px;\n  margin: 0;\n  ", /* border-radius: 2px 5px 5px 2px; */ "\n  border-radius: 2px 0px 0px 2px;\n  background-color: ", ";\n  :hover {\n    cursor: pointer;\n  }\n"])), '' /* border-radius: 2px 5px 5px 2px; */, function (_a) {
    var theme = _a.theme;
    return theme.athensGray;
});
var templateObject_1, templateObject_2;

import { defineMessages } from 'react-intl';
var scope = 'app.components.Calendar.EventPane';
var errorScope = 'app.components.Calendar.EventPane.Errors';
export default defineMessages({
    headerEditBooking: {
        id: "".concat(scope, ".headerEditBooking"),
        defaultMessage: 'Ändra bokning',
    },
    headerNewBooking: {
        id: "".concat(scope, ".headerNewBooking"),
        defaultMessage: 'Ny bokning',
    },
    fieldLabelTitle: {
        id: "".concat(scope, ".fieldLabelTitle"),
        defaultMessage: 'TITEL',
    },
    fieldLabelActivity: {
        id: "".concat(scope, ".fieldLabelActivity"),
        defaultMessage: 'AKTIVITET',
    },
    fieldLabelDateFrom: {
        id: "".concat(scope, ".fieldLabelDateFrom"),
        defaultMessage: 'FRÅN',
    },
    fieldLabelDateUntil: {
        id: "".concat(scope, ".fieldLabelDateUntil"),
        defaultMessage: 'TILL',
    },
    fieldLabelDescription: {
        id: "".concat(scope, ".fieldLabelDescription"),
        defaultMessage: 'BESKRIVNING',
    },
    fieldLabelPatients: {
        id: "".concat(scope, ".fieldLabelPatients"),
        defaultMessage: 'PATIENTER',
    },
    fieldLabelLocation: {
        id: "".concat(scope, ".fieldLabelLocation"),
        defaultMessage: 'PLATS',
    },
    fieldLabelRepeatingRule: {
        id: "".concat(scope, ".fieldLabelRepeatingRule"),
        defaultMessage: 'REGEL',
    },
    fieldLabelResources: {
        id: "".concat(scope, ".fieldLabelResources"),
        defaultMessage: 'RESURSER',
    },
    toggleRepeatingEvent: {
        id: "".concat(scope, ".toggleRepeatingEvent"),
        defaultMessage: 'ÅTERKOMMANDE',
    },
    dateFromFieldValidationError: {
        id: "".concat(errorScope, ".dateFromFieldValidationError"),
        defaultMessage: 'Ange start',
    },
    dateUntilFieldValidationError: {
        id: "".concat(errorScope, ".dateUntilFieldValidationError"),
        defaultMessage: 'Ange slut',
    },
    titleFieldValidationError: {
        id: "".concat(errorScope, ".titleFieldValidationError"),
        defaultMessage: 'Ange titel',
    },
    participantsValidationError: {
        id: "".concat(errorScope, ".participantsValidationError"),
        defaultMessage: 'Ange deltagare',
    },
});

import { useContext } from 'react';
import { PatientDetailStateContext } from './PatientDetailProvider';
var usePatientDetailState = function () {
    var context = useContext(PatientDetailStateContext);
    if (context === undefined) {
        throw new Error('usePatientDetailState must be used within a PatientDetailProvider');
    }
    return context;
};
export default usePatientDetailState;

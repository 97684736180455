// GLOBAL USES IT
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { Global, css } from '@emotion/react';
import normalize from 'normalize-css';
export const theme = {
  cello: '#1c3a54',
  sanJuan: '#374A6A',
  slateGray: '#637889',
  loblolly: '#BBC4CC',
  athensGray: '#F0F2F5',
  alto: '#D2D2D2',
  dodgerBlue: '#4CA1FF',
  tangerine: '#DE9900',
  lynch: '#5F7588',
  procelain: '#F6F8F9',
  stiletto: '#A63232',
  flushMahogany: '#CF4040',
  //old:
  wildRose: '#f6f6f6',
  darkGrey: '#637889',
  lightGrey: '#d5d5d5',
  lightBlue: '#53a0fc',
  skyBlue: '#0fdfff',
  lightGreen: '#8ED94C',
  darkGreen: '#4CD964',
  lightOrange: '#FFC700',
  darkOrange: '#FF9500',
  lightRed: '#FF9564',
  darkRed: '#FA3200',
  lightTeal: '#00DF8B',
  darkTeal: '#12AE90',
  blueGrey: '#f6f8f9',
  white: '#fff',
  black: '#000',
  border: 'rgba(0, 0, 0, 0.09)',
  bs: '0 0 5px 0 rgba(0, 0, 0, 0.09)',
  checkMarkGradient: { color1: '#00DF8B', color2: '#12AE90', direction: '180deg' },
  environmentBanner: { demo: { color: '#e589d3' }, test: { color: '#69a9ce' }, dev: { color: '#75d6cc' } },
};

export const GlobalSyles = () => (
  <Global
    styles={css`
      ${normalize}
      * {
        box-sizing: border-box;
      }
      html {
        margin: 0 inherit;
        height: 100%;
        font-family: 'Open Sans', sans-serif;
        font-size: 10px;
      }
      body {
        padding: 0;
        height: 100%;
        margin-top: 0;
        -webkit-font-smoothing: antialiased;
        font-size: 1.5rem;
        background: ${theme.wildRose};
        color: ${theme.cello};
      }
      #app {
        width: 100%;
        height: 100%;
      }
      h1 {
        font-weight: bold;
      }
      a {
        text-decoration: none;
      }
      a:hover {
        text-decoration: underline;
      }

      .unselectable {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      .flip-vertical {
        -webkit-transform: scaleY(-1);
        -moz-transform: scaleY(-1);
        -o-transform: scaleY(-1);
        transform: scaleY(-1);
      }

      .headline,
      .headline-14 {
        color: #607587;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.22px;
        line-height: 19px;
      }

      .headline-16 {
        color: #607587;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.34px;
        line-height: 22px;
      }

      .headlineH3,
      .headline-18 {
        color: #5e768e;
        font-family: 'Open Sans', sans-serif;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.01px;
        line-height: 24px;
      }

      .headlineH2,
      .headline-20 {
        color: ${theme.cello};
        font-family: 'Open Sans', sans-serif;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.09px;
        line-height: 27px;
      }
      
      .headline-22 {
        color: ${theme.cello};
        font-family: 'Open Sans', sans-serif;
        font-size: 22px;
        font-weight: bold;
        line-height: 30px;
      }
      .headlineH1,
      
      .headline-32 {
        color: ${theme.cello};
        font-family: 'Open Sans', sans-serif;
        font-size: 32px;
        font-weight: bold;
        letter-spacing: 0.09px;
        line-height: 43px;
      }
      .title-14 {
        color: ${ theme.cello };
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        line-height: 19px;
        font-weight: 600;
      }

      .title-16 {
        color: ${ theme.cello };
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        line-height: 22px;
        font-weight: 700;
      }

      .text-14 {
        color: ${theme.lynch};
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
      }

    `}
  />
);

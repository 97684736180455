import React from 'react';
import styled from '@emotion/styled';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';

import Button, { StyledButton } from 'components/Button';
import VasGraph from '../graph/VasGraph';
import iconHome from '@resources/img/ikon-home.png';
import messages from '../messages';

export default class VasTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showGraph: true,
      date: moment(),
      sortedAllEntries: [],
    };
  }
  componentDidMount() {
    this.componentDidMountOrUpdate();
  }

  componentDidUpdate(prevProps) {
    if (this.props.dataPoints.length !== prevProps.dataPoints.length) {
      this.componentDidMountOrUpdate();
    }
  }

  componentDidMountOrUpdate = () => {
    let sortedAllEntries = this.props.dataPoints.slice().sort((a, b) => b.timestamp - a.timestamp);
    this.setState({
      sortedAllEntries: sortedAllEntries,
    });
  };

  static propTypes = {
    id: PropTypes.number.isRequired,
    dataPoints: PropTypes.array.isRequired,
    scaleTypes: PropTypes.array.isRequired,
    createdAt: PropTypes.number.isRequired,
  };

  getFillerRows = (curRows) => {
    let rows = [];
    for (let i = curRows; i < 21; i++) {
      rows.push(<StyledVasRow key={i} backgroundColor={i % 2 === 0 ? 'rgba(240, 242, 245, 0.5)' : null} />);
    }
    return rows;
  };

  mapVasRows = () => {
    if (this.state.sortedAllEntries.length === 0) {
      return null;
    }
    let prevMoment = moment(0);
    let elements = [];

    this.state.sortedAllEntries.forEach((entry, i) => {
      let date = moment(entry.timestamp * 1000);
      let element = (
        <StyledVasRow backgroundColor={i % 2 === 0 ? 'rgba(240, 242, 245, 0.5)' : undefined} key={`styleVasRow${i}`}>
          <div className="box">
            <div className="cell date">
              {this.props.sentHomeAt && entry.timestamp > this.props.sentHomeAt && <img src={iconHome} />}{' '}
              {!prevMoment.isSame(date, 'day') ? date.format('YYYY.MM.DD') : ''}
            </div>
            <div className="cell time">{date.format('HH:mm')}</div>
            {this.props.scaleTypes.map((type) => (
              <div className="cell value" key={type}>
                {type === entry.scaleType ? entry.value : ''}
              </div>
            ))}
          </div>
        </StyledVasRow>
      );
      elements.push(element);
      prevMoment = date;
    });
    return [...elements, ...this.getFillerRows(this.state.sortedAllEntries.length)];
  };

  getHeadline = (type) => {
    switch (type) {
      case 'NRS_PAIN':
        return <FormattedMessage {...messages.pain} />;
      case 'NRS_NAUSEA':
        return <FormattedMessage {...messages.nausea} />;
      default:
        return <FormattedMessage {...messages.unknown} />;
    }
  };

  copyRowDataToClipboard = () => {
    const lines = [];
    this.props.scaleTypes.forEach((type) => {
      lines.push(this.getHeadline(type));
      console.log(lines.push(this.getHeadline(type)));
      lines.push(<FormattedMessage {...messages.copiedClipboardDataHeadlines} />);
      this.props.dataPoints
        .filter((outcome) => outcome.scaleType === type)
        .sort((a, b) => b.timestamp - a.timestamp)
        .map((entry) => {
          const datestr = moment(entry.timestamp * 1000).format('YYYY-MM-DD   HH:mm');
          lines.push(`${datestr}   ${entry.value} ${this.props.sentHomeAt && entry.timestamp > this.props.sentHomeAt ? '🏚' : ''}`);
        });
      lines.push('');
    });
    navigator.clipboard.writeText(lines.join('\n')).then(() => alert(<FormattedMessage {...messages.alertCopiedSuccess} />));
  };

  render() {
    const { showGraph } = this.state;

    return (
      <StyledVasTable>
        <div className="main">
          <div className="content">
            <div className="chart-list-selector">
              <div></div>
              <div>
                <Button
                  tooltip={<FormattedMessage {...messages.tooltipToggleGraph} />}
                  primary={this.state.showGraph}
                  onClick={() => this.setState({ showGraph: true })}
                >
                  <FontAwesomeIcon icon="chart-line" />
                </Button>
                <Button
                  tooltip={<FormattedMessage {...messages.tooltipToggleTable} />}
                  primary={!this.state.showGraph}
                  onClick={() => this.setState({ showGraph: false })}
                >
                  <FontAwesomeIcon icon="list-ul" />
                </Button>
              </div>
              <div>
                <Button tooltip={<FormattedMessage {...messages.tooltipCopyClipboard} />} onClick={() => this.copyRowDataToClipboard()}>
                  <FontAwesomeIcon icon={faCopy} />
                </Button>
              </div>
            </div>
            {showGraph ? (
              <VasGraph
                id={this.props.id}
                vasValues={this.state.sortedAllEntries}
                createdAt={this.props.createdAt}
                scaleTypes={this.props.scaleTypes}
                sentHomeAt={this.props.sentHomeAt}
              />
            ) : (
              <React.Fragment>
                <div className="headers headline">
                  <div>
                    <FormattedMessage {...messages.date} />
                  </div>
                  <div>
                    <FormattedMessage {...messages.time} />
                  </div>
                  {this.props.scaleTypes.map((type) => (
                    <div key={type}>{this.getHeadline(type)}</div>
                  ))}
                </div>
                {this.mapVasRows()}
              </React.Fragment>
            )}
          </div>
        </div>
      </StyledVasTable>
    );
  }
}

const StyledVasTable = styled.div`
  width: 100%;
  .main {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 810px;
    background-color: ${({ theme }) => theme.white};
    border-radius: 5px;
    box-shadow: 0 2px 6px 2px rgba(205, 215, 223, 0.3);
  }
  .chart-list-selector {
    display: flex;
    ${StyledButton} {
      padding: 10px 25px;
    }
    justify-content: space-between;
  }
  .content {
    margin: 20px 40px 53px 40px;
    width: 100%;
    .chart {
    }
    .headers {
      width: 80%;
      margin-left: 10px;
      padding: 30px 0 15px 0;
      div {
        width: 20%;
        display: inline-block;
      }
    }
  }
`;

const StyledVasRow = styled.div`
  height: 32px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-top: ${({ date, theme }) => (date ? `1px solid ${theme.loblolly}` : 0)};
  .box {
    width: 80%;
    margin-left: 10px;
    margin-right: 15%;

    .cell {
      width: 20%;
      display: inline-block;
      text-align: left;
      padding: 2px 0 2px 0;
    }
    .date {
      color: ${({ theme }) => theme.lynch};
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.25px;
      line-height: 19px;
    }
    .time {
      color: ${({ theme }) => theme.darkGrey};
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.25px;
      line-height: 22px;
    }
    .value {
      color: ${({ theme }) => theme.cello};
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0.09px;
      line-height: 27px;
    }
  }
`;

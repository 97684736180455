import { useContext } from 'react';
import { OrganizationActionContext } from './OrganizationProvider';
var useOrganizationAction = function () {
    var context = useContext(OrganizationActionContext);
    if (context === undefined) {
        throw new Error('useOrganizationAction must be used within a OrganizationProvider');
    }
    return context;
};
export default useOrganizationAction;

import { useContext } from 'react';
import { OrganizationStateContext } from './OrganizationProvider';
var useOrganizationState = function () {
    var context = useContext(OrganizationStateContext);
    if (context === undefined) {
        throw new Error('useOrganizationState must be used within a OrganizationProvider');
    }
    return context;
};
export default useOrganizationState;

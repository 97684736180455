import React from 'react';
import PropTypes from 'prop-types';
import Button, { StyledButton } from 'components/Button';
import Modal from 'components/Modal/Modal';
import ModalFooter from 'components/Modal/ModalFooterButtons';
import styled from '@emotion/styled';
import QRCode from 'qrcode.react';
import { FormattedMessage } from 'react-intl';
import globalMessages from '@src/globalMessages';
import messages from './messages';

export default class ShareUserCalendar extends React.Component {
  static propTypes = {
    calendarIdentifier: PropTypes.string.isRequired,
    dismiss: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let origin = window.location.origin;
    const url = `${origin}/rest/calendar/v1/ics/${this.props.calendarIdentifier}.ics`;
    return (
      <Modal
        ref={this.modalRef}
        title={this.props.title || <FormattedMessage {...messages.shareCalendar} />}
        dismiss={this.props.dismiss}
        tainted={this.state.tainted}
      >
        <ShareUserCalendarContent>
          <QRCode value={url} />
        </ShareUserCalendarContent>
        <ModalFooter>
          <Button className="button" medium onClick={() => this.props.dismiss()}>
            <span>
              <FormattedMessage {...globalMessages.cancel} />
            </span>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const ShareUserCalendarContent = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  align-items: center;

  h1 {
    text-align: center;
    margin: 5rem 0;
    padding-bottom: 1rem;
    box-shadow: 0 8px 6px -6px ${({ theme }) => theme.lightGrey};
  }
  .buttonGroup {
    padding: 3rem;
    span {
      padding-left: 0.5rem;
    }
    ${StyledButton} {
      margin: 1rem;
    }
  }
`;

/*
 * PatientList Messages
 *
 * This contains all the text for the PatientList component.
 */
import { defineMessages } from 'react-intl';
var scope = 'app.components.PatientList';
export default defineMessages({
    header: {
        id: "".concat(scope, ".header"),
        defaultMessage: 'Patientöversikt',
    },
    addPatient: {
        id: "".concat(scope, ".addPatient"),
        defaultMessage: 'Lägg till patient',
    },
    lastUpdated: {
        id: "".concat(scope, ".lastUpdated"),
        defaultMessage: 'SENAST UPPDATERAD',
    },
    noPatients: {
        id: "".concat(scope, ".noPatients"),
        defaultMessage: 'Inga patienter',
    },
    room: {
        id: "".concat(scope, ".room"),
        defaultMessage: 'RUM:SÄNG',
    },
    name: {
        id: "".concat(scope, ".name"),
        defaultMessage: 'NAMN',
    },
    patient: {
        id: "".concat(scope, ".patient"),
        defaultMessage: 'PATIENT',
    },
    checklist: {
        id: "".concat(scope, ".checklist"),
        defaultMessage: 'CHECKLISTA',
    },
    diagnosis: {
        id: "".concat(scope, ".diagnosis"),
        defaultMessage: 'DIAGNOSKOD',
    },
    nausea: {
        id: "".concat(scope, ".nausea"),
        defaultMessage: 'ILLAMÅENDE',
    },
    pain: {
        id: "".concat(scope, ".pain"),
        defaultMessage: 'SMÄRTA',
    },
});

import { defineMessages } from 'react-intl';
var scope = 'app.pages.Admin.OrganizationPage';
var modalScope = 'app.pages.Admin.OrganizationPage.Modal';
export default defineMessages({
    heading: {
        id: "".concat(scope, ".heading"),
        defaultMessage: 'Organisation',
    },
    searchPlaceholder: {
        id: "".concat(scope, ".searchPlaceholder"),
        defaultMessage: 'Sök organisation',
    },
    subHeading: {
        id: "".concat(scope, ".subHeading"),
        defaultMessage: 'Lägg till och hantera organisationer',
    },
    confirmationRemoveHeader: {
        id: "".concat(modalScope, ".confirmationRemoveHeader"),
        defaultMessage: 'Ta bort organization',
    },
    confirmationRemoveText: {
        id: "".concat(modalScope, ".confirmationRemoveText"),
        defaultMessage: 'Är du säker att du vill ta bort',
    },
});

import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import './loadingIndicator.scss';
var LoadingIndicatorComponent = function (_a) {
    var msDelay = _a.msDelay;
    var _b = useState(false), showSpinner = _b[0], setShowSpinner = _b[1];
    useEffect(function () {
        setShowSpinner(true);
        var timeout = setTimeout(function () { return setShowSpinner(false); }, msDelay || 500);
        return clearTimeout(timeout);
    }, [showSpinner]);
    return showSpinner ? _jsx(FontAwesomeIcon, { icon: faSpinner, spin: true, className: 'loading-indicator' }) : null;
};
export default LoadingIndicatorComponent;

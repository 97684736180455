import Axios from 'axios';

import * as actions from '../constants/actions';

export const loadAll = () => (dispatch) => {
  dispatch(loadPatients());
  dispatch(loadPersonnel());
  dispatch(loadCategories());
  dispatch(loadLocations());
  dispatch(loadDrafts());
};

export const loadPatients = () => (dispatch) => {
  dispatch({
    type: actions.UPDATE_LOADING,
    data: {
      key: 'patients',
      value: true,
    },
  });
  Axios.get('/rest/calendar/v1/user?type=PATIENT')
    .then((result) => {
      dispatch({
        type: actions.LOADED_CALENDAR_PATIENTS,
        data: result.data,
      });
    })
    .catch((err) => {
      console.warn('loadPatients failed', err);
    });
};

export const loadPersonnel = () => (dispatch) => {
  dispatch({
    type: actions.UPDATE_LOADING,
    data: {
      key: 'personnel',
      value: true,
    },
  });
  Axios.get('/rest/calendar/v1/user?type=PERSONNEL')
    .then((result) => {
      dispatch({
        type: actions.LOADED_CALENDAR_PERSONNEL,
        data: result.data,
      });
    })
    .catch((err) => {
      console.warn('loadPersonnel failed', err);
    });
};

export const loadLocations = () => (dispatch) => {
  dispatch({
    type: actions.UPDATE_LOADING,
    data: {
      key: 'locations',
      value: true,
    },
  });
  Axios.get('/rest/calendar/v1/user?type=LOCATION')
    .then((result) => {
      dispatch({
        type: actions.LOADED_CALENDAR_LOCATIONS,
        data: result.data,
      });
    })
    .catch((err) => {
      console.warn('loadLocations failed', err);
    });
};

export const loadCategories = () => (dispatch) => {
  dispatch({
    type: actions.UPDATE_LOADING,
    data: {
      key: 'categories',
      value: true,
    },
  });

  Axios.get('/rest/calendar/v1/user?type=CATEGORY')
    .then((result) => {
      dispatch({
        type: actions.LOADED_CALENDAR_CATEGORIES,
        data: result.data,
      });
    })
    .catch((err) => {
      console.warn('loadLocations failed', err);
    });
};

export const loadDrafts = () => (dispatch) => {
  dispatch({
    type: actions.UPDATE_LOADING,
    data: {
      key: 'drafts',
      value: true,
    },
  });

  Axios.get('/rest/calendar/v1/draft')
    .then((result) => {
      dispatch({
        type: actions.LOADED_CALENDAR_DRAFTS,
        data: result.data,
      });
    })
    .catch((err) => {
      console.warn('loadDrafts failed', err);
    });
};

export const bookDrafts =
  ({ data, success, fail }) =>
  (dispatch) => {
    let ids = data.map((value) => ({ id: value.id }));
    Axios.post('/rest/calendar/v1/draft', ids)
      .then((result) => {
        dispatch({
          type: actions.DRAFTS_BOOKED,
          data: result.data,
        });
        dispatch(loadDrafts());
        if (success) {
          success();
        }
      })
      .catch((err) => {
        if (fail) {
          fail(err);
        } else {
          console.warn('bookDrafts failed', err);
        }
      });
  };

export const addUser =
  ({ data, success, fail }) =>
  (dispatch) => {
    let user = {};
    user.type = data.type;
    user.displayName = data.displayName;
    user.email = data.email;
    user.color = data.color;

    Axios.post('/rest/calendar/v1/user', user)
      .then((result) => {
        dispatch({
          type: actions.ADDED_CALENDAR_USER,
          data: result.data,
        });
        if (result.data.type === 'PATIENT') {
          dispatch(loadPatients());
        } else if (result.data.type === 'PERSONNEL') {
          dispatch(loadPersonnel());
        } else if (result.data.type === 'LOCATION') {
          dispatch(loadLocations());
        } else if (result.data.type === 'CATEGORY') {
          dispatch(loadCategories());
        }
        if (success) {
          success();
        }
      })
      .catch((err) => {
        if (fail) {
          fail(err);
        } else {
          console.warn('addUser failed', err);
        }
      });
  };

export const updateUser =
  ({ data, userId, success, fail }) =>
  (dispatch) => {
    let user = {};
    user.type = data.type;
    user.displayName = data.displayName;
    user.email = data.email;
    user.color = data.color;

    Axios.put(`/rest/calendar/v1/user/${userId}`, user)
      .then((result) => {
        dispatch({
          type: actions.UPDATED_CALENDAR_USER,
          data: result.data,
        });
        if (result.data.type === 'PATIENT') {
          dispatch(loadPatients());
        } else if (result.data.type === 'PERSONNEL') {
          dispatch(loadPersonnel());
        } else if (result.data.type === 'LOCATION') {
          dispatch(loadLocations());
        } else if (result.data.type === 'CATEGORY') {
          dispatch(loadCategories());
        }
        if (success) {
          success();
        }
      })
      .catch((err) => {
        if (fail) {
          fail(err);
        } else {
          console.warn('updateUser failed', err);
        }
      });
  };

export const getUserEvents =
  ({ userId, onlyFuture, success, fail }) =>
  (dispatch) => {
    const onSuccess = success || noop;
    const onFail = fail || noop;
    Axios.get(`/rest/calendar/v1/user/${userId}/event`)
      .then((result) => {
        onSuccess(result.data);
      })
      .catch((err) => {
        onFail(err);
      });
  };

export const getEvent =
  ({ eventId, success, fail }) =>
  (dispatch) => {
    Axios.get(`/rest/calendar/v1/event/${eventId}`)
      .then((result) => {
        dispatch({
          type: actions.GET_CALENDAR_EVENT,
          data: result.data,
        });
        if (success) {
          success(result.data);
        }
      })
      .catch((err) => {
        if (fail) {
          fail(err);
        } else {
          console.warn('updateUser failed', err);
        }
      });
  };

export const addDraft =
  ({ data, success, fail }) =>
  (dispatch) => {
    let event = {};
    event.title = data.title;
    event.start = data.start;
    event.end = data.end;
    event.description = data.description;
    event.location = data.location;
    event.category = data.category;
    event.recursion = data.recursion;
    event.participants = data.participants;

    Axios.post('/rest/calendar/v1/event', event)
      .then((result) => {
        dispatch({
          type: actions.ADDED_CALENDAR_EVENT,
          data: result.data,
        });
        dispatch(loadDrafts());
        if (success) {
          success();
        }
      })
      .catch((err) => {
        if (fail) {
          fail(err);
        } else {
          console.warn('addDraft failed', err);
        }
      });
  };

export const updateDraft =
  ({ data, eventId, success, fail }) =>
  (dispatch) => {
    let event = {};
    event.title = data.title;
    event.start = data.start;
    event.end = data.end;
    event.description = data.description;
    event.location = data.location;
    event.category = data.category;
    event.participants = data.participants;

    Axios.put(`/rest/calendar/v1/event/${eventId}`, event)
      .then((result) => {
        dispatch({
          type: actions.UPDATED_CALENDAR_EVENT,
          data: result.data,
        });
        dispatch(loadDrafts());
        if (success) {
          success();
        }
      })
      .catch((err) => {
        if (fail) {
          fail(err);
        } else {
          console.warn('updateDraft failed', err);
        }
      });
  };

export const deleteDraft =
  ({ eventId, success, fail }) =>
  (dispatch) => {
    Axios.delete(`/rest/calendar/v1/event/${eventId}`)
      .then(() => {
        dispatch({
          type: actions.DELETED_CALENDAR_EVENT,
        });
        dispatch(loadDrafts());
        if (success) {
          success();
        }
      })
      .catch((err) => {
        if (fail) {
          fail(err);
        } else {
          console.warn('deleteDraft failed', err);
        }
      });
  };

export const setStatusDraft =
  ({ eventId, success, fail }) =>
  (dispatch) => {
    let data = {};
    data.status = 'DRAFT';

    Axios.put(`/rest/calendar/v1/event/${eventId}/status`, data)
      .then((result) => {
        dispatch({
          type: actions.UPDATED_CALENDAR_EVENT,
          data: result.data,
        });
        dispatch(loadDrafts());
        if (success) {
          success();
        }
      })
      .catch((err) => {
        if (fail) {
          fail(err);
        } else {
          console.warn('setStatusDraft failed', err);
        }
      });
  };

export const applyFilter =
  ({ filter, success, fail }) =>
  (dispatch) => {
    if (filter.patientIds.size + filter.personnelIds.size + filter.categoryIds.size + filter.locationIds.size === 0) {
      dispatch({
        type: actions.UPDATE_FILTER,
        data: filter,
      });
      dispatch({
        type: actions.LOADED_EVENTS,
        data: {
          events: [],
          users: [],
        },
      });
      return;
    }
    const data = {
      patientIds: [...filter.patientIds],
      personnelIds: [...filter.personnelIds],
      categoryIds: [...filter.categoryIds],
      locationIds: [...filter.locationIds],
      start: filter.start,
      end: filter.end,
    };

    Axios.post('/rest/calendar/v1/event/filter', data)
      .then((result) => {
        dispatch({
          type: actions.UPDATE_FILTER,
          data: filter,
        });
        dispatch({
          type: actions.LOADED_EVENTS,
          data: result.data,
        });
        if (success) {
          success();
        }
      })
      .catch((err) => {
        if (fail) {
          fail(err);
        } else {
          console.warn('apply filter failed', err);
        }
      });
  };

function noop() {}

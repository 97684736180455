var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import styled from '@emotion/styled';
import { theme } from 'constants/styling';
import { getDiagnoses } from '@api/patient/patients';
import { setDiagnosesAction } from '@hooks/useOrganization/actions';
import { updatePatient } from '@api/patient/patient';
import { setPatientAction } from '@hooks/usePatientDetail/actions';
import LoadingIndicatorComponent from '@components/Common/LoadingIndicatorComponent/LoadingIndicatorComponent';
import Button from '../Button/index';
import Modal from '../Modal/Modal';
import ModalFooter from '../Modal/ModalFooterButtons';
import useOrganizationState from '@hooks/useOrganization/useOrganizationState';
import useOrganizationAction from '@hooks/useOrganization/useOrganizationAction';
import usePatientDetailAction from '@hooks/usePatientDetail/usePatientDetailAction';
import PatientBox from '@components/PatientBox/PatientBox';
import messages from '@src/pages/PatientListPage/PatientList/PatientFormModal/messages';
import globalMessages from '@src/globalMessages';
var DiagnosisModal = function (_a) {
    var _b, _c, _d;
    var patient = _a.patient, dismiss = _a.dismiss;
    var diagnoses = useOrganizationState().diagnoses;
    var formatMessage = useIntl().formatMessage;
    var organizationDispatch = useOrganizationAction();
    var dispatch = usePatientDetailAction();
    var _e = useState((_b = patient.diagnosis) === null || _b === void 0 ? void 0 : _b.code), selectedCode = _e[0], setSelectedCode = _e[1];
    var formattedModalHeadline = formatMessage(messages.diagnosis).charAt(0).toLocaleUpperCase() + formatMessage(messages.diagnosis).slice(1).toLowerCase();
    useEffect(function () {
        if (!diagnoses) {
            getDiagnoses().then(function (value) {
                organizationDispatch(setDiagnosesAction(value));
            });
        }
    }, []);
    var onSave = function () {
        var _a, _b;
        var data = {
            ssn: patient.ssn,
            name: patient.name,
            surName: patient.surName,
            gender: patient.gender,
            diagnosis: diagnoses === null || diagnoses === void 0 ? void 0 : diagnoses.find(function (d) { return d.code === selectedCode; }),
            roomId: (_a = patient.room) === null || _a === void 0 ? void 0 : _a.id,
            bedId: (_b = patient.bed) === null || _b === void 0 ? void 0 : _b.id,
            modules: [],
        };
        updatePatient(patient.id, data).then(function (value) {
            dispatch(setPatientAction(value));
            dismiss();
        });
    };
    var options = diagnoses && diagnoses.map(function (diagnosis) { return ({ value: diagnosis.code, label: "".concat(diagnosis.code, " - ").concat(diagnosis.description) }); });
    return (_jsxs(Modal, __assign({ maxWidth: "845px", title: formattedModalHeadline, dismiss: dismiss, tainted: selectedCode !== ((_c = patient.diagnosis) === null || _c === void 0 ? void 0 : _c.code) }, { children: [_jsx(StyledContent, { children: _jsxs("div", __assign({ className: "diagnosisModalInner" }, { children: [_jsx(PatientBox, { className: "patient-box", patient: patient }), _jsx("p", __assign({ id: "diagnosisModalSubtTitle", className: "headline" }, { children: formatMessage(messages.diagnosis) })), _jsx("div", __assign({ className: "select" }, { children: options ? (_jsx(Select, { classNamePrefix: "dropdown", theme: function (selectTheme) { return (__assign(__assign({}, selectTheme), { borderRadius: 5, colors: __assign(__assign({}, selectTheme.colors), { danger: theme.darkRed, dangerLight: theme.lightRed, primary25: theme.lightBlue, primary50: theme.dodgerBlue, primary75: theme.dodgerBlue, primary: theme.lightBlue }) })); }, value: options.find(function (o) { return o.value == selectedCode; }), onChange: function (option) { return setSelectedCode(option === null || option === void 0 ? void 0 : option.value); }, placeholder: "V\u00E4lj", options: options })) : (_jsx(LoadingIndicatorComponent, {})) }))] })) }), _jsxs(ModalFooter, { children: [_jsx(Button, __assign({ medium: true, hover: true, onClick: function () { return dismiss(); } }, { children: _jsx("span", { children: formatMessage(globalMessages.cancel) }) })), _jsx(Button, __assign({ medium: true, hover: true, primary: true, disabled: !selectedCode || selectedCode === ((_d = patient.diagnosis) === null || _d === void 0 ? void 0 : _d.code), onClick: onSave }, { children: _jsx("span", { children: formatMessage(globalMessages.save) }) }))] })] })));
};
export default DiagnosisModal;
var StyledContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding-bottom: 134px;\n\n  .patient-box {\n    margin-bottom: 30px;\n  }\n  #diagnosisModalSubtTitle {\n    padding-bottom: 12px;\n    margin: 0 0 19px 0;\n    border: 0 solid;\n    border-color: ", ";\n    border-bottom-width: 1px;\n  }\n\n  .dropdown__control {\n    height: 34px;\n    min-height: 34px;\n    box-sizing: border-box;\n  }\n\n  .dropdown__indicators {\n    height: 32px;\n  }\n\n  .dropdown__single-value,\n  .dropdown__placeholder {\n    color: ", ";\n    font-weight: 600;\n    padding-bottom: 3px;\n    font-size: 16px;\n  }\n\n  .dropdown__option--is-focused {\n    color: ", ";\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding-bottom: 134px;\n\n  .patient-box {\n    margin-bottom: 30px;\n  }\n  #diagnosisModalSubtTitle {\n    padding-bottom: 12px;\n    margin: 0 0 19px 0;\n    border: 0 solid;\n    border-color: ", ";\n    border-bottom-width: 1px;\n  }\n\n  .dropdown__control {\n    height: 34px;\n    min-height: 34px;\n    box-sizing: border-box;\n  }\n\n  .dropdown__indicators {\n    height: 32px;\n  }\n\n  .dropdown__single-value,\n  .dropdown__placeholder {\n    color: ", ";\n    font-weight: 600;\n    padding-bottom: 3px;\n    font-size: 16px;\n  }\n\n  .dropdown__option--is-focused {\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.athensGray;
}, function (_a) {
    var theme = _a.theme;
    return theme.lynch;
}, function (_a) {
    var theme = _a.theme;
    return theme.white;
});
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
var Cell = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 3rem;\n  font-weight: bold;\n  cursor: ", ";\n  width: ", ";\n"], ["\n  font-size: 3rem;\n  font-weight: bold;\n  cursor: ", ";\n  width: ", ";\n"])), function (_a) {
    var onClick = _a.onClick;
    return (onClick ? 'pointer' : 'inherit');
}, function (_a) {
    var width = _a.width;
    return width || '100px';
});
export default Cell;
var templateObject_1;

import { useContext } from 'react';
import { PatientListStateContext } from '@hooks/usePatientList/PatientListProvider';
var usePatientListState = function () {
    var context = useContext(PatientListStateContext);
    if (context === undefined) {
        throw new Error('usePatientListState must be used within a PatientListProvider');
    }
    return context;
};
export default usePatientListState;

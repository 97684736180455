import * as actions from '../constants/actions';

export const showNotification = ({title, message}) => dispatch => {
  dispatch({
    type: actions.SHOW_NOTIFICATION,
    data: { title, message }
  });
};

export const dismissNotification = () => dispatch => {
  dispatch({
    type: actions.HIDE_NOTIFICATION
  });
};

import { defineMessages } from 'react-intl';
var scope = 'app.pages.PatientList.PatientListItem';
export default defineMessages({
    ageSubtitle: {
        id: "".concat(scope, ".ageSubtitle"),
        defaultMessage: 'år',
    },
    ageSubtitleSingular: {
        id: "".concat(scope, ".ageSubtitleSingular"),
        defaultMessage: 'år',
    },
    femaleSubtitle: {
        id: "".concat(scope, ".femaleSubtitle"),
        defaultMessage: 'Kvinna',
    },
    maleSubtitle: {
        id: "".concat(scope, ".maleSubtitle"),
        defaultMessage: 'Man',
    },
    trendTextToday: {
        id: "".concat(scope, ".trendTextToday"),
        defaultMessage: 'Idag',
    },
    trendTextYesterday: {
        id: "".concat(scope, ".trendTextYesterday"),
        defaultMessage: 'Igår',
    },
});

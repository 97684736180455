import { useContext } from 'react';
import { AdminStateContext } from '@hooks/useAdmin/AdminProvider';
var useAdminState = function () {
    var context = useContext(AdminStateContext);
    if (context === undefined) {
        throw new Error('useAdminState must be used within a AdminProvider');
    }
    return context;
};
export default useAdminState;

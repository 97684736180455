import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';

import { applyFilter, getEvent } from 'actions/calendar';
import CalendarWidget from 'components/Calendar/CalendarWidget/CalendarWidget';
import globalMessages from '@src/globalMessages';
import messages from '../DraftsPane/messages';
import '../CalendarWidget/CalendarWidget';

class CalendarPane extends PureComponent {
  static propTypes = {
    startEditEvent: PropTypes.func.isRequired,
  };

  handleDateClick = (arg) => {
    const event = this.props.calendar.selectedEvent;
    const { formatMessage } = this.props.intl;
    if (event && event.tainted) {
      alert(formatMessage(globalMessages.unsavedChangesMessage));
    } else {
      const participantIds = new Set([...this.props.calendar.filter.patientIds, ...this.props.calendar.filter.personnelIds]);
      this.props.startEditEvent({
        start: moment(arg.start).unix(),
        end: moment(arg.end).unix(),
        participantIds: participantIds,
        status: 'DRAFT',
      });
    }
  };

  handleEventClick = (arg) => {
    const event = this.props.calendar.selectedEvent;
    const { formatMessage } = this.props.intl;
    if (event && event.tainted) {
      alert(formatMessage(globalMessages.unsavedChangesMessage));
      arg.revert();
    } else {
      this.props.getEvent({
        eventId: arg.event.id,
        success: this.props.startEditEvent,
      });
    }
  };

  handleEventDrop = (arg) => {
    const event = this.props.calendar.selectedEvent;
    const { formatMessage } = this.props.intl;
    if (event && event.id && event.id.toString() !== arg.event.id && event.tainted) {
      alert(formatMessage(globalMessages.unsavedChangesMessage));
      arg.revert();
    } else {
      this.props.getEvent({
        eventId: arg.event.id,
        success: (event) => {
          this.props.startEditEvent({
            ...event,
            start: moment(arg.event.start).unix(),
            end: moment(arg.event.end).unix(),
          });
        },
      });
    }
  };

  handleEventResize = (arg) => {
    const event = this.props.calendar.selectedEvent;
    const { formatMessage } = this.props.intl;
    if (event && event.id && event.id.toString() !== arg.event.id && event.tainted) {
      alert(formatMessage(globalMessages.unsavedChangesMessage));
      arg.revert();
    } else {
      this.props.getEvent({
        eventId: arg.event.id,
        success: (event) => {
          this.props.startEditEvent({
            ...event,
            start: moment(arg.event.start).unix(),
            end: moment(arg.event.end).unix(),
          });
        },
      });
    }
  };

  viewRenderCallback = (arg) => {
    const filter = this.props.calendar.filter;
    const extra = {
      start: moment(arg.view.activeStart).unix(),
      end: moment(arg.view.activeEnd).unix(),
    };
    this.props.applyFilter({
      filter: { ...filter, ...extra },
    });
  };

  eventDataTransform = (eventData) => ({
    id: eventData.id,
    title: eventData.title,
    start: eventData.start * 1000,
    end: eventData.end * 1000,
    editable: eventData.status === 'DRAFT',
    color: eventData.category?.color || '#53a0fc',
    className: this.eventClasses(eventData),
    extendedProps: {
      description: eventData.description,
      organizerDisplayName: '', //eventData.updatedBy.displayName,
      location: eventData.location?.displayName || '',
      participants: `${eventData.participants ? eventData.participants.length : '0'}` + this.props.intl.formatMessage(messages.eventParticipants),
      focused: eventData.focused,
      selected: eventData.selected,
    },
  });

  eventClasses = (eventData) => {
    let c = eventData.focused ? ['event_focused'] : ['event_not_focused'];
    if (eventData.selected) {
      c.push('event_selected');
    }
    return c;
  };

  render() {
    return (
      <CalendarWidget
        settings={{
          select: this.handleDateClick,
          eventClick: this.handleEventClick,
          eventDrop: this.handleEventDrop,
          eventResize: this.handleEventResize,
          events: this.props.calendar.events,
          datesSet: this.viewRenderCallback,
          eventDataTransform: this.eventDataTransform,
          editable: true,
          selectable: true,
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  calendar: state.calendar,
});

const mapDispatchToProps = (dispatch) => ({
  applyFilter: ({ filter, success, fail }) => dispatch(applyFilter({ filter, success, fail })),
  getEvent: ({ eventId, success, fail }) => dispatch(getEvent({ eventId, success, fail })),
  dispatch,
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CalendarPane));

import React from 'react';
import styled from '@emotion/styled';

export default class Support extends React.PureComponent {
  render() {
    return (
      <SupportWrapper>
        <h1 className="headlineH1">Support</h1>
        <p className="text">
          Zengio gör att du som patient kan samverka med utvalda vårdgivare så att ni enkelt kan utbyta information. Syftet är att öka din
          delaktighet, förbättra vårdkvaliteten samt se till att vården fungerar som planerat.
        </p>
        <p className="text">
          För att få tillgång till tjänsten behöver din vårdgivare vara ansluten till Zengio. Du laddar ner Zengio när du och din vårdgivare kommit
          överens om detta och ni kan då enkelt börja utbyta information. Har du problem att ansluta dig eller vill rapportera ett fel, vänd dig till
          din vårdgivare.
        </p>
      </SupportWrapper>
    );
  }
}

const SupportWrapper = styled.div`
  max-width: 800px;
  margin: 20px auto;
  .text {
    font-size: 16px;
  }
`;

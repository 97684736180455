import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import CalendarPane from 'components/Calendar/CalendarPane/CalendarPane';
import CalendarUserDetails from 'components/Calendar/CalendarUserDetails';
import DraftsPane from 'components/Calendar/DraftsPane/DraftsPane';
import UserPane from 'components/Calendar/UserPane/UserPane';
import EventPane from 'components/Calendar/EventPane/EventPane';
import moment from 'moment';
import * as actions from 'constants/actions';
import MoveIn from './MoveIn';

class Calendar extends PureComponent {
  startEditEvent = event => {
    let extra = {
      participantIds: new Set(),
      typeDisabled: false,
      missingType: false,
      invalidTitle: false,
      titleDisabled: false,
      invalidStart: false,
      invalidEnd: false,
      recursionDisabled: true,
      invalidRecursionUntil: false,
      invalidRecursionRule: false,
      invalidParticipants: false,
      fieldValidationFailed: false,
      disabled: false, //event.status !== "DRAFT",
      tainted: false,
      selected: true,
      focused: true,
    };
    if (event.id) {
      extra.participantIds = new Set(event.participants.map(user => user.id));
    } else {
      if (event.participantIds) {
        extra.participantIds = event.participantIds;
      }
      let locationIds = this.props.calendar.filter.locationIds;
      if (locationIds.size > 0) {
        extra.location = {
          id: [...locationIds][0],
        };
      }
      let categoryIds = this.props.calendar.filter.categoryIds;
      if (categoryIds.size > 0) {
        const id = [...categoryIds][0];
        const title = this.props.calendar.categories
          .filter(category => category.id === id)[0]
          .displayName;
        extra.category = {
          id: id,
        };
        extra.titleDisabled = true;
        extra.title = title;
      }
    }
    if (!event.end) {
      extra.end = moment
        .unix(event.start)
        .add(1, 'hours')
        .unix();
    }
    if (event.category) {
      extra.titleDisabled = true;
      extra.title = event.category.displayName;
    }
    if (!event.recursion) {
      extra.recursion = {
        until: moment
          .unix(event.start)
          .add(1, 'month')
          .endOf('day')
          .unix(),
        rrule: 'FREQ=WEEKLY;INTERVAL=1;WKST=MO',
      };
    }
    this.props.dispatch({
      type: actions.EDIT_EVENT,
      data: { ...event, ...extra },
    });
  };

  render() {
    const drafts = this.props.calendar.drafts;
    return (
      <CalendarWrapper>
        <CalendarFlexContainer>
          <CalendarLeftContainer>
            <UserPane />
          </CalendarLeftContainer>
          <CalendarCenterContainer>
            <CalendarPane startEditEvent={this.startEditEvent} />
          </CalendarCenterContainer>
          {this.props.calendar.selectedEvent ? (
            <MoveIn>
              <CalendarRightContainer>
                <EventPane />
              </CalendarRightContainer>
            </MoveIn>
          ) : drafts.length > 0 ? (
            <MoveIn>
              <CalendarRightContainer>
                <DraftsPane startEditEvent={this.startEditEvent} />
              </CalendarRightContainer>
            </MoveIn>
          ) : (
            ''
          )}
        </CalendarFlexContainer>
        {this.props.calendar.selectedUser && <CalendarUserDetails />}
      </CalendarWrapper>
    );
  }
}

const mapStateToProps = state => ({
  calendar: state.calendar,
});

export default connect(mapStateToProps)(Calendar);

const CalendarWrapper = styled.div`
  padding: 2rem 1rem 1rem;
  height: 100%;
`;

const CalendarFlexContainer = styled.div`
  display: flex;
  height: 100%;
`;

const CalendarLeftContainer = styled.div`
  width: 220px;
  min-width: 220px;
  margin-top: 61px;
  box-shadow: 0 2px 6px 2px rgba(205, 215, 223, 0.4);
`;

const CalendarCenterContainer = styled.div`
  flex-grow: 1;
  margin: 0 10px;
`;

const CalendarRightContainer = styled.div`
  width: 250px;
  min-width: 250px;
  margin-top: 61px;
  box-shadow: 0 2px 6px 2px rgba(205, 215, 223, 0.4);
  height: 100%;
`;

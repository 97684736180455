import { useContext } from 'react';
import { AdminActionContext } from '@hooks/useAdmin/AdminProvider';
var useAdminAction = function () {
    var context = useContext(AdminActionContext);
    if (context === undefined) {
        throw new Error('useAdminAction must be used within a AdminProvider');
    }
    return context;
};
export default useAdminAction;

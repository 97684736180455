import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import * as actions from 'constants/actions';
import { connect } from 'react-redux';

import { addDraft, applyFilter, deleteDraft, updateDraft } from 'actions/calendar';
import { SidePane, SidePaneFooter, SidePaneHeader } from 'components/Calendar/SidePane/SidePane';
import Button from 'components/Button';
import EventParticipantsBox from 'components/Calendar/EventPane/EventParticipantsBox';
import EventDropDownBox from 'components/Calendar/EventPane/EventDropDownBox';
import EventTextBox from 'components/Calendar/EventPane/EventTextBox';
import EventTimeBox from 'components/Calendar/EventPane/EventTimeBox';
import RecursionContainer from 'components/Calendar/EventPane/RecursionContainer';
import globalMessages from '@src/globalMessages';
import messages from './messages';
import './event-pane.scss';

class EventPane extends PureComponent {
  checkValidForm = () => {
    const event = this.props.calendar.selectedEvent;
    let validation = {},
      invalid = false;

    if (!event.title || event.title === '') {
      invalid = true;
      validation.invalidTitle = true;
    }
    if (!event.start) {
      invalid = true;
      validation.invalidStart = true;
    }
    if (!event.end) {
      invalid = true;
      validation.invalidEnd = true;
    }
    if (event.start > event.end) {
      invalid = true;
      validation.invalidStart = true;
      validation.invalidEnd = true;
    }

    if (event.participantIds.size === 0 && !event.location && !event.category) {
      invalid = true;
      validation.invalidParticipants = true;
    }
    if (!event.recursionDisabled) {
      if (!event.recursion?.until) {
        invalid = true;
        validation.invalidRecursionUntil = true;
      }
      if (!event.recursion?.rrule) {
        invalid = true;
        validation.invalidRecursionRule = true;
      }
    }

    if (!invalid) {
      this.saveEvent(event);
    } else {
      validation.fieldValidationFailed = true;
      this.props.dispatch({
        type: actions.EDIT_EVENT,
        data: { ...event, ...validation },
      });
    }
  };

  saveEvent = (event) => {
    let payload = {
      title: event.title,
      start: event.start,
      end: event.end,
      description: event.description || '',
      location: event.location?.id,
      category: event.category?.id,
      recursion: !event.recursionDisabled
        ? {
            until: event.recursion?.until,
            rrule: event.recursion?.rrule,
          }
        : undefined,
      participants: [...event.participantIds],
    };
    if (event.id) {
      this.props.updateDraft({
        data: payload,
        eventId: event.id,
        success: this.updateFilter,
      });
    } else {
      this.props.addDraft({
        data: payload,
        success: this.updateFilter,
      });
    }
  };

  deleteDraft = () => {
    const event = this.props.calendar.selectedEvent;
    this.props.deleteDraft({
      eventId: event.id,
      success: this.updateFilter,
    });
  };

  updateFilter = () => {
    this.props.applyFilter({
      filter: this.props.calendar.filter,
    });
  };

  renderHeader = (title) => (
    <SidePaneHeader>
      <span>{title}</span>
    </SidePaneHeader>
  );

  renderFooter = (event) => (
    <SidePaneFooter>
      {event.id && (
        <Button className="button button-delete" medium onClick={this.deleteDraft}>
          <span>
            <FormattedMessage {...globalMessages.delete} />
          </span>
        </Button>
      )}
      <Button
        className="button"
        medium
        onClick={() => {
          this.props.dispatch({
            type: actions.CANCEL_EDIT_EVENT,
          });
        }}
      >
        <span>
          <FormattedMessage {...globalMessages.cancel} />
        </span>
      </Button>
      <Button className="button" disabled={event.disabled} primary hover medium onClick={this.checkValidForm}>
        {this.props.buttonText ? (
          <span>{this.props.buttonText}</span>
        ) : (
          <span>
            <FormattedMessage {...globalMessages.save} />
          </span>
        )}
      </Button>
    </SidePaneFooter>
  );

  onTitleValueChange = (value) => {
    let extra = {
      title: value.trimStart(),
      tainted: true,
    };
    const event = this.props.calendar.selectedEvent;
    if (event.fieldValidationFailed) {
      let invalid = false;
      if (extra.title.length === 0) {
        invalid = true;
      }
      extra = {
        ...extra,
        invalidTitle: invalid,
      };
    }
    this.updateEvent(extra);
  };

  onDescriptionValueChange = (value) => {
    this.updateEvent({
      description: value.trimStart(),
      tainted: true,
    });
  };

  onStartChange = (value) => {
    const event = this.props.calendar.selectedEvent;
    const diff = event.end - event.start;
    this.updateEvent({
      start: value,
      end: value + diff,
      tainted: true,
      invalidStart: false,
    });
  };

  onEndChange = (value) => {
    this.updateEvent({
      end: value,
      tainted: true,
      invalidEnd: false,
    });
  };

  onEventLocationChanged = (option) => {
    this.updateEvent({
      location: option ? { id: option.value } : undefined,
      tainted: true,
    });
  };

  onEventCategoryChanged = (option) => {
    this.updateEvent({
      category: option ? { id: option.value } : undefined,
      title: option ? option.label : '',
      titleDisabled: !!option,
      tainted: true,
    });
  };

  onToggleSelectUser = (userId, selected) => {
    const participantIds = new Set(this.props.calendar.selectedEvent.participantIds);
    if (selected) {
      participantIds.delete(userId);
      this.updateEvent({
        participantIds: participantIds,
        tainted: true,
      });
    } else {
      this.updateEvent({
        participantIds: participantIds.add(userId),
        tainted: true,
      });
    }
  };

  updateEvent = (values) => {
    this.props.dispatch({
      type: actions.EDIT_EVENT,
      data: { ...this.props.calendar.selectedEvent, ...values },
    });
  };

  render() {
    const event = this.props.calendar.selectedEvent;

    const participantIds = event.participantIds;
    const filter = this.props.calendar.filter;
    const selectedPatientIds = filter.patientIds;
    const selectedPersonnelIds = filter.personnelIds;
    const ids = new Set([...selectedPatientIds, ...selectedPersonnelIds, ...participantIds]);

    const selectablePatients = this.props.calendar.patients
      .filter((user) => ids.has(user.id))
      .map((user) => ({ id: user.id, displayName: user.displayName, selected: participantIds.has(user.id) }));
    const selectableResources = this.props.calendar.personnel
      .filter((user) => ids.has(user.id))
      .map((user) => ({ id: user.id, displayName: user.displayName, selected: participantIds.has(user.id) }));

    const locations = this.props.calendar.locations.map((location) => ({ label: location.displayName, value: location.id }));
    const selectedLocation = locations.filter((location) => event.location?.id === location.value);
    const categories = this.props.calendar.categories.map((category) => ({ label: category.displayName, value: category.id }));
    const selectedCategory = categories.filter((category) => event.category?.id === category.value);
    return (
      <SidePane
        header={this.renderHeader(
          event.id ? <FormattedMessage {...messages.headerEditBooking} /> : <FormattedMessage {...messages.headerNewBooking} />,
        )}
        footer={this.renderFooter(event)}
      >
        <div className={'event-pane-content'}>
          <EventTextBox
            title={<FormattedMessage {...messages.fieldLabelTitle} />}
            iconName={'activity'}
            disabled={event.disabled || event.titleDisabled}
            invalid={event.invalidTitle}
            errorMessage={<FormattedMessage {...messages.titleFieldValidationError} />}
            value={event.title}
            onValueChange={this.onTitleValueChange}
          />
          <EventDropDownBox
            title={<FormattedMessage {...messages.fieldLabelActivity} />}
            disabled={event.disabled}
            placeholder={<FormattedMessage {...globalMessages.selectPlaceholder} />}
            value={selectedCategory}
            options={categories}
            onValueChange={this.onEventCategoryChanged}
          />
          <EventTimeBox
            title={<FormattedMessage {...messages.fieldLabelDateFrom} />}
            iconName={'time'}
            disabled={event.disabled}
            invalid={event.invalidStart}
            errorMessage={<FormattedMessage {...messages.dateFromFieldValidationError} />}
            value={event.start}
            onValueChange={this.onStartChange}
          />
          <EventTimeBox
            title={<FormattedMessage {...messages.fieldLabelDateUntil} />}
            iconName={'time'}
            disabled={event.disabled}
            invalid={event.invalidEnd}
            errorMessage={<FormattedMessage {...messages.dateUntilFieldValidationError} />}
            value={event.end}
            onValueChange={this.onEndChange}
          />
          <EventParticipantsBox
            title={<FormattedMessage {...messages.fieldLabelPatients} />}
            iconName={'patient'}
            disabled={event.disabled}
            invalid={event.invalidParticipants}
            errorMessage={<FormattedMessage {...messages.participantsValidationError} />}
            selectable={selectablePatients}
            onValueChange={this.onToggleSelectUser}
          />
          <EventParticipantsBox
            title={<FormattedMessage {...messages.fieldLabelResources} />}
            iconName={'resource'}
            disabled={event.disabled}
            invalid={event.invalidParticipants}
            errorMessage={<FormattedMessage {...messages.participantsValidationError} />}
            selectable={selectableResources}
            onValueChange={this.onToggleSelectUser}
          />
          <EventDropDownBox
            title={<FormattedMessage {...messages.fieldLabelLocation} />}
            iconName={'room'}
            disabled={event.disabled}
            placeholder={<FormattedMessage {...globalMessages.selectPlaceholder} />}
            value={selectedLocation}
            options={locations}
            onValueChange={this.onEventLocationChanged}
          />
          <EventTextBox
            title={<FormattedMessage {...messages.fieldLabelDescription} />}
            disabled={event.disabled}
            value={event.description}
            onValueChange={this.onDescriptionValueChange}
          />
          {!event.id && <RecursionContainer />}
        </div>
      </SidePane>
    );
  }
}

const mapStateToProps = (state) => ({
  calendar: state.calendar,
});

const mapDispatchToProps = (dispatch) => ({
  addDraft: ({ data, success, fail }) => dispatch(addDraft({ data, success, fail })),
  updateDraft: ({ data, eventId, success, fail }) => dispatch(updateDraft({ data, eventId, success, fail })),
  deleteDraft: ({ eventId, success, fail }) => dispatch(deleteDraft({ eventId, success, fail })),
  applyFilter: ({ filter, success, fail }) => dispatch(applyFilter({ filter, success, fail })),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(EventPane);

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, ListItem } from '@components/Admin/common/styling/list';
import { ChecklistTemplateStatus } from '@api/admin/types';
import { deleteChecklistTemplate, loadChecklistTemplates, publishChecklistTemplate } from '@api/admin/actions';
import { pushErrorAction } from '@hooks/useError/actions';
import { setChecklistTemplatesAction } from '@hooks/useAdmin/actions';
import ConfirmationModal from 'components/Modal/ConfirmationModal';
import useErrorAction from '@hooks/useError/useErrorAction';
import useAdminAction from '@hooks/useAdmin/useAdminAction';
import IconEdit from '@resources/img/edit.svg';
import IconPublish from '@resources/img/publish.svg';
import IconTrash from '@resources/img/trash-checklist.svg';
import globalMessages from '@src/globalMessages';
import messages from '../messages';
var ChecklistListItem = function (_a) {
    var checklistTemplate = _a.checklistTemplate, showChecklistModal = _a.showChecklistModal;
    var adminDispatch = useAdminAction();
    var errorDispatch = useErrorAction();
    var formatMessage = useIntl().formatMessage;
    var _b = useState(false), showConfirmationModal = _b[0], setShowConfirmationModal = _b[1];
    var _c = useState(false), showPublishChecklistModal = _c[0], setShowPublishChecklistModal = _c[1];
    var onDelete = function (id) {
        deleteChecklistTemplate(id)
            .then(function () {
            loadChecklistTemplates()
                .then(function (templates) {
                adminDispatch(setChecklistTemplatesAction(templates));
            })
                .catch(function () {
                errorDispatch(pushErrorAction({
                    title: formatMessage(globalMessages.unexpectedError),
                    message: formatMessage(globalMessages.refreshPageMessage),
                }));
            });
        })
            .catch(function () {
            errorDispatch(pushErrorAction({
                title: formatMessage(globalMessages.unexpectedError),
                message: formatMessage(messages.removeErrorMessage),
            }));
        });
    };
    var createNewDraft = function (template) {
        showChecklistModal({
            identifier: template.identifier,
            title: template.title,
            description: template.description || '',
            items: template.items,
            status: ChecklistTemplateStatus.DRAFT,
        });
    };
    var editDraft = function (template) {
        if (template.status !== ChecklistTemplateStatus.DRAFT)
            return;
        showChecklistModal({
            id: template.id,
            identifier: template.identifier,
            title: template.title,
            description: template.description || '',
            items: template.items.map(function (i) { return ({ id: i.id, text: i.text, order: i.order }); }),
            status: template.status,
            version: template.version,
        });
    };
    var onPublishDraft = function (id) {
        publishChecklistTemplate(id)
            .then(function () {
            loadChecklistTemplates()
                .then(function (templates) {
                adminDispatch(setChecklistTemplatesAction(templates));
            })
                .catch(function () {
                errorDispatch(pushErrorAction({
                    title: formatMessage(globalMessages.unexpectedError),
                    message: formatMessage(globalMessages.refreshPageMessage),
                }));
            });
        })
            .catch(function () {
            errorDispatch(pushErrorAction({
                title: formatMessage(globalMessages.unexpectedError),
                message: formatMessage(messages.publishErrorMessage),
            }));
        });
    };
    var getStatusText = function (status) {
        switch (status) {
            case ChecklistTemplateStatus.DRAFT:
                return formatMessage(messages.statusDraft);
            case ChecklistTemplateStatus.PUBLISHED:
                return formatMessage(messages.statusPublished);
            case ChecklistTemplateStatus.REMOVED:
                return formatMessage(messages.statusInactive);
        }
    };
    return (_jsxs(React.Fragment, { children: [_jsxs(ListItem, __assign({ className: 'listItem' }, { children: [_jsxs(Box, __assign({ className: "left" }, { children: [_jsx(Box, __assign({ width: '100px', className: 'text-14' }, { children: _jsxs("p", { children: [formatMessage(messages.headerVersion), " ", checklistTemplate.version] }) })), _jsx(Box, __assign({ width: "400px", className: "title-16" }, { children: _jsx("p", { children: checklistTemplate.title }) })), _jsx(Box, __assign({ className: 'text-14' }, { children: _jsx("p", { children: getStatusText(checklistTemplate.status) }) }))] })), _jsxs(Box, __assign({ width: "276px", className: "right" }, { children: [checklistTemplate.status === 'PUBLISHED' && (_jsxs(Box, __assign({ className: "link", onClick: function () {
                                    createNewDraft(checklistTemplate);
                                } }, { children: [_jsx("img", { src: IconEdit }), _jsx("p", { children: formatMessage(globalMessages.edit) })] }))), checklistTemplate.status === 'DRAFT' && (_jsxs(Box, __assign({ className: "link", onClick: function () {
                                    setShowPublishChecklistModal(true);
                                } }, { children: [_jsx("img", { src: IconPublish }), _jsx("p", { children: formatMessage(messages.publishChecklist) })] }))), checklistTemplate.status === 'DRAFT' && (_jsxs(Box, __assign({ className: "link", onClick: function () { return editDraft(checklistTemplate); } }, { children: [_jsx("img", { src: IconEdit }), _jsx("p", { children: formatMessage(globalMessages.edit) })] }))), checklistTemplate.status === 'DRAFT' && (_jsxs(Box, __assign({ className: "link trash-box", onClick: function () {
                                    setShowConfirmationModal(true);
                                } }, { children: [_jsx("img", { src: IconTrash }), _jsx("p", { children: formatMessage(globalMessages.remove) })] })))] }))] })), showConfirmationModal && (_jsx(ConfirmationModal, { text: "".concat(formatMessage(messages.confirmationRemoveText), " ").concat(checklistTemplate.title, " (version ").concat(checklistTemplate.version, ")?"), dismiss: function () {
                    setShowConfirmationModal(false);
                }, onConfirm: function () {
                    if (checklistTemplate.id) {
                        onDelete(checklistTemplate.id);
                    }
                }, title: formatMessage(messages.confirmationRemoveHeader) })), showPublishChecklistModal && (_jsx(ConfirmationModal, { text: "".concat(formatMessage(messages.confirmationPublishChecklist), " ").concat(checklistTemplate.title, "?"), dismiss: function () { return setShowPublishChecklistModal(false); }, onConfirm: function () {
                    if (checklistTemplate.id) {
                        onPublishDraft(checklistTemplate.id);
                    }
                }, title: formatMessage(messages.confirmationPublishHeader) }))] }));
};
export default ChecklistListItem;

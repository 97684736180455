import { combineReducers } from 'redux';

import LanguageReducer from 'containers/LanguageProvider/reducer';
import NotificationsReducer from './Notifications';
import CalendarReducer from './Calendar';
import AdminReducer from './Admin';

const RootReducer = combineReducers({
  language: LanguageReducer,
  notifications: NotificationsReducer,
  calendar: CalendarReducer,
  admin: AdminReducer,
});

export default RootReducer;

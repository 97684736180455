var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import Cell from './Cell';
import IconTrendUp from '@resources/img/ikon-trendu.png';
import IconTrendNeutral from '@resources/img/ikon-trendn.png';
import IconTrendDown from '@resources/img/ikon-trendd.png';
import IconTrendNeutralBlue from '@resources/img/ikon-trendnb.png';
import moment from 'moment';
import { css } from '@emotion/react';
import { useIntl } from 'react-intl';
import messages from '../messages';
export var StyledTrendCell = styled(Cell)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  cursor: ", ";\n  color: ", ";\n\n  margin-left: 5px;\n  width: ", ";\n  box-shadow: 0 3px 5px 1px rgba(55, 74, 106, 0.05);\n  padding: 0 10px;\n  background: ", ";\n\n  font-weight: 600;\n  display: grid;\n  justify-items: center;\n  align-items: center;\n  grid-template-columns: ", ";\n  ", "\n"], ["\n  cursor: ", ";\n  color: ", ";\n\n  margin-left: 5px;\n  width: ", ";\n  box-shadow: 0 3px 5px 1px rgba(55, 74, 106, 0.05);\n  padding: 0 10px;\n  background: ", ";\n\n  font-weight: 600;\n  display: grid;\n  justify-items: center;\n  align-items: center;\n  grid-template-columns: ", ";\n  ", "\n"])), function (_a) {
    var onClick = _a.onClick;
    return (onClick ? 'pointer' : 'inherit');
}, function (_a) {
    var value = _a.value, theme = _a.theme;
    return (value !== undefined ? 'white' : theme.darkGrey);
}, function (_a) {
    var compact = _a.compact;
    return (compact ? '160px' : '104px');
}, function (_a) {
    var value = _a.value, theme = _a.theme;
    if (value === null || value === undefined) {
        return '#F6F8F9';
    }
    if (value < 4) {
        return "linear-gradient(to bottom, ".concat(theme.lightGreen, " 0%, ").concat(theme.darkGreen, " 100%)");
    }
    else if (value < 7) {
        return "linear-gradient(to bottom, ".concat(theme.lightOrange, " 0%, ").concat(theme.darkOrange, " 100%)");
    }
    return "linear-gradient(to bottom, ".concat(theme.lightRed, " 0%, ").concat(theme.darkRed, " 100%)");
}, function (_a) {
    var compact = _a.compact;
    return (compact ? '2fr 1fr' : '1fr 1fr');
}, function (_a) {
    var compact = _a.compact;
    if (compact) {
        return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        .info {\n          display: grid;\n          width: 100%;\n          grid-auto-flow: dense;\n          grid-template-columns: 1fr 1fr;\n          justify-items: center;\n          align-items: center;\n        }\n        .arrow {\n          width: 15px;\n          height: 15px;\n          grid-column: 2;\n          grid-row: 1;\n        }\n\n        .timestamp {\n          letter-spacing: -1px;\n          font-size: 20px;\n          grid-column: 1;\n          grid-row: 1;\n        }\n        .datestamp {\n          letter-spacing: 0.5px;\n          font-size: 14px;\n          opacity: 0.875;\n        }\n        .no-consent {\n          font-size: 42px;\n        }\n      "], ["\n        .info {\n          display: grid;\n          width: 100%;\n          grid-auto-flow: dense;\n          grid-template-columns: 1fr 1fr;\n          justify-items: center;\n          align-items: center;\n        }\n        .arrow {\n          width: 15px;\n          height: 15px;\n          grid-column: 2;\n          grid-row: 1;\n        }\n\n        .timestamp {\n          letter-spacing: -1px;\n          font-size: 20px;\n          grid-column: 1;\n          grid-row: 1;\n        }\n        .datestamp {\n          letter-spacing: 0.5px;\n          font-size: 14px;\n          opacity: 0.875;\n        }\n        .no-consent {\n          font-size: 42px;\n        }\n      "])));
    }
    else {
        return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        .info {\n          display: flex;\n          width: 100%;\n          flex-direction: column;\n          justify-content: space-evenly;\n          align-items: center;\n        }\n        .arrow {\n          width: 12px;\n          height: 12px;\n        }\n        .timestamp {\n          letter-spacing: -0.25px;\n          font-size: 14px;\n          font-weight: 600;\n        }\n        .datestamp {\n          margin-top: -2px;\n          letter-spacing: 0.5px;\n          font-size: 11px;\n          opacity: 0.875;\n        }\n      "], ["\n        .info {\n          display: flex;\n          width: 100%;\n          flex-direction: column;\n          justify-content: space-evenly;\n          align-items: center;\n        }\n        .arrow {\n          width: 12px;\n          height: 12px;\n        }\n        .timestamp {\n          letter-spacing: -0.25px;\n          font-size: 14px;\n          font-weight: 600;\n        }\n        .datestamp {\n          margin-top: -2px;\n          letter-spacing: 0.5px;\n          font-size: 11px;\n          opacity: 0.875;\n        }\n      "])));
    }
});
var TrendCell = function (_a) {
    var value = _a.value, timestamp = _a.timestamp, trend = _a.trend, consented = _a.consented, compact = _a.compact;
    var formatMessage = useIntl().formatMessage;
    if (value !== null && timestamp && consented) {
        var dateShown = moment.unix(timestamp).format('MM/DD');
        if (dateShown === moment().format('MM/DD')) {
            dateShown = formatMessage(messages.trendTextToday);
        }
        else if (dateShown === moment().subtract(1, 'day').format('MM/DD')) {
            dateShown = formatMessage(messages.trendTextYesterday);
        }
        return (_jsxs(StyledTrendCell, __assign({ value: value, timestamp: timestamp, trend: trend, consented: consented, compact: compact }, { children: [_jsxs("div", __assign({ className: "info" }, { children: [trend === 'UNCHANGED' ? (_jsx("img", { src: IconTrendNeutral, className: "arrow" })) : trend === 'INCREASED' ? (_jsx("img", { src: IconTrendUp, className: "arrow" })) : (_jsx("img", { src: IconTrendDown, className: "arrow" })), _jsx("div", __assign({ className: "timestamp" }, { children: moment.unix(timestamp).format('HH:mm') })), _jsx("div", __assign({ className: "datestamp" }, { children: dateShown }))] })), _jsx("div", __assign({ className: "value" }, { children: value }))] })));
    }
    else {
        return (_jsxs(StyledTrendCell, __assign({ consented: consented, compact: compact }, { children: [_jsxs("div", __assign({ className: "info" }, { children: [_jsx("img", { src: IconTrendNeutralBlue, className: "arrow" }), _jsx("div", __assign({ className: "timestamp no-consent" }, { children: "-" }))] })), _jsx("div", __assign({ className: "value no-consent" }, { children: "-" }))] })));
    }
};
export default TrendCell;
var templateObject_1, templateObject_2, templateObject_3;

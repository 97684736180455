/*
 * Patient Overview List Messages
 *
 * This contains the message translations that are used in the patient overview, both for home and hospital
 *
 */
import { defineMessages } from 'react-intl';
var scope = 'app.pages.PatientList';
export default defineMessages({
    addPatient: {
        id: "".concat(scope, ".addPatient"),
        defaultMessage: 'Lägg till patient',
    },
    admittedPatients: {
        id: "".concat(scope, ".admittedPatients"),
        defaultMessage: 'Inskrivna',
    },
    name: {
        id: "".concat(scope, ".name"),
        defaultMessage: 'NAMN',
    },
    patient: {
        id: "".concat(scope, ".patient"),
        defaultMessage: 'PATIENT',
    },
    checklist: {
        id: "".concat(scope, ".checklist"),
        defaultMessage: 'CHECKLISTA',
    },
    created: {
        id: "".concat(scope, ".created"),
        defaultMessage: 'INSKR. DATUM',
    },
    diagnosis: {
        id: "".concat(scope, ".diagnosis"),
        defaultMessage: 'DIAGNOSKOD',
    },
    nrs: {
        id: "".concat(scope, ".nrs"),
        defaultMessage: 'NRS',
    },
    nausea: {
        id: "".concat(scope, ".nausea"),
        defaultMessage: 'ILLAMÅENDE',
    },
    pain: {
        id: "".concat(scope, ".pain"),
        defaultMessage: 'SMÄRTA',
    },
    home: {
        id: "".concat(scope, ".home"),
        defaultMessage: 'Hemma',
    },
    overviewHeader: {
        id: "".concat(scope, ".overviewHeader"),
        defaultMessage: 'Patientöversikt',
    },
    overviewHome: {
        id: "".concat(scope, ".overviewHome"),
        defaultMessage: 'Patientöversikt för hemmavarande patienter.',
    },
    overviewHospital: {
        id: "".concat(scope, ".overviewHospital"),
        defaultMessage: 'Patientöversikt för inskrivna patienter.',
    },
    room: {
        id: "".concat(scope, ".room"),
        defaultMessage: 'RUM:SÄNG',
    },
});

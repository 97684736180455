var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { updatePatientModule } from '@api/patient/patient';
import { setPatientAction } from '@hooks/usePatientDetail/actions';
import Modal from 'components/Modal/Modal';
import MedicineScheduleTable from './MedicineScheduleTable';
import Button from 'components/Button';
import ModalFooter from 'components/Modal/ModalFooterButtons';
import MedicineScheduleSelect from './MedicineScheduleSelect';
import usePatientDetailAction from '@hooks/usePatientDetail/usePatientDetailAction';
import PatientBox from '@components/PatientBox/PatientBox';
import messages from '@src/pages/PatientListPage/PatientList/PatientFormModal/messages';
import globalMessages from '@src/globalMessages';
var MedicineScheduleModal = function (_a) {
    var _b, _c;
    var patient = _a.patient, module = _a.module, moduleTemplate = _a.moduleTemplate, dismiss = _a.dismiss;
    var dispatch = usePatientDetailAction();
    var formatMessage = useIntl().formatMessage;
    var _d = useState((_b = moduleTemplate.templates.find(function (o) { var _a, _b; return ((_a = o.scheduleTemplate) === null || _a === void 0 ? void 0 : _a.id) === ((_b = module === null || module === void 0 ? void 0 : module.schedule) === null || _b === void 0 ? void 0 : _b.id); })) === null || _b === void 0 ? void 0 : _b.id), selectedTemplateId = _d[0], setSelectedTemplateId = _d[1];
    var formattedModalHeadline = formatMessage(messages.medicationSchema).charAt(0).toLocaleUpperCase() + formatMessage(messages.medicationSchema).slice(1).toLowerCase();
    var onSave = function () {
        var _a, _b;
        var scheduleId = (_b = (_a = moduleTemplate.templates.find(function (m) { return m.id === selectedTemplateId; })) === null || _a === void 0 ? void 0 : _a.scheduleTemplate) === null || _b === void 0 ? void 0 : _b.id;
        if (scheduleId) {
            updatePatientModule(patient.id, module.id, {
                id: module.id,
                templateId: scheduleId,
            }).then(function (value) {
                dispatch(setPatientAction(value));
                dismiss();
            });
        }
    };
    var selectedTemplate = (_c = moduleTemplate.templates.find(function (s) { return (s === null || s === void 0 ? void 0 : s.id) === selectedTemplateId; })) === null || _c === void 0 ? void 0 : _c.scheduleTemplate;
    return (_jsxs(Modal, __assign({ title: formattedModalHeadline, dismiss: dismiss, maxWidth: "845px", tainted: selectedTemplateId === module.schedule.id }, { children: [_jsx(StyledContent, { children: _jsxs("div", __assign({ className: "content" }, { children: [_jsx(PatientBox, { patient: patient, className: "patientBox" }), _jsx("p", __assign({ id: "scheduleTitle", className: "headline" }, { children: formatMessage(messages.medicationSchema) })), _jsx(MedicineScheduleSelect, { module: module, moduleTemplate: moduleTemplate, onChange: function (value, valid) {
                                setSelectedTemplateId(value);
                            } }), selectedTemplate && _jsx(MedicineScheduleTable, { medicineSchedule: selectedTemplate })] })) }), _jsxs(ModalFooter, { children: [_jsx(Button, __assign({ medium: true, hover: true, onClick: dismiss }, { children: _jsx("span", { children: formatMessage(globalMessages.cancel) }) })), _jsx(Button, __assign({ primary: true, medium: true, hover: true, disabled: !selectedTemplate || (selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.id) === module.schedule.id, onClick: onSave }, { children: _jsx("span", { children: formatMessage(globalMessages.save) }) }))] })] })));
};
export default MedicineScheduleModal;
var StyledContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  .content {\n    width: 485px;\n\n    .patientBox {\n      margin: 0 0 30px 0;\n    }\n\n    #scheduleTitle {\n      margin: 0 0 16px 0;\n      padding: 0 0 12px 0;\n      border: 0 solid;\n      border-color: ", ";\n      border-bottom-width: 1px;\n    }\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  .content {\n    width: 485px;\n\n    .patientBox {\n      margin: 0 0 30px 0;\n    }\n\n    #scheduleTitle {\n      margin: 0 0 16px 0;\n      padding: 0 0 12px 0;\n      border: 0 solid;\n      border-color: ", ";\n      border-bottom-width: 1px;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.athensGray;
});
var templateObject_1;

import React from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import moment from 'moment';
import styled from '@emotion/styled';
import am4lang_en_US from '@amcharts/amcharts4/lang/en_US';
import am4lang_sv_SE from '@amcharts/amcharts4/lang/sv_SE';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { FormattedMessage, injectIntl } from 'react-intl';

import { theme } from '../../../constants/styling';
import am4themes_zengio from './theme';
import Button, { StyledButton } from 'components/Button';
import IntervalPicker from 'components/Date/IntervalPicker/IntervalPicker';
import MinusIcon from '../../../resources/img/ikon-minus.png';
import PlusIcon from '../../../resources/img/ikon-plus.png';
import HomeIcon from '../../../resources/img/ikon-home.png';
import messages from '../messages';

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_zengio);

class VasGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateAxis: undefined,
      firstData: undefined,
      lastData: undefined,
      startDate: undefined,
      endDate: undefined,
      clearedDates: false,
      canZoomIn: true,
      canZoomOut: true,
      newData: false,
      placeholder: false,
    };

    //interval with novalue data, needed to provide a proper date range between creation and current date.
    this.noValBaseDataInterval = [
      {
        timestamp: moment(this.props.createdAt * 1000)
          .subtract(1, 'd')
          .toDate(),
      },
      {
        timestamp: moment().toDate(),
      },
    ];
  }

  getTitle = (type) => {
    const { formatMessage } = this.props.intl;
    switch (type) {
      case 'NRS_PAIN':
        return formatMessage(messages.pain);
      case 'NRS_NAUSEA':
        return formatMessage(messages.nausea);
      default:
        return formatMessage(messages.unknown);
    }
  };

  getColor = (type) => {
    switch (type) {
      case 'NRS_PAIN':
        return theme.darkGrey;
      case 'NRS_NAUSEA':
        return theme.lightBlue;
      default:
        return theme.loblolly;
    }
  };

  getFillColor = (value) => {
    const green = '#6cc173';
    const yellow = '#e4ae42';
    const red = '#e45050';

    if (value < 4) {
      return green;
    } else if (value < 7) {
      return yellow;
    } else {
      return red;
    }
  };
  getAtHomeFillColor = (value) => {
    const green = '#6cc17377';
    const yellow = '#e4ae4277';
    const red = '#e4505077';

    if (value < 4) {
      return green;
    } else if (value < 7) {
      return yellow;
    } else {
      return red;
    }
  };

  mapVasPoints = () => {
    const entries = this.props.vasValues
      .slice()
      .sort((a, b) => a.timestamp - b.timestamp)
      .map(this.entryMap);
    return entries;
  };

  entryMap = (entry) => {
    let lineColor;
    if (!this.props.sentHomeAt || entry.timestamp < this.props.sentHomeAt) {
      lineColor = this.getFillColor(entry.value);
    } else {
      lineColor = this.getAtHomeFillColor(entry.value);
    }
    return {
      [entry.scaleType]: entry.value,
      timestamp: new Date(entry.timestamp * 1000),
      lineColor: lineColor,
    };
  };

  dateChangeHandler = (e) => {
    this.setState({ clearedDates: false });
    const { startDate, endDate } = e;
    const { dateAxis } = this.state;
    if (startDate && endDate && startDate.isSameOrBefore(endDate)) {
      dateAxis.zoomToDates(startDate.toDate(), moment(endDate).endOf('d').toDate());
    }
  };

  componentDidMount() {
    // am4core.useTheme(am4themes_animated);
    let chart = am4core.create('chartdiv', am4charts.XYChart);

    am4lang_sv_SE.Jan = 'jan';
    am4lang_sv_SE.Feb = 'feb';
    am4lang_sv_SE.Mar = 'mar';
    am4lang_sv_SE.Apr = 'apr';
    am4lang_sv_SE['May(short)'] = 'maj';
    am4lang_sv_SE.Jun = 'jun';
    am4lang_sv_SE.Jul = 'jul';
    am4lang_sv_SE.Aug = 'aug';
    am4lang_sv_SE.Sep = 'sep';
    am4lang_sv_SE.Oct = 'okt';
    am4lang_sv_SE.Nov = 'nov';
    am4lang_sv_SE.Dec = 'dec';

    chart.language.locale = navigator.language && navigator.language.split('-')[0] === 'sv' ? am4lang_sv_SE : am4lang_en_US;

    let data = this.mapVasPoints();
    const lastData = [...data].pop();
    const firstData = [...data].shift();
    //If no points add a placeholder datapoint, dateAxis wont render without it.
    if (data.length === 0) {
      data = this.props.scaleTypes.map((type) => ({
        [type]: -1,
        timestamp: new Date(),
      }));
      this.setState({ placeholder: true });
    }

    //novalue datapoint interval, ensure dateaxis exists for creation and current date.
    data = data.concat([...this.noValBaseDataInterval]);

    const sortedData = data.sort((a, b) => a.timestamp - b.timestamp);
    //save first and last datapoints to know when whart is over for navigation with buttons
    if (lastData && firstData) {
      this.setState({ firstData: firstData.timestamp, lastData: lastData.timestamp });
    }
    chart.data = sortedData;

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    if (firstData && lastData) {
      dateAxis.extraMax = 0.1;
      dateAxis.extraMin = 1;
    }

    dateAxis.renderer.minGridDistance = 50;
    dateAxis.stroke = theme.slateGray;
    dateAxis.periodChangeDateFormats.setKey('hour', '[bold]d MMM[/]');
    dateAxis.periodChangeDateFormats.setKey('day', 'd MMM');
    dateAxis.periodChangeDateFormats.setKey('week', 'd MMM');
    dateAxis.periodChangeDateFormats.setKey('year', '[bold]yyyy[/]');
    dateAxis.dateFormatter.dateFormat = 'HH:mm';
    dateAxis.dateFormats.setKey('day', 'd MMM');
    dateAxis.dateFormats.setKey('week', 'd MMM');
    dateAxis.dateFormats.setKey('month', 'd MMM');

    dateAxis.dateFormats.setKey('year', 'yyyy');
    dateAxis.cursorTooltipEnabled = false;
    const label = dateAxis.renderer.labels.template;
    label.maxWidth = 400;

    dateAxis.events.on('selectionextremeschanged', (ev) => {
      var axis = ev.target;
      this.setState({ dateAxis: axis });
    });

    dateAxis.events.on('rangechangeended', (ev) => {
      var axis = ev.target;
      if (axis.gridInterval.timeUnit === 'day' || axis.gridInterval.timeUnit === 'week' || axis.gridInterval.timeUnit === 'month') {
        axis.renderer.labels.template.rotation = -45;
        axis.renderer.labels.template.horizontalCenter = 'middle';
        axis.renderer.labels.template.verticalCenter = 'middle';
      } else {
        axis.renderer.labels.template.rotation = 0;
        axis.renderer.labels.template.horizontalCenter = 'middle';
        axis.renderer.labels.template.verticalCenter = 'top';
      }
      this.setState({ dateAxis: axis });
    });

    dateAxis.baseInterval = {
      timeUnit: 'second',
      count: 1,
    };

    dateAxis.gridIntervals = new am4core.List([
      { timeUnit: 'millisecond', count: 1 },
      { timeUnit: 'millisecond', count: 5 },
      { timeUnit: 'millisecond', count: 10 },
      { timeUnit: 'millisecond', count: 50 },
      { timeUnit: 'millisecond', count: 100 },
      { timeUnit: 'millisecond', count: 500 },
      { timeUnit: 'second', count: 1 },
      { timeUnit: 'second', count: 5 },
      { timeUnit: 'second', count: 10 },
      { timeUnit: 'second', count: 30 },
      { timeUnit: 'minute', count: 1 },
      { timeUnit: 'minute', count: 2 },
      { timeUnit: 'minute', count: 3 },
      { timeUnit: 'minute', count: 4 },
      { timeUnit: 'minute', count: 5 },
      { timeUnit: 'minute', count: 10 },
      { timeUnit: 'minute', count: 15 },
      { timeUnit: 'minute', count: 20 },
      { timeUnit: 'minute', count: 25 },
      { timeUnit: 'minute', count: 30 },
      { timeUnit: 'minute', count: 35 },
      { timeUnit: 'minute', count: 40 },
      { timeUnit: 'minute', count: 45 },
      { timeUnit: 'hour', count: 1 },
      { timeUnit: 'hour', count: 2 },
      { timeUnit: 'hour', count: 3 },
      { timeUnit: 'hour', count: 4 },
      { timeUnit: 'hour', count: 6 },
      { timeUnit: 'hour', count: 7 },
      { timeUnit: 'hour', count: 8 },
      { timeUnit: 'hour', count: 9 },
      { timeUnit: 'hour', count: 10 },
      { timeUnit: 'hour', count: 11 },
      { timeUnit: 'hour', count: 12 },
      { timeUnit: 'day', count: 1 },
      { timeUnit: 'day', count: 2 },
      { timeUnit: 'day', count: 3 },
      { timeUnit: 'day', count: 4 },
      { timeUnit: 'day', count: 5 },
      { timeUnit: 'week', count: 1 },
      { timeUnit: 'week', count: 2 },
      { timeUnit: 'week', count: 3 },
      { timeUnit: 'month', count: 1 },
      { timeUnit: 'month', count: 2 },
      { timeUnit: 'month', count: 3 },
      { timeUnit: 'month', count: 6 },
      { timeUnit: 'year', count: 1 },
      { timeUnit: 'year', count: 2 },
      { timeUnit: 'year', count: 5 },
      { timeUnit: 'year', count: 10 },
      { timeUnit: 'year', count: 50 },
      { timeUnit: 'year', count: 100 },
    ]);

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.minGridDistance = 75;
    valueAxis.max = 10.9;
    valueAxis.min = -0.1;
    valueAxis.strictMinMax = true;
    valueAxis.cursorTooltipEnabled = false;
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.stroke = theme.slateGray;

    function createSeries(field, name, color) {
      let lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.name = name;
      lineSeries.dataFields.dateX = 'timestamp';
      lineSeries.dataFields.valueY = field;
      lineSeries.strokeWidth = 3;
      //lineSeries.stroke = theme.slateGray;
      lineSeries.stroke = am4core.color(color);
      lineSeries.fill = am4core.color(color);
      lineSeries.cursorTooltipEnabled = false;
      lineSeries.defaultState.transitionDuration = 0;
      // Tooltip
      lineSeries.tooltip.label.textAlign = 'down';
      lineSeries.tooltip.pointerOrientation = 'down';
      lineSeries.tooltip.getFillFromObject = false;
      lineSeries.tooltip.dy = -45;
      lineSeries.tooltip.background.fill = 'white';
      lineSeries.tooltip.stroke = '#000';
      lineSeries.tooltip.fontWeight = '100';
      lineSeries.dummyData = {
        path: 'M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z',
      };

      let circleBullet = lineSeries.bullets.push(new am4charts.CircleBullet());
      circleBullet.circle.stroke = am4core.color('#fff');
      let circleHoverState = circleBullet.states.create('hover');
      circleHoverState.properties.scale = 1.2;
      circleBullet.circle.strokeWidth = 2;
      circleBullet.circle.propertyFields.fill = color;
      circleBullet.tooltipText = '{timestamp}';

      let squareBullet = circleBullet.createChild(am4charts.Bullet);
      let square = squareBullet.createChild(am4core.Rectangle);
      square.width = 15;
      square.height = 12;
      square.propertyFields.fill = 'lineColor';
      square.propertyFields.stroke = 'lineColor';
      square.horizontalCenter = 'middle';
      square.verticalCenter = 'middle';
      square.strokeLinejoin = 'round';
      square.strokeWidth = 12;
      square.dy = -20;

      var labelBullet = circleBullet.createChild(am4charts.LabelBullet);
      labelBullet.label.text = '{valueY}';
      labelBullet.label.fill = 'white';
      labelBullet.label.dy = -20;
      labelBullet.label.fontSize = 14;
      labelBullet.label.fontWeight = 'bold';

      return lineSeries;
    }

    this.props.scaleTypes.forEach((scaleType) => createSeries(scaleType, this.getTitle(scaleType), this.getColor(scaleType)));

    /* Create legend and enable default markers */
    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;

    /* Remove square from marker template */
    var marker = chart.legend.markers.template;
    marker.disposeChildren();
    marker.width = 24;
    marker.height = 24;

    /* Add custom image instead */
    var eyeIcon = marker.createChild(am4core.Image);
    eyeIcon.width = 20;
    eyeIcon.height = 20;
    eyeIcon.scale = 1;
    eyeIcon.stroke = am4core.color('#f6f8f9');
    eyeIcon.verticalCenter = 'top';
    eyeIcon.horizontalCenter = 'left';
    eyeIcon.propertyFields.fill = 'fill';
    eyeIcon.adapter.add('dx', function (dx, target) {
      target.path = target.dataItem.dataContext.dummyData.path;
      return dx;
    });

    let cursor = new am4charts.XYCursor();
    cursor.lineX.disabled = true;
    cursor.lineY.disabled = true;
    cursor.behavior = 'panX';
    chart.cursor = cursor;
    chart.plotContainer.paddingTop = 10;

    valueAxis.paddingTop = 10;

    dateAxis.events.on('validated', () => {
      if (this.state.firstData && this.state.newData) {
        this.showAlldata();
        this.setState({ newData: false });
      }
    });

    //ensure dateAxis is available in state when chart ready event is handled.
    this.setState(
      {
        dateAxis: dateAxis,
      },
      () => {
        chart.events.on('ready', () => {
          if (this.state.firstData) {
            this.showAlldata(true);
          } else {
            this.zoomToToday();
          }
        });
      },
    );

    chart.plotContainer.events.on('doublehit', (e) => {
      const spritePoint = this.state.dateAxis.pointToPosition(e.spritePoint);
      this.zoomIn(this.state.dateAxis.positionToDate(spritePoint));
    });

    chart.zoomOutButton.disabled = true;
    this.chart = chart;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.vasValues.length < this.props.vasValues.length && this.state.dateAxis) {
      if (this.state.placeholder) {
        //reset chart, insert the no value base datapoint interval to ensure dateAxis renders from creation to cur date.
        this.chart.data = [...this.noValBaseDataInterval];
        this.setState({ placeholder: false });
      }
      const allDataPoints = this.props.vasValues.slice().sort((a, b) => a.timestamp - b.timestamp);
      const newValues = allDataPoints.slice(prevProps.vasValues.length - this.props.vasValues.length).map(this.entryMap);
      const endValue = [...newValues].pop();
      const lastData = moment(endValue.timestamp).toDate();
      const startValue = [...newValues].shift();
      const firstData = moment(startValue.timestamp).toDate();
      this.setState({ firstData, lastData, newData: true });

      this.chart.addData(newValues);
    }
  }

  zoomInOnCenter = () => {
    const { dateAxis } = this.state;
    const end = dateAxis.positionToDate(dateAxis.end);
    const start = dateAxis.positionToDate(dateAxis.start);
    const diff = moment.duration(moment(end).diff(moment(start)));
    const axisStart = moment(start).add(diff.asMinutes() / 4, 'm');
    const axisEnd = moment(end).subtract(diff.asMinutes() / 4, 'm');

    this.setState({ canZoomOut: true });
    this.state.dateAxis.zoomToDates(axisStart.toDate(), axisEnd.toDate(), true);
  };
  zoomIn = (center) => {
    const { dateAxis } = this.state;
    const end = dateAxis.positionToDate(dateAxis.end);
    const start = dateAxis.positionToDate(dateAxis.start);
    const diff = moment.duration(moment(end).diff(moment(start)));
    const axisStart = moment(center).subtract(diff.asMinutes() / 4, 'm');
    const axisEnd = moment(center).add(diff.asMinutes() / 4, 'm');

    this.setState({ canZoomOut: true });
    this.state.dateAxis.zoomToDates(axisStart.toDate(), axisEnd.toDate(), true);
  };

  zoomOutOnCenter = () => {
    const { dateAxis } = this.state;
    const end = dateAxis.positionToDate(dateAxis.end);
    const start = dateAxis.positionToDate(dateAxis.start);
    const diff = moment.duration(moment(end).diff(moment(start)));
    const axisStart = moment(start).subtract(diff.asMinutes() / 4, 'm');
    const axisEnd = moment(end).add(diff.asMinutes() / 4, 'm');

    this.setState({ canZoomIn: true });
    this.state.dateAxis.zoomToDates(axisStart.toDate(), axisEnd.toDate());
  };

  render() {
    const { dateAxis } = this.state;
    let end, start;
    if (dateAxis) {
      end = dateAxis.positionToDate(dateAxis.end);
      start = dateAxis.positionToDate(dateAxis.start);
    }
    return (
      <GraphContainer>
        <ChartContainer>
          <ChartWrapper>
            <Chart id={'chartdiv'} />
            <DateContainer>
              <DateValue>
                {moment(start)
                  .locale(navigator.language ? navigator.language : 'sv')
                  .format('ddd DD MMM')}
              </DateValue>
              {!moment(start).isSame(moment(end).subtract(1, 'h'), 'd') && <DateValue>{moment(end).format('ddd DD MMM')}</DateValue>}
            </DateContainer>
          </ChartWrapper>
          <ZoomButtonGroup>
            <Button
              square
              width={37}
              height={37}
              onClick={() => {
                this.showAlldata();
              }}
            >
              <img src={HomeIcon} />
            </Button>
            <ZoomButtons>
              <Button tooltip={<FormattedMessage {...messages.tooltipZoomIn} />} square width={37} height={37} onClick={this.zoomInOnCenter}>
                <img src={PlusIcon} />
              </Button>
              <Button
                tooltip={<FormattedMessage {...messages.tooltipZoomOut} />}
                square
                width={37}
                height={37}
                onClick={this.zoomOutOnCenter}
                disabled={!this.state.canZoomOut}
              >
                <img src={MinusIcon} />
              </Button>
            </ZoomButtons>
          </ZoomButtonGroup>
        </ChartContainer>

        <Footer>
          <div>
            <IntervalPicker
              minDate={moment(this.state.firstData).toDate()}
              maxDate={new Date()}
              colorful
              disableHeadline
              onChange={this.dateChangeHandler}
              startTitle={this.props.intl.formatMessage(messages.fromDate)}
              endTitle={this.props.intl.formatMessage(messages.toDate)}
              clearedDates={this.state.clearedDates}
            />
          </div>

          <Button
            onClick={() => {
              this.zoomToToday();
            }}
            medium
          >
            <FormattedMessage {...messages.todaysDate} />
          </Button>
        </Footer>
        <IntervalContainer />
      </GraphContainer>
    );
  }

  showAlldata(instant) {
    const { dateAxis } = this.state;
    const { createdAt } = this.props;
    if (createdAt) {
      //max zoom is 1h

      const diff = moment.duration(moment().diff(moment(createdAt * 1000)));
      const end = moment()
        .add(diff / 10)
        .add(1, 'm')
        .toDate();
      const start = moment(createdAt * 1000)
        .subtract(diff / 10)
        .subtract(10, 'm')
        .toDate();
      dateAxis.zoomToDates(start, end, true, instant || false);
    }
  }

  zoomToToday() {
    const daysToShow = 1;
    const diff = moment.duration(moment().diff(moment().subtract(daysToShow, 'd')));
    const axisEnd = moment()
      .add(diff * 0.05)
      .toDate();
    const axisStart = moment()
      .subtract(daysToShow, 'd')
      .subtract(diff * 0.05)
      .toDate();
    this.setState({ clearedDates: true });
    this.state.dateAxis.zoomToDates(axisStart, axisEnd, true);
  }
}

export default injectIntl(VasGraph);

const DateContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  padding-left: 35px;
  padding-right: 10px;
`;
const Chart = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  flex-grow: 1;
  color: ${({ theme }) => theme.darkGrey};
`;
const ChartWrapper = styled.div`
  font-size: 1.2rem;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
`;
const DateValue = styled.div`
  font-weight: bold;
  :first-letter {
    text-transform: uppercase;
  }
`;

const ZoomButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  margin-bottom: 145px;
  width: min-content;
  height: 100%;
`;
const ZoomButtons = styled.div`
  margin-top: 13px;
  width: 100%;
  ${StyledButton} {
    margin-bottom: 3px;
  }
`;
const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 50px;
  font-size: 1.4rem;
  margin-top: 25px;
  border: 1px solid ${({ theme }) => theme.athensGray};
  border-radius: 1px;
  background-color: ${({ theme }) => theme.blueGrey};
  button {
    margin-left: 10px;
  }
  button:last-of-type {
    margin-left: 20px;
  }
`;

const IntervalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px;
  ${StyledButton} {
    margin-left: 10px;
  }
`;

const GraphContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  #chartdiv {
    cursor: grab;
    :active {
      cursor: grabbing;
    }
  }
`;

const ChartContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  flex-direction: row;
`;

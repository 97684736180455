var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import ConfirmationModal from 'components/Modal/ConfirmationModal';
import messages from './messages';
import { useIntl } from 'react-intl';
var PersonnelConflictModal = function (_a) {
    var personnel = _a.personnel, onConfirm = _a.onConfirm, onCancel = _a.onCancel, dismiss = _a.dismiss;
    var formatMessage = useIntl().formatMessage;
    return (_jsx(ConfirmationModal, __assign({ title: formatMessage(messages.conflictValidationError), onConfirm: onConfirm, onCancel: onCancel, dismiss: dismiss }, { children: _jsxs(StyledContent, { children: [_jsx("div", __assign({ className: "headline-14" }, { children: formatMessage(messages.conflictPersonnelValidationErrorMessage) })), _jsxs("p", { children: [personnel.firstName, " ", personnel.surname] }), _jsx("p", { children: personnel.hsaId })] }) })));
};
export default PersonnelConflictModal;
var StyledContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: block;\n  text-align: center;\n  margin: 5px 0;\n"], ["\n  display: block;\n  text-align: center;\n  margin: 5px 0;\n"])));
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';
import PatientBox from '@components/PatientBox/PatientBox';
import Button from 'components/Button';
import Modal from '../Modal/Modal';
import IconCheck from '../../resources/img/ikon-check.png';
import ModalFooter from 'components/Modal/ModalFooterButtons';
import globalMessages from '@src/globalMessages';
var ChecklistDetailModal = function (_a) {
    var dismiss = _a.dismiss, checklist = _a.checklist, patient = _a.patient;
    var mapChecklistItems = function (checklistItems) {
        // eslint-disable-next-line arrow-body-style
        var elements = checklistItems.map(function (item) {
            return (_jsxs("div", __assign({ className: "item" }, { children: [item.completed ? _jsx("img", { src: IconCheck, className: "checklist-icon" }) : _jsx("div", { className: "checklist-icon incomplete-circle" }), _jsx("span", __assign({ className: "text" }, { children: item.text }))] }), item.id));
        });
        return elements;
    };
    return (_jsxs(Modal, __assign({ maxWidth: "845px", title: checklist.title, subtitle: "Version ".concat(checklist.version), dismiss: dismiss }, { children: [_jsx(StyledContent, { children: _jsxs("div", __assign({ className: "items" }, { children: [_jsx(PatientBox, { className: "patient-box", patient: patient }), mapChecklistItems(checklist.items)] })) }), _jsx(ModalFooter, { children: _jsx(Button, __assign({ primary: true, medium: true, hover: true, onClick: function () { return dismiss(); } }, { children: _jsx("span", { children: _jsx(FormattedMessage, __assign({}, globalMessages.ok)) }) })) })] })));
};
export default ChecklistDetailModal;
var StyledContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-align: left;\n  margin-bottom: 3rem;\n  font-size: 16px;\n\n  .patient-box {\n    margin: 0 0 20px 25px;\n  }\n\n  .items {\n    display: table;\n    margin: auto;\n    .item {\n      display: flex;\n      align-items: center;\n      padding: 5px;\n    }\n    .text {\n      color: ", ";\n      font-size: 14px;\n      font-weight: 600;\n    }\n    .checklist-icon {\n      margin-right: 30px;\n      width: 37px;\n      height: 37px;\n    }\n    .incomplete-circle {\n      border-radius: 50%;\n      background-color: ", ";\n    }\n  }\n"], ["\n  text-align: left;\n  margin-bottom: 3rem;\n  font-size: 16px;\n\n  .patient-box {\n    margin: 0 0 20px 25px;\n  }\n\n  .items {\n    display: table;\n    margin: auto;\n    .item {\n      display: flex;\n      align-items: center;\n      padding: 5px;\n    }\n    .text {\n      color: ", ";\n      font-size: 14px;\n      font-weight: 600;\n    }\n    .checklist-icon {\n      margin-right: 30px;\n      width: 37px;\n      height: 37px;\n    }\n    .incomplete-circle {\n      border-radius: 50%;\n      background-color: ", ";\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.cello;
}, function (_a) {
    var theme = _a.theme;
    return theme.alto;
});
var templateObject_1;

import { jsx as _jsx } from "react/jsx-runtime";
import { BrowserRouter as Router } from 'react-router-dom';
import AuthProvider from '@hooks/useAuth/AuthProvider';
import ErrorProvider from '@hooks/useError/ErrorProvider';
import AppContent from '@app/AppContent';
import AdminProvider from '@hooks/useAdmin/AdminProvider';
import PatientListProvider from '@hooks/usePatientList/PatientListProvider';
import PatientDetailProvider from '@hooks/usePatientDetail/PatientDetailProvider';
import OrganizationProvider from '@hooks/useOrganization/OrganizationProvider';
var App = function () { return (_jsx(ErrorProvider, { children: _jsx(AuthProvider, { children: _jsx(OrganizationProvider, { children: _jsx(AdminProvider, { children: _jsx(PatientListProvider, { children: _jsx(PatientDetailProvider, { children: _jsx(Router, { children: _jsx(AppContent, {}) }) }) }) }) }) }) })); };
export default App;

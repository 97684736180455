import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { CirclePicker } from 'react-color';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import styled from '@emotion/styled';

import * as actions from 'constants/actions';
import { theme } from 'constants/styling';
import { addUser, updateUser } from 'actions/calendar';
import Button, { StyledButton } from 'components/Button';
import Modal from 'components/Modal/Modal';
import ModalFooter from 'components/Modal/ModalFooterButtons';
import messages from './messages';
import globalMessages from '@src/globalMessages';

class CalendarUserDetails extends PureComponent {
  constructor(props) {
    super(props);
    const user = this.props.calendar.selectedUser;

    let extra = {
      typeDisabled: !!user.id,
      nameDisabled: user.type === 'PATIENT',
      missingType: false,
      invalidName: false,
      fieldValidationFailed: false,
      tainted: false,
    };
    if (!user.color) {
      extra.color = '#2196f3';
    }
    this.props.dispatch({
      type: actions.EDIT_USER,
      data: { ...user, ...extra },
    });
    this.typeInputRef = React.createRef();
  }

  onNameValueChange = (value) => {
    let extra = {
      displayName: value.trimStart(),
      tainted: true,
    };
    const user = this.props.calendar.selectedUser;
    if (user.fieldValidationFailed) {
      let invalid = false;
      if (extra.displayName.length === 0) {
        invalid = true;
      }
      extra = {
        ...extra,
        invalidTitle: invalid,
      };
    }
    this.props.dispatch({
      type: actions.EDIT_USER,
      data: { ...user, ...extra },
    });
  };

  onEmailValueChange = (value) => {
    let extra = {
      email: value.trimStart(),
      tainted: true,
    };
    this.props.dispatch({
      type: actions.EDIT_USER,
      data: { ...this.props.calendar.selectedUser, ...extra },
    });
  };

  handleColorChangeComplete = (color) => {
    let extra = {
      color: color.hex,
      tainted: true,
    };
    this.props.dispatch({
      type: actions.EDIT_USER,
      data: { ...this.props.calendar.selectedUser, ...extra },
    });
  };

  onUserTypeChanged = (value) => {
    let extra = {
      type: value,
      tainted: true,
      missingType: false,
    };
    this.props.dispatch({
      type: actions.EDIT_USER,
      data: { ...this.props.calendar.selectedUser, ...extra },
    });
  };

  checkValidForm = () => {
    const user = this.props.calendar.selectedUser;
    let validation = {},
      invalid = false;

    if (!user.type || user.type === '') {
      invalid = true;
      validation.missingType = true;
    }
    if (!user.displayName || user.displayName === '') {
      invalid = true;
      validation.invalidName = true;
    }
    if (!invalid) {
      this.saveUser(user);
    } else {
      validation.fieldValidationFailed = true;
      this.props.dispatch({
        type: actions.EDIT_USER,
        data: { ...user, ...validation },
      });
    }
  };

  saveUser = (user) => {
    let payload = {
      type: user.type,
      displayName: user.displayName,
      email: user.email,
      color: user.color,
    };
    if (user.id) {
      this.props.updateUser({
        data: payload,
        userId: user.id,
        success: () => {
          this.props.dispatch({
            type: actions.CANCEL_EDIT_USER,
          });
        },
      });
    } else {
      this.props.addUser({
        data: payload,
        success: () => {
          this.props.dispatch({
            type: actions.CANCEL_EDIT_USER,
          });
        },
      });
    }
  };

  render() {
    const user = this.props.calendar.selectedUser;
    const types = [
      { label: <FormattedMessage {...messages.typeLabelResource} />, value: 'PERSONNEL' },
      { label: <FormattedMessage {...messages.typeLabelRoom} />, value: 'LOCATION' },
      { label: <FormattedMessage {...messages.typeLabelActivity} />, value: 'CATEGORY' },
    ];
    const selectedType = types.filter((type) => user.type === type.value);
    return (
      <Modal
        ref={this.modalRef}
        title={user.id ? <FormattedMessage {...globalMessages.edit} /> : <FormattedMessage {...globalMessages.add} />}
        dismiss={() => {
          this.props.dispatch({
            type: actions.CANCEL_EDIT_USER,
          });
        }}
        tainted={user.tainted}
      >
        <CreateUserContent>
          <div className="content">
            {!user.typeDisabled && (
              <div>
                <label htmlFor="type">
                  <FormattedMessage {...messages.labelType} />
                </label>
                <div className="select">
                  <Select
                    ref={this.typeInputRef}
                    id="type"
                    classNamePrefix="dropdown"
                    theme={(selectTheme) => ({
                      ...selectTheme,
                      borderRadius: 5,
                      colors: {
                        ...selectTheme.colors,
                        danger: theme.darkRed,
                        dangerLight: theme.lightRed,
                        primary25: theme.lightBlue,
                        primary50: theme.dodgerBlue,
                        primary75: theme.dodgerBlue,
                        primary: theme.lightBlue,
                      },
                    })}
                    isDisabled={user.typeDisabled}
                    value={selectedType}
                    onChange={(option) => this.onUserTypeChanged(option.value)}
                    options={types}
                    placeholder={<FormattedMessage {...globalMessages.selectPlaceholder} />}
                  />
                  {user.missingType && (
                    <span className="error">
                      <FormattedMessage {...messages.typeValidationError} />
                    </span>
                  )}
                </div>
              </div>
            )}

            <label htmlFor="name">
              <span>
                <FormattedMessage {...messages.fieldLabelName} />
              </span>
            </label>
            <input
              autoComplete="off"
              disabled={user.nameDisabled}
              id="name"
              className="textInput"
              type="text"
              value={user.displayName || ''}
              onChange={(e) => this.onNameValueChange(e.target.value)}
            />
            {user.invalidName && (
              <span className="error">
                <FormattedMessage {...messages.nameFieldValidationError} />
              </span>
            )}
            <label htmlFor="email">
              <span>
                <FormattedMessage {...messages.fieldLabelEmail} />
              </span>
            </label>
            <input
              autoComplete="off"
              id="email"
              className="textInput"
              type="text"
              value={user.email || ''}
              onChange={(e) => this.onEmailValueChange(e.target.value)}
            />
            <label htmlFor="color">
              <span>
                <FormattedMessage {...messages.fieldLabelColor} />
              </span>
            </label>
            <div id="color">
              <CirclePicker color={user.color || '#2196f3'} onChangeComplete={this.handleColorChangeComplete} />
            </div>
          </div>
        </CreateUserContent>
        <ModalFooter>
          <Button
            className="button"
            medium
            onClick={() => {
              this.props.dispatch({
                type: actions.CANCEL_EDIT_USER,
              });
            }}
          >
            <span>
              <FormattedMessage {...globalMessages.cancel} />
            </span>
          </Button>
          <Button className="button" primary hover medium onClick={this.checkValidForm}>
            <span>
              <FormattedMessage {...globalMessages.save} />
            </span>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  calendar: state.calendar,
});

const mapDispatchToProps = (dispatch) => ({
  addUser: ({ data, success, fail }) => dispatch(addUser({ data, success, fail })),
  updateUser: ({ data, userId, success, fail }) => dispatch(updateUser({ data, userId, success, fail })),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarUserDetails);

const CreateUserContent = styled.div`
  .content {
    margin: 4px 130px;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.lynch};
    label {
      display: block;
      margin: 24px 0 6px 0;
    }
    #ssnLabel {
      margin-top: 0;
    }

    #gender {
      width: 255px;
    }

    .modal-content {
      display: block;
    }

    .error {
      color: #cf4040;
      font-size: 12px;
      font-weight: 600;
      margin-left: 5;
    }
    p {
      margin: 0;
    }
    label {
    }
    label.inline {
      display: inline-block;
      margin-top: 0;
    }
    button.selected {
      border-color: ${({ theme }) => theme.lightBlue};
    }
    button:disabled {
      cursor: forbidden;
      background: ${({ theme }) => theme.darkGrey};
    }
    input {
      display: block;
      font-family: 'Open Sans';
      font-weight: 600;
    }
    input:disabled {
      cursor: forbidden;
      background: #f6f6f6;
    }

    .textInput {
      height: 34px;
      box-sizing: border-box;
      padding: 0 0 0 12px;
      border-radius: 5px;
      border: 1px solid ${({ theme }) => theme.alto};
      width: 375px;
    }

    .textInput,
    .textInput::placeholder {
      font-size: 16px;
      font-size: 16px;
      font-weight: 600;
      color: ${({ theme }) => theme.lynch};
    }

    .select {
      max-width: 375px;
    }

    .dropdown__control {
      height: 34px;
      min-height: 34px;
      box-sizing: border-box;
    }

    .dropdown__indicators {
      height: 32px;
    }

    .dropdown__single-value,
    .dropdown__placeholder {
      color: ${({ theme }) => theme.lynch};
      font-weight: 600;
      font-size: 16px;
      overflow: visible;
    }

    .dropdown__option--is-focused {
      color: #ffffff;
    }
  }
  h1 {
    text-align: center;
    margin: 5rem 0;
    padding-bottom: 1rem;
    box-shadow: 0 8px 6px -6px ${({ theme }) => theme.lightGrey};
  }
  .buttonGroup {
    padding: 3rem;
    span {
      padding-left: 0.5rem;
    }
    ${StyledButton} {
      margin: 1rem;
    }
  }
`;

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import * as actions from 'constants/actions';
import ToggleButton from 'components/Common/ToggleButtonComponent/ToggleButtonComponent';
import './event-pane.scss';
import messages from './messages';
import { FormattedMessage } from 'react-intl';

class RecursionContainer extends PureComponent {
  toggleRecursion() {
    const event = this.props.calendar.selectedEvent;
    let extra = {
      recursionDisabled: !event.recursionDisabled,
      tainted: true,
    };
    this.props.dispatch({
      type: actions.EDIT_EVENT,
      data: { ...event, ...extra },
    });
  }

  onRecursionUntilChange = (value) => {
    const event = this.props.calendar.selectedEvent;
    const recursion = { ...event.recursion, until: value };
    const data = { ...event, recursion, tainted: true };
    this.props.dispatch({
      type: actions.EDIT_EVENT,
      data: data,
    });
  };

  onRecursionRuleChange = (value) => {
    const event = this.props.calendar.selectedEvent;
    const recursion = { ...event.recursion, rrule: value };
    const data = { ...event, recursion, tainted: true };
    this.props.dispatch({
      type: actions.EDIT_EVENT,
      data: data,
    });
  };

  render() {
    const event = this.props.calendar.selectedEvent;
    return (
      <div className={event.recursionDisabled ? 'event-pane-recursion event-pane-recursion__disabled' : 'event-pane-recursion'}>
        <span>
          <FormattedMessage {...messages.toggleRepeatingEvent} />
        </span>
        <ToggleButton checked={!event.recursionDisabled} className="event-pane-recursion-enabled" onClick={() => this.toggleRecursion()} />
        {event.recursionDisabled ? null : (
          <div id="recursion">
            <label htmlFor="recursionEnd">
              <span>
                <FormattedMessage {...messages.fieldLabelDateUntil} />
              </span>
            </label>
            <DatePicker
              className={'timeInput'}
              disabled={event.disabled || event.recursionDisabled}
              showPopperArrow={false}
              dateFormat={'yyyy/MM/dd'}
              selected={moment.unix(event.recursion.until).toDate()}
              onChange={(date) => this.onRecursionUntilChange(moment(date).endOf('day').unix())}
            />
            <label htmlFor="recursionRule">
              <span>
                {' '}
                <FormattedMessage {...messages.fieldLabelRepeatingRule} />
              </span>
            </label>
            <input
              disabled={true} //{event.disabled || event.recursionDisabled}
              autoComplete="off"
              id="recursionRule"
              className="textInput"
              type="text"
              value={event.recursion?.rrule}
              onChange={(e) => this.onRecursionRuleChange(e.target.value)}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  calendar: state.calendar,
});

export default connect(mapStateToProps)(RecursionContainer);

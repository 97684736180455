import React from 'react';
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translate(30px, 0px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
`;
const MoveIn = ({ duration = 600, delay = 0, children, ...delegated }) => (
  <Wrapper
      {...delegated}
      style={{
        ...(delegated.style || {}),
        animationDuration: `${duration  }ms`,
        animationDelay: `${delay  }ms`,
        animationTimingFunction: 'cubic-bezier(0.175, 0.885, 0.320, 1.275)',
        maxHeight: '100%',
        overflow: 'hidden',
      }}
  >
    {children}
  </Wrapper>
);
const Wrapper = styled.div`
  @media (prefers-reduced-motion: no-preference) {
    animation-name: ${fadeIn};
    animation-fill-mode: backwards;
  }
`;
export default MoveIn;

import { AdminTypeKeys, } from './types';
export var setPersonnelAction = function (personnel) { return ({
    type: AdminTypeKeys.SET_PERSONNEL,
    personnel: personnel,
}); };
export var clearPersonnelAction = function () { return ({
    type: AdminTypeKeys.CLEAR_PERSONNEL,
}); };
export var setOrganizationsAction = function (organizations) { return ({
    type: AdminTypeKeys.SET_ORGANISATIONS,
    organizations: organizations,
}); };
export var clearOrganizationsAction = function () { return ({
    type: AdminTypeKeys.CLEAR_ORGANISATIONS,
}); };
export var setOrganizationAction = function (organization) { return ({
    type: AdminTypeKeys.SET_ORGANISATION,
    organization: organization,
}); };
export var setChecklistTemplatesAction = function (checklistTemplates) { return ({
    type: AdminTypeKeys.SET_CHECKLIST_TEMPLATES,
    checklistTemplates: checklistTemplates,
}); };
export var clearAdminAction = function () { return ({
    type: AdminTypeKeys.CLEAR_ADMIN,
}); };

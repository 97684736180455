import { useReducer } from 'react';
import produce from 'immer';
import { OrganizationTypeKeys } from './types';
var initialState = {
    diagnoses: undefined,
};
var organizationReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    return produce(state, function (draft) {
        switch (action.type) {
            case OrganizationTypeKeys.SET_DIAGNOSES:
                draft.diagnoses = action.diagnoses;
                return;
            case OrganizationTypeKeys.CLEAR_ORGANIZATION:
                return initialState;
        }
    });
};
export var useOrganization = function () {
    var _a = useReducer(organizationReducer, initialState), state = _a[0], dispatch = _a[1];
    return [state, dispatch];
};

/*
 * User List Messages
 *
 * This contains the message translations that are used in the user list table
 * IMPORTANT: there's two messages within the confirmation modal scope when deleting a user
 *  that are defined in this file in order to facilitate imports
 */
import { defineMessages } from 'react-intl';
export var scope = 'app.pages.Admin.PersonnelPage';
export var modalScope = 'app.pages.Admin.PersonnelPage.Modal';
export default defineMessages({
    columnHsaId: {
        id: "".concat(scope, ".columnHsaId"),
        defaultMessage: 'HSA-ID',
    },
    columnName: {
        id: "".concat(scope, ".columnName"),
        defaultMessage: 'Namn',
    },
    columnRole: {
        id: "".concat(scope, ".columnRole"),
        defaultMessage: 'Roll',
    },
    usersPageHeader: {
        id: "".concat(scope, ".usersPageHeader"),
        defaultMessage: 'Användare',
    },
    usersPageSubheader: {
        id: "".concat(scope, ".usersPageSubheader"),
        defaultMessage: 'Lägg till och hantera behörighet för personal',
    },
    usersSearchPlaceholder: {
        id: "".concat(scope, ".usersSearchPlaceholder"),
        defaultMessage: 'Sök användare',
    },
    confirmationRemoveUser: {
        id: "".concat(modalScope, ".confirmationRemoveUser"),
        defaultMessage: 'Är du säker att du vill ta bort ',
    },
    headerRemoveUser: {
        id: "".concat(modalScope, ".headerRemoveUser"),
        defaultMessage: 'Ta bort användare',
    },
});

import { jsx as _jsx } from "react/jsx-runtime";
import { useDispatch } from 'react-redux';
import LoadingIndicatorComponent from '@components/Common/LoadingIndicatorComponent/LoadingIndicatorComponent';
import useAuthAction from '@hooks/useAuth/useAuthAction';
import { logout } from '@api/auth/actions';
import { clearAuthAction } from '@hooks/useAuth/actions';
import { CLEAR_STATE } from '@constants/actions';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import useAdminAction from '@hooks/useAdmin/useAdminAction';
import { clearAdminAction } from '@hooks/useAdmin/actions';
import usePatientListAction from '@hooks/usePatientList/usePatientListAction';
import { clearPatientListAction } from '@hooks/usePatientList/actions';
import usePatientDetailAction from '@hooks/usePatientDetail/usePatientDetailAction';
import { clearPatientDetailAction } from '@hooks/usePatientDetail/actions';
import useOrganizationAction from '@hooks/useOrganization/useOrganizationAction';
import { clearOrganizationAction } from '@hooks/useOrganization/actions';
var LogoutPage = function (_a) {
    var dispatch = useDispatch();
    var authDispatch = useAuthAction();
    var adminDispatch = useAdminAction();
    var patientListDispatch = usePatientListAction();
    var patientDetailDispatch = usePatientDetailAction();
    var organizationDispatch = useOrganizationAction();
    var history = useHistory();
    useEffect(function () {
        var isCancelled = false;
        logout().finally(function () {
            authDispatch(clearAuthAction());
            adminDispatch(clearAdminAction());
            patientListDispatch(clearPatientListAction());
            patientDetailDispatch(clearPatientDetailAction());
            organizationDispatch(clearOrganizationAction());
            dispatch({
                type: CLEAR_STATE,
            });
            if (!isCancelled) {
                history.push('/');
            }
        });
        return function () {
            isCancelled = true;
        };
    }, []);
    return _jsx(LoadingIndicatorComponent, {});
};
export default LogoutPage;

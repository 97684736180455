/**
 *
 * Button
 *
 */

// eslint-disable-next-line no-unused-vars
import React from 'react';
import styled from '@emotion/styled';

export const StyledButton = styled.button`
  background: ${({ theme, primary }) => (primary ? theme.lightBlue : '#fff')};
  padding: ${({ large, square }) => (square ? '0' : large ? '2rem 4rem' : '0.7rem 1.4rem')};
  color: ${({ theme, primary }) => (primary ? theme.white : '#5F7588')};
  border: 1px solid ${({ borderColor, theme }) => (borderColor || theme.lightGrey)};
  border-radius: ${({ rounded }) => (rounded ? '50px' : '5px')};
  font-size: ${({ large }) => (large ? '2.1rem' : '100%')};
  font-weight: ${({ bold, medium }) => (bold ? 'bold' : medium ? 600 : 400)};
  font-family: 'Open Sans', sans-serif;
  :disabled {
    cursor: not-allowed;
    background: ${({ theme, primary }) => (primary ? `${theme.lightBlue}AA` : '#FFFFFF55')};
  }
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  width: ${({ width }) => width && `${width}px`};
  height: ${({ height }) => height && `${height}px`};

  &:hover {
    background-color: ${({ hover, primary, theme, disabled }) => (hover && !disabled ? (primary ? `${theme.lightBlue}CC` : '#f8f8f8A0') : '')};
  }
  position: relative;

  .tooltip {
    background: #fff;
    bottom: 100%;
    color: #000;
    display: block;
    margin-bottom: 15px;
    opacity: 0;
    padding: 0.5rem 1rem;
    border-radius: 3px;
    pointer-events: none;
    position: absolute;
    transform: translateY(10px);
    transition: all 0.25s ease-out;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    min-width: 100%;
    width: max-content;
    left: ${({ width }) => (width ? `calc(-50% - ${width / 4}px)` : 0)};
  }
  .tooltip:before {
    bottom: -30px;
    content: ' ';
    display: block;
    height: 10px;
    left: 0;
    position: absolute;
    width: 100%;
  }
  .tooltip:after {
    border-left: solid transparent 8px;
    border-right: solid transparent 8px;
    border-top: solid #fff 8px;
    bottom: -8px;
    content: ' ';
    height: 0;
    left: 50%;
    margin-left: -8px;
    position: absolute;
    width: 0;
  }
  &:disabled .tooltip,
  &:focus .tooltip,
  &:active .tooltip {
    display: none;
  }
  &:hover .tooltip {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
    z-index: 999;
  }
`;

const Button = props => {
  const { component = 'button', children, onClick, submit, tooltip, ...rest } = props;
  const isButtonWithHref = component === 'button' && props.href;
  const buttonComponent = isButtonWithHref ? 'a' : component;
  const buttonType = submit ? 'submit' : 'button';
  return (
    <StyledButton as={buttonComponent} type={buttonType} onClick={onClick} {...rest}>
      {tooltip && <span className="tooltip">{tooltip}</span>}
      {children}
    </StyledButton>
  );
};
export default Button;

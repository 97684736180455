var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useIntl } from 'react-intl';
import Checkbox from '../common/Checkbox';
import messages from '../messages';
var RoleCheckbox = function (_a) {
    var permission = _a.permission, rest = __rest(_a, ["permission"]);
    var description = getDescription(permission);
    var title = getTitle(permission);
    return _jsx(Checkbox, __assign({ title: title, description: description }, rest));
};
var getDescription = function (permission) {
    var formatMessage = useIntl().formatMessage;
    var admin = formatMessage(messages.adminPermissionDescription);
    var practitioner = formatMessage(messages.practitionerPermissionDescription);
    switch (permission.toLowerCase()) {
        case 'practitioner':
            return practitioner;
        case 'admin':
            return admin;
        default:
            return '';
    }
};
var getTitle = function (permission) {
    var formatMessage = useIntl().formatMessage;
    var admin = formatMessage(messages.adminPermission);
    var practitioner = formatMessage(messages.practitionerPermission);
    switch (permission.toLowerCase()) {
        case 'practitioner':
            return practitioner;
        case 'admin':
            return admin;
        default:
            return permission;
    }
};
export default RoleCheckbox;

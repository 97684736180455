var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import styled from '@emotion/styled';
import { getPatientCsv, getScheduleHistoryCsv, getVasCsv } from '@api/patient/export';
import { addTaskAction, clearExportAction, completeTaskAction } from '@hooks/useExport/actions';
import Button from '@components/Button';
import Modal from '@components/Modal/Modal';
import ModalFooter from '@components/Modal/ModalFooterButtons';
import IntervalPicker from '@components/Date/IntervalPicker/IntervalPicker';
import useAuthState from '@hooks/useAuth/useAuthState';
import useExportAction from '@hooks/useExport/useExportAction';
import useExportState from '@hooks/useExport/useExportState';
import globalMessages from '@src/globalMessages';
import messages from '../messages';
import titles from '../../../components/Vas/messages';
import IconCheck from '@src/resources/img/ikon-checklista-ansluten.png';
import IconWaiting from '@src/resources/img/ikon-checklista-invantar.png';
var TaskList = function () {
    var tasks = useExportState().tasks;
    return (_jsx("div", __assign({ className: "tasklist" }, { children: tasks.map(function (task) { return (_jsxs("div", { children: [_jsx("p", { children: task.label }), _jsxs("span", { children: [task.completed ? _jsx("img", { src: IconCheck }) : _jsx("img", { src: IconWaiting }), " "] })] }, task.id)); }) })));
};
var Footer = function (_a) {
    var canExport = _a.canExport, doExport = _a.doExport, doClose = _a.doClose;
    var tasks = useExportState().tasks;
    var completed = tasks.find(function (value) { return value.completed; });
    return (_jsx(ModalFooter, { children: completed ? (_jsx(Button, __assign({ hover: true, onClick: doClose }, { children: _jsx(FormattedMessage, __assign({}, globalMessages.close)) }))) : (_jsxs(_Fragment, { children: [_jsx(Button, __assign({ hover: true, onClick: doClose }, { children: _jsx(FormattedMessage, __assign({}, globalMessages.cancel)) })), _jsx(Button, __assign({ primary: true, hover: true, disabled: canExport, onClick: doExport }, { children: _jsx(FormattedMessage, __assign({}, messages.exportButton)) }))] })) }));
};
var ExportModal = function (_a) {
    var dismiss = _a.dismiss;
    var exportDispatch = useExportAction();
    var user = useAuthState().user;
    var formatMessage = useIntl().formatMessage;
    var _b = useState(), start = _b[0], setStart = _b[1];
    var _c = useState(), end = _c[0], setEnd = _c[1];
    var dateChangeHandler = function (interval) {
        setStart(interval.startDate);
        setEnd(interval.endDate);
    };
    var exportOnClickHandler = function () {
        exportDispatch(clearExportAction());
        if (!start || !end) {
            return;
        }
        var startDateUtc = start.unix();
        var endDateUtc = end.endOf('day').unix();
        exportPatientData(startDateUtc, endDateUtc);
        var uniqueScaleTypes = ((user === null || user === void 0 ? void 0 : user.organization.modules) || [])
            .filter(function (m) { return m.type === 'SCALE'; })
            .map(function (m) { return m.templates; })
            .reduce(function (accumulator, currentValue) { return accumulator.concat(currentValue); })
            .map(function (t) { var _a; return (_a = t.scaleTemplate) === null || _a === void 0 ? void 0 : _a.scaleType; })
            .filter(function (v, i, a) { return a.indexOf(v) === i; });
        uniqueScaleTypes.forEach(function (type) {
            if (type) {
                exportScaleData(startDateUtc, endDateUtc, type);
            }
        });
        if ((user === null || user === void 0 ? void 0 : user.organization) && (user === null || user === void 0 ? void 0 : user.organization.modules.some(function (m) { return m.type === 'SCHEDULE'; }))) {
            exportScheduleData(startDateUtc, endDateUtc);
        }
    };
    var exportPatientData = function (startDateUtc, endDateUtc) {
        var basicDataLabel = formatMessage(messages.basicDataLabel);
        var task = {
            id: uuidv4(),
            label: basicDataLabel,
            completed: false,
        };
        exportDispatch(addTaskAction(task));
        getPatientCsv(startDateUtc, endDateUtc).then(function (data) {
            fileDownload(data, "".concat(basicDataLabel, " ").concat(moment().format('YYYY-MM-DD'), "_").concat(user ? user.hsaid : 'Okänd', ".csv"));
            exportDispatch(completeTaskAction(task.id));
        });
    };
    var exportScaleData = function (startDateUtc, endDateUtc, scaleType) {
        var title = scaleType === 'NRS_NAUSEA' ? formatMessage(titles.nausea) : formatMessage(titles.pain);
        var task = {
            id: uuidv4(),
            label: title,
            completed: false,
        };
        exportDispatch(addTaskAction(task));
        getVasCsv(startDateUtc, endDateUtc, scaleType).then(function (data) {
            fileDownload(data, "".concat(title, " ").concat(moment().format('YYYY-MM-DD'), "_").concat(user ? user.hsaid : 'Okänd', ".csv"));
            exportDispatch(completeTaskAction(task.id));
        });
    };
    var exportScheduleData = function (startDateUtc, endDateUtc) {
        var medicationPlanLabel = formatMessage(messages.medicationPlanLabel);
        var task = {
            id: uuidv4(),
            label: medicationPlanLabel,
            completed: false,
        };
        exportDispatch(addTaskAction(task));
        getScheduleHistoryCsv(startDateUtc, endDateUtc).then(function (data) {
            fileDownload(data, " ".concat(medicationPlanLabel, " ").concat(moment().format('YYYY-MM-DD'), "_").concat(user ? user.hsaid : 'Okänd', ".csv"));
            exportDispatch(completeTaskAction(task.id));
        });
    };
    var fileDownload = function (data, name) {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([data]));
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    var close = function () {
        exportDispatch(clearExportAction());
        dismiss();
    };
    return (_jsxs(Modal, __assign({ width: "768px", title: formatMessage(messages.modalHeadline), dismiss: function () { return close(); } }, { children: [_jsx(StyledContent, { children: _jsxs("div", __assign({ className: "content" }, { children: [_jsx(IntervalPicker, { startTitle: formatMessage(messages.intervalPickerStartDatePlaceholder), endTitle: formatMessage(messages.intervalPickerEndDatePlaceholder), onChange: dateChangeHandler }), _jsx(TaskList, {})] })) }), _jsx(Footer, { canExport: !end || !start, doExport: exportOnClickHandler, doClose: close })] })));
};
var StyledContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  .content {\n    width: 465px;\n    min-height: 400px;\n\n    .tasklist {\n      padding: 20px 10px;\n      div {\n        display: flex;\n        align-items: center;\n        justify-content: space-between;\n        border-bottom: 1px solid ", ";\n        p {\n          font-size: 14px;\n          font-weight: bold;\n        }\n        span {\n          img {\n            width: 20px;\n            height: 20px;\n          }\n        }\n      }\n    }\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  .content {\n    width: 465px;\n    min-height: 400px;\n\n    .tasklist {\n      padding: 20px 10px;\n      div {\n        display: flex;\n        align-items: center;\n        justify-content: space-between;\n        border-bottom: 1px solid ", ";\n        p {\n          font-size: 14px;\n          font-weight: bold;\n        }\n        span {\n          img {\n            width: 20px;\n            height: 20px;\n          }\n        }\n      }\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.loblolly;
});
export default ExportModal;
var templateObject_1;

import { jsx as _jsx } from "react/jsx-runtime";
import { useHistory, useLocation } from 'react-router-dom';
import useAuthState from '@hooks/useAuth/useAuthState';
import CalendarContainer from '@containers/CalendarContainer/index';
/**
 * Helper class that loads props for the container that can no longer be found after routing was rewritten in TS.
 * Should be removed when the container is rewritten in TS and no longer need prop-drilling.
 */
var CalendarContainerHelper = function () {
    var history = useHistory();
    var user = useAuthState().user;
    var location = useLocation();
    return _jsx(CalendarContainer, { location: location, organization: user === null || user === void 0 ? void 0 : user.organization, history: history });
};
export default CalendarContainerHelper;

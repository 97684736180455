var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import useAuthState from '@hooks/useAuth/useAuthState';
import messages from '@src/pages/PatientListPage/PatientList/PatientFormModal/messages';
import ageMessage from '@src/pages/PatientListPage/PatientList/PatientListItem/messages';
var PatientBox = function (_a) {
    var patient = _a.patient, className = _a.className;
    var user = useAuthState().user;
    var formatMessage = useIntl().formatMessage;
    return patient && (user === null || user === void 0 ? void 0 : user.organization) ? (_jsxs(StyledContent, __assign({ className: className }, { children: [(user === null || user === void 0 ? void 0 : user.organization.medicalBoardingEnabled) && (_jsx("div", __assign({ className: "bedBox headlineH1" }, { children: _jsxs("p", { children: [patient.room ? patient.room.name : '', patient.bed ? ":".concat(patient.bed.name) : ''] }) }))), _jsx("div", __assign({ className: "descriptionBox" }, { children: _jsxs("div", __assign({ className: "wrapper" }, { children: [_jsx("div", __assign({ className: "name headlineH3" }, { children: _jsxs("span", { children: [patient.name, " ", patient.surName] }) })), _jsx("div", __assign({ className: "ssn headlineH2" }, { children: _jsx("span", { children: patient.ssn }) })), _jsx("div", __assign({ className: "genderAge headline-16" }, { children: _jsxs("span", { children: [patient.gender === 'MALE' ? formatMessage(messages.genderMale) : formatMessage(messages.genderFemale), ",", ' ', patient.age > 1
                                        ? patient.age + ' ' + formatMessage(ageMessage.ageSubtitle)
                                        : patient.age + ' ' + formatMessage(ageMessage.ageSubtitleSingular)] }) }))] })) }))] }))) : (_jsx(_Fragment, {}));
};
export default PatientBox;
var StyledContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-block;\n  .bedBox {\n    color: ", ";\n    vertical-align: top;\n    margin-right: 10px;\n    display: inline-flex;\n    justify-content: center;\n    align-items: center;\n    height: 90px;\n    width: 136px;\n    text-align: center;\n    background-color: ", ";\n    border-radius: 5px 0 0 5px;\n    box-shadow: 0 2px 4px 1px rgba(55, 74, 106, 0.1);\n    p {\n      margin: 0;\n    }\n  }\n\n  .descriptionBox {\n    display: inline-block;\n    height: 90px;\n    width: 339px;\n    box-shadow: 0 2px 4px 1px rgba(55, 74, 106, 0.05);\n    text-align: left;\n    .wrapper {\n      margin-left: 30px;\n      margin-top: 20px;\n      .name {\n      }\n      .ssn {\n        display: inline-block;\n        margin-right: 15px;\n      }\n      .genderAge {\n        display: inline-block;\n      }\n    }\n  }\n"], ["\n  display: inline-block;\n  .bedBox {\n    color: ", ";\n    vertical-align: top;\n    margin-right: 10px;\n    display: inline-flex;\n    justify-content: center;\n    align-items: center;\n    height: 90px;\n    width: 136px;\n    text-align: center;\n    background-color: ", ";\n    border-radius: 5px 0 0 5px;\n    box-shadow: 0 2px 4px 1px rgba(55, 74, 106, 0.1);\n    p {\n      margin: 0;\n    }\n  }\n\n  .descriptionBox {\n    display: inline-block;\n    height: 90px;\n    width: 339px;\n    box-shadow: 0 2px 4px 1px rgba(55, 74, 106, 0.05);\n    text-align: left;\n    .wrapper {\n      margin-left: 30px;\n      margin-top: 20px;\n      .name {\n      }\n      .ssn {\n        display: inline-block;\n        margin-right: 15px;\n      }\n      .genderAge {\n        display: inline-block;\n      }\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.cello;
}, function (_a) {
    var theme = _a.theme;
    return theme.blueGrey;
});
var templateObject_1;

/*
 * Boarding Messages
 *
 * This contains the messages to translate the bedroom modal, bedroom select component and associated validation errors
 */
import { defineMessages } from 'react-intl';
var scope = 'app.components.Boarding';
var errorScope = 'app.components.Boarding.Errors';
export default defineMessages({
    room: {
        id: "".concat(scope, ".room"),
        defaultMessage: 'RUM',
    },
    bed: {
        id: "".concat(scope, ".bed"),
        defaultMessage: 'PLATS',
    },
    bedValidationError: {
        id: "".concat(errorScope, ".bedValidationError"),
        defaultMessage: 'Du måste ange plats',
    },
    roomValidationError: {
        id: "".concat(errorScope, ".roomValidationError"),
        defaultMessage: 'Du måste ange rum',
    },
    occupiedBedValidationError: {
        id: "".concat(errorScope, ".occupiedBedValidationError"),
        defaultMessage: 'Det finns redan en patient på denna plats, om ok fortsätt lägga till patient.',
    },
    modalHeadline: {
        id: "".concat(scope, ".roomBedModalHeadline"),
        defaultMessage: 'Rum och Säng',
    },
});

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
var Toggle = function (_a) {
    var options = _a.options, value = _a.value, onToggle = _a.onToggle;
    return (_jsx("div", __assign({ style: { display: 'flex', float: 'right' } }, { children: _jsx(StyledToggle, { children: options.map(function (e, index) { return (_jsxs("div", __assign({ className: "".concat(e.state === value ? 'buttonOption active' : 'buttonOption'), style: {
                    borderRight: "".concat(index < options.length - 1 ? 1 : 0),
                    borderRightStyle: 'solid',
                    backgroundColor: "".concat(e.state !== value ? '#c9e2ff' : 'inherit'),
                }, onClick: onToggle }, { children: [_jsx("img", { src: e.icon, width: "14px", height: "14px" }), _jsx("span", { children: e.text })] }), "toggle_option_".concat(index))); }) }) })));
};
export default Toggle;
var StyledToggle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  border: 1px solid ", ";\n  border-radius: ", ";\n  font-weight: 600;\n  font-family: 'Open Sans', sans-serif;\n  :disabled {\n    cursor: not-allowed;\n    background: ", ";\n  }\n  cursor: ", ";\n  opacity: ", ";\n  height: 34px;\n  margin-top: 9px;\n  margin-left: 10px;\n  display: flex;\n  align-items: center;\n  overflow: hidden;\n\n  .buttonOption {\n    display: flex;\n    align-items: center;\n    height: 32px;\n    padding: 0.7rem 1.4rem;\n    border-color: ", ";\n\n    span {\n      display: inline-block;\n      padding-left: 7px;\n      font-size: 14px;\n      font-weight: 600;\n      letter-spacing: -0.3px;\n      line-height: 19px;\n    }\n  }\n"], ["\n  color: ", ";\n  border: 1px solid ", ";\n  border-radius: ", ";\n  font-weight: 600;\n  font-family: 'Open Sans', sans-serif;\n  :disabled {\n    cursor: not-allowed;\n    background: ", ";\n  }\n  cursor: ", ";\n  opacity: ", ";\n  height: 34px;\n  margin-top: 9px;\n  margin-left: 10px;\n  display: flex;\n  align-items: center;\n  overflow: hidden;\n\n  .buttonOption {\n    display: flex;\n    align-items: center;\n    height: 32px;\n    padding: 0.7rem 1.4rem;\n    border-color: ", ";\n\n    span {\n      display: inline-block;\n      padding-left: 7px;\n      font-size: 14px;\n      font-weight: 600;\n      letter-spacing: -0.3px;\n      line-height: 19px;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme, primary = _a.primary;
    return (primary ? theme.white : '#5F7588');
}, function (_a) {
    var borderColor = _a.borderColor, theme = _a.theme;
    return borderColor || theme.lightGrey;
}, function (_a) {
    var rounded = _a.rounded;
    return (rounded ? '50px' : '5px');
}, function (_a) {
    var theme = _a.theme, primary = _a.primary;
    return (primary ? "".concat(theme.lightBlue, "AA") : '#FFFFFF55');
}, function (_a) {
    var disabled = _a.disabled;
    return (disabled ? 'not-allowed' : 'pointer');
}, function (_a) {
    var disabled = _a.disabled;
    return (disabled ? '0.5' : '1');
}, function (_a) {
    var theme = _a.theme;
    return "".concat(theme.lightGrey);
});
var templateObject_1;

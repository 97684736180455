var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { Controls, HorizontalRule, Title, Wrapper } from '@components/Admin/common/styling/main';
import { loadChecklistTemplates } from '@api/admin/actions';
import { setChecklistTemplatesAction } from '@hooks/useAdmin/actions';
import { pushErrorAction } from '@hooks/useError/actions';
import ChecklistList from '@pages/Admin/ChecklistPage/ChecklistList/ChecklistList';
import useAdminState from '@hooks/useAdmin/useAdminState';
import LoadingIndicatorComponent from '@components/Common/LoadingIndicatorComponent/LoadingIndicatorComponent';
import useAdminAction from '@hooks/useAdmin/useAdminAction';
import ChecklistModal from '@pages/Admin/ChecklistPage/ChecklistModal';
import useErrorAction from '@hooks/useError/useErrorAction';
import globalMessages from '@src/globalMessages';
import messages from './messages';
var ChecklistPage = function () {
    var formatMessage = useIntl().formatMessage;
    var adminDispatch = useAdminAction();
    var errorDispatch = useErrorAction();
    var checklistTemplates = useAdminState().checklistTemplates;
    var _a = useState(''), filter = _a[0], setFilter = _a[1];
    var _b = useState(undefined), selectedTemplate = _b[0], setSelectedTemplate = _b[1];
    useEffect(function () {
        reloadChecklistTemplates();
    }, []);
    var reloadChecklistTemplates = function () {
        loadChecklistTemplates()
            .then(function (templates) {
            adminDispatch(setChecklistTemplatesAction(templates));
        })
            .catch(function () {
            errorDispatch(pushErrorAction({
                title: formatMessage(globalMessages.unexpectedError),
                message: formatMessage(globalMessages.refreshPageMessage),
            }));
        });
    };
    return (_jsxs(ChecklistWrapper, { children: [_jsx(Title, __assign({ className: "headlineH1" }, { children: _jsx("p", { children: formatMessage(messages.checklistTitle) }) })), _jsx(HorizontalRule, {}), _jsx(Controls, __assign({ className: "text-14" }, { children: _jsxs("div", __assign({ className: "left" }, { children: [_jsx("span", { children: formatMessage(messages.checklistSubtitle) }), _jsx("span", __assign({ className: "color-cardinal" }, { children: formatMessage(messages.checklistWarningSubtitle) }))] })) })), checklistTemplates ? (_jsx(ChecklistList, { filter: filter, checklistTemplates: checklistTemplates, showChecklistModal: function (request) {
                    setSelectedTemplate(request);
                } })) : (_jsx(LoadingIndicatorComponent, {})), selectedTemplate && (_jsx(ChecklistModal, { checklistTemplate: selectedTemplate, dismiss: function () {
                    setSelectedTemplate(undefined);
                } }))] }));
};
var ChecklistWrapper = styled(Wrapper)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .left {\n    // width: 584px; restore when reenabling header search + buttons. remove span block and 100% width.\n    width: 100%;\n    span {\n      display: block;\n    }\n  }\n\n  .color-stiletto {\n    color: ", ";\n  }\n\n  .color-cardinal {\n    color: #cb2222;\n  }\n"], ["\n  .left {\n    // width: 584px; restore when reenabling header search + buttons. remove span block and 100% width.\n    width: 100%;\n    span {\n      display: block;\n    }\n  }\n\n  .color-stiletto {\n    color: ", ";\n  }\n\n  .color-cardinal {\n    color: #cb2222;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.stiletto;
});
export default ChecklistPage;
var templateObject_1;

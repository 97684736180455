var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { getRooms } from '@api/patient/patients';
import Button from '../Button/index';
import Scroll from 'components/ui/Scroll';
import messages from './messages';
var BedRoomSelect = function (_a) {
    var _b;
    var width = _a.width, room = _a.room, bed = _a.bed, onChange = _a.onChange, validationFailed = _a.validationFailed;
    var _c = useState(undefined), selectedRoom = _c[0], setSelectedRoom = _c[1];
    var _d = useState(undefined), selectedBed = _d[0], setSelectedBed = _d[1];
    var _e = useState([]), rooms = _e[0], setRooms = _e[1];
    var formatMessage = useIntl().formatMessage;
    var loadRooms = function () {
        getRooms().then(function (value) {
            setRooms(value);
            var rm = value.find(function (r) { return r.id === (room === null || room === void 0 ? void 0 : room.id); });
            setSelectedRoom(rm);
            scrollRoomIntoView();
            if (rm === null || rm === void 0 ? void 0 : rm.beds) {
                setSelectedBed(rm.beds.find(function (b) { return b.id === (bed === null || bed === void 0 ? void 0 : bed.id); }));
            }
        });
    };
    useEffect(function () {
        loadRooms();
    }, []);
    var onRoomChanged = function (room) {
        var roomLocation = { id: room.id, name: room.name };
        setSelectedRoom(room);
        setSelectedBed(undefined);
        onChange(roomLocation, undefined, room.beds.length === 0);
    };
    var onBedChanged = function (bed) {
        if (selectedRoom) {
            var roomLocation = { id: selectedRoom.id, name: selectedRoom.name };
            var bedLocation = { id: bed.id, name: bed.name };
            setSelectedBed(bed);
            onChange(roomLocation, bedLocation, true);
        }
    };
    var scrollRoomIntoView = function () {
        var elements = document.getElementsByClassName('scrollItem selected room');
        if (elements.length > 0) {
            elements[0].scrollIntoView({ inline: 'center', behavior: 'smooth' });
        }
    };
    var occupied = (selectedRoom === null || selectedRoom === void 0 ? void 0 : selectedRoom.beds.length)
        ? (selectedBed === null || selectedBed === void 0 ? void 0 : selectedBed.occupied) && ((selectedRoom === null || selectedRoom === void 0 ? void 0 : selectedRoom.id) !== (room === null || room === void 0 ? void 0 : room.id) || (selectedBed === null || selectedBed === void 0 ? void 0 : selectedBed.id) !== (bed === null || bed === void 0 ? void 0 : bed.id))
        : (selectedRoom === null || selectedRoom === void 0 ? void 0 : selectedRoom.full) && (selectedRoom === null || selectedRoom === void 0 ? void 0 : selectedRoom.id) !== (room === null || room === void 0 ? void 0 : room.id);
    return (_jsxs(StyledBedRoomSelect, __assign({ width: width || '490px' }, { children: [_jsx(Scroll, __assign({ id: "room" }, { children: rooms &&
                    rooms.map(function (r) {
                        var selected = selectedRoom && selectedRoom.id === r.id ? 'selected' : '';
                        return (_jsx(Button, __assign({ onClick: function () {
                                onRoomChanged(r);
                            }, className: "scrollItem ".concat(selected, " room") }, { children: _jsx("div", { children: r.name }) }), r.id));
                    }) })), validationFailed && !selectedRoom && _jsx("span", __assign({ className: "error" }, { children: formatMessage(messages.roomValidationError) })), selectedRoom && ((_b = selectedRoom.beds) === null || _b === void 0 ? void 0 : _b.length) > 1 && (_jsxs(_Fragment, { children: [_jsx("br", {}), _jsx("label", __assign({ htmlFor: "bed", className: "headline" }, { children: _jsx("span", { children: _jsx(FormattedMessage, __assign({}, messages.bed)) }) })), _jsx(Scroll, __assign({ id: "bed", className: "dragscroll" }, { children: selectedRoom.beds.map(function (b) {
                            var selected = selectedBed && selectedBed.id === b.id ? 'selected' : '';
                            return (_jsx(Button, __assign({ onClick: function () {
                                    onBedChanged(b);
                                }, className: "scrollItem ".concat(selected) }, { children: "".concat(selectedRoom.name, ":").concat(b.name) }), "".concat(selectedRoom.name, ":").concat(b.name)));
                        }) })), validationFailed && !selectedBed && _jsx("span", __assign({ className: "error" }, { children: formatMessage(messages.bedValidationError) }))] })), occupied && _jsx("span", __assign({ className: "error" }, { children: formatMessage(messages.occupiedBedValidationError) }))] })));
};
export default BedRoomSelect;
var StyledBedRoomSelect = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: ", ";\n  .error {\n    color: #fa1337;\n    font-size: 1.2rem;\n    margin-left: 5;\n  }\n  button.selected {\n    border-color: ", ";\n  }\n"], ["\n  width: ", ";\n  .error {\n    color: #fa1337;\n    font-size: 1.2rem;\n    margin-left: 5;\n  }\n  button.selected {\n    border-color: ", ";\n  }\n"])), function (_a) {
    var width = _a.width;
    return width;
}, function (_a) {
    var theme = _a.theme;
    return theme.lightBlue;
});
var templateObject_1;

import { useReducer } from 'react';
import { AuthTypeKeys } from '@hooks/useAuth/types';
import { AuthStatus } from '@api/auth/types';
import { v4 as uuidv4 } from 'uuid';
import produce from 'immer';
var initialState = {
    status: AuthStatus.DEFAULT,
    jwt: undefined,
    user: undefined,
    devLoginOptions: undefined,
    monitorSecret: uuidv4(),
};
var authReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    return produce(state, function (draft) {
        switch (action.type) {
            case AuthTypeKeys.SET_USER:
                draft.user = action.user;
                return;
            case AuthTypeKeys.SET_JWT:
                draft.jwt = action.jwt;
                draft.status = AuthStatus.AUTHENTICATED;
                return;
            case AuthTypeKeys.SET_DEV_LOGIN_OPTIONS:
                draft.devLoginOptions = action.devLoginOptions;
                return;
            case AuthTypeKeys.CLEAR_AUTH:
                return initialState;
        }
    });
};
export var useAuth = function () {
    var _a = useReducer(authReducer, initialState), state = _a[0], dispatch = _a[1];
    return [state, dispatch];
};

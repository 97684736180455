import { useContext } from 'react';
import { AuthStateContext } from '@hooks/useAuth/AuthProvider';
var useAuthState = function () {
    var context = useContext(AuthStateContext);
    if (context === undefined) {
        throw new Error('useAuthState must be used within a AuthProvider');
    }
    return context;
};
export default useAuthState;

import { useContext } from 'react';
import { PatientListActionContext } from '@hooks/usePatientList/PatientListProvider';
var usePatientListAction = function () {
    var context = useContext(PatientListActionContext);
    if (context === undefined) {
        throw new Error('usePatientListAction must be used within a PatientListProvider');
    }
    return context;
};
export default usePatientListAction;

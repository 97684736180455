var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
var InformationBox = function (_a) {
    var textLeft = _a.textLeft, textRight = _a.textRight, onLinkClick = _a.onLinkClick, onBoxClick = _a.onBoxClick, icon = _a.icon, iconStyle = _a.iconStyle, children = _a.children;
    return (_jsxs(StyledInformationBox, { children: [_jsxs("div", __assign({ className: "text" }, { children: [_jsx("div", __assign({ className: "left headline" }, { children: textLeft && _jsx("p", { children: textLeft }) })), _jsxs("div", __assign({ className: "right" }, { children: [icon && _jsx("img", { src: icon, className: "icon", style: iconStyle }), textRight && (_jsx("p", __assign({ className: onLinkClick ? 'link' : '', onClick: onLinkClick }, { children: textRight })))] }))] })), _jsx("div", __assign({ className: "box ".concat(onBoxClick ? 'link' : ''), onClick: onBoxClick }, { children: children }))] }));
};
export default InformationBox;
var StyledInformationBox = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-block;\n  flex: auto;\n\n  .link:hover {\n    cursor: pointer;\n  }\n\n  .box.link:hover {\n    background-color: rgba(255, 255, 255, 0.5);\n  }\n\n  margin: 5px 5px;\n  .box {\n    display: inline-block;\n    border-radius: 5px;\n    background-color: ", ";\n    box-shadow: 0 2px 4px 1px rgba(55, 74, 106, 0.05);\n    height: 60px;\n    vertical-align: top;\n    width: 100%;\n  }\n\n  .text {\n    display: flex;\n    margin-bottom: 6px;\n\n    .left {\n      flex: 1;\n      margin-right: 15px;\n    }\n\n    .right {\n      display: inline-flex;\n      justify-content: center;\n      align-items: center;\n      vertical-align: top;\n      color: ", ";\n      font-size: 10px;\n      font-weight: 600;\n    }\n\n    p {\n      margin: 0;\n    }\n  }\n"], ["\n  display: inline-block;\n  flex: auto;\n\n  .link:hover {\n    cursor: pointer;\n  }\n\n  .box.link:hover {\n    background-color: rgba(255, 255, 255, 0.5);\n  }\n\n  margin: 5px 5px;\n  .box {\n    display: inline-block;\n    border-radius: 5px;\n    background-color: ", ";\n    box-shadow: 0 2px 4px 1px rgba(55, 74, 106, 0.05);\n    height: 60px;\n    vertical-align: top;\n    width: 100%;\n  }\n\n  .text {\n    display: flex;\n    margin-bottom: 6px;\n\n    .left {\n      flex: 1;\n      margin-right: 15px;\n    }\n\n    .right {\n      display: inline-flex;\n      justify-content: center;\n      align-items: center;\n      vertical-align: top;\n      color: ", ";\n      font-size: 10px;\n      font-weight: 600;\n    }\n\n    p {\n      margin: 0;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.white;
}, function (_a) {
    var theme = _a.theme;
    return theme.dodgerBlue;
});
var templateObject_1;

import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from 'reducers/RootReducer';
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native

// const persistConfig = {
//   key: 'root',
//   storage
// };
// const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureStore() {
  const composeEnhancers =
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  // const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));
  // let persistor = persistStore(store);
  // return { store, persistor };

  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
  return store;
}

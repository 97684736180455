import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const ToggleButton = React.forwardRef(({checked, onClick, className}, ref) => (
  <CheckBoxWrapper className={className} onClick={onClick}>
    <CheckBox ref={ref} type="checkbox" checked={checked} onChange={() => {}} />
    <CheckBoxLabel />
  </CheckBoxWrapper>
));

ToggleButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

const CheckBoxWrapper = styled.div`
  position: relative;
`;
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 22px;
  margin: 6px 0px 0px !important;
  border-radius: 15px;
  background: #c5cdd4;
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    margin: 3px;
    background: #ffffff;
    transition: 0.16s;
  }
`;
const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: #4ca1ff;
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      margin-left: 21px;
      transition: 0.16s;
    }
  }
`;

export default ToggleButton;
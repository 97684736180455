var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';
import NotificationModal from '../Modal/NotificationModal';
import Header from 'components/Header/Header';
import FloatingActionButton from '../FloatingActionButton/FloatingActionButton';
import IconInfo from '@resources/img/icon-info.svg';
import IconHelpRounded from '@resources/img/icon-help-rounded.svg';
import IconManual from '@resources/img/icon-manual.svg';
import IconReportProblem from '@resources/img/icon-report-problem.svg';
import messages from '@src/globalMessages';
var Main = function (_a) {
    var auth = _a.auth, location = _a.location, dismissNotification = _a.dismissNotification, showNotification = _a.showNotification, notificationTitle = _a.notificationTitle, notificationMessage = _a.notificationMessage, children = _a.children, rest = __rest(_a, ["auth", "location", "dismissNotification", "showNotification", "notificationTitle", "notificationMessage", "children"]);
    var zengioJiraPortal = 'https://chorusab.atlassian.net/servicedesk/customer/portal/15';
    var reportProblemUrl = 'https://chorusab.atlassian.net/servicedesk/customer/portal/15/group/-1';
    var userManual = 'https://chorusab.atlassian.net/servicedesk/customer/portal/15/topic/fd9691ff-ca55-4edd-84d2-ea9e557764de';
    var fabActions = [
        { label: _jsx(FormattedMessage, __assign({}, messages.helpFeedback)), icon: IconHelpRounded, url: zengioJiraPortal },
        { label: _jsx(FormattedMessage, __assign({}, messages.reportProblem)), icon: IconReportProblem, url: reportProblemUrl },
        { label: _jsx(FormattedMessage, __assign({}, messages.zengioUserManual)), icon: IconManual, url: userManual },
    ];
    return (_jsxs(MainWrapper, __assign({ login: location.pathname === '/login', tv: location.pathname === '/tv', calendar: location.pathname === '/calendar' }, { children: [location.pathname !== '/login' && location.pathname !== '/tv' && _jsx(Header, {}), _jsxs("div", __assign({ className: "mainContainerOuterScrollbox" }, { children: [_jsx("div", __assign({ className: "mainContainer" }, { children: children })), location.pathname !== '/login' && location.pathname !== '/tv' && location.pathname !== '/calendar' && (_jsx(FloatingActionButton, { actions: fabActions, icon: IconInfo }))] })), showNotification && _jsx(NotificationModal, { dismiss: dismissNotification, title: notificationTitle, message: notificationMessage })] })));
};
var MainWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  background: ", ";\n  height: 100vh;\n  overflow: hidden;\n  .mainContainerOuterScrollbox {\n    padding-left: -35px;\n    /* Header height == 90px */\n    min-height: calc(100vh - 90px);\n    overflow: auto;\n  }\n\n  .mainContainer {\n    max-width: ", ";\n    margin: 0 auto;\n    height: 100%;\n    width: 100%;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  background: ", ";\n  height: 100vh;\n  overflow: hidden;\n  .mainContainerOuterScrollbox {\n    padding-left: -35px;\n    /* Header height == 90px */\n    min-height: calc(100vh - 90px);\n    overflow: auto;\n  }\n\n  .mainContainer {\n    max-width: ", ";\n    margin: 0 auto;\n    height: 100%;\n    width: 100%;\n  }\n"])), function (_a) {
    var login = _a.login;
    return (login ? '#FFF' : 'inherit');
}, function (_a) {
    var tv = _a.tv, calendar = _a.calendar;
    return (tv ? '1400px' : calendar ? '100%' : '1024px');
});
export default Main;
var templateObject_1;

import { useContext } from 'react';
import { ExportActionContext } from '@hooks/useExport/ExportProvider';
var useExportAction = function () {
    var context = useContext(ExportActionContext);
    if (context === undefined) {
        throw new Error('useExportAction must be used within a ExportProvider');
    }
    return context;
};
export default useExportAction;

import styled from '@emotion/styled';
import React, { PureComponent } from 'react';

export class SidePane extends PureComponent {
  render() {
    return (
      <SidePaneContainer>
        <SidePaneHeaderContainer>{this.props.header}</SidePaneHeaderContainer>
        {this.props.children}
        {this.props.footer}
      </SidePaneContainer>
    );
  }
}

const SidePaneContainer = styled.div`
  background: #ffffff;
  height: 100%;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
`;

const SidePaneHeaderContainer = styled.div`
  position: absolute;
  height: 60px;
  width: 100%;
  border-bottom: 2px solid #f6f6f6;
  background: #fff;
  z-index: 2;
  top: 0px;
`;

export const SidePaneHeader = styled.div`
  position: absolute;
  bottom: 0;
  height: 30px;
  width: 100%;

  & > span {
    margin-left: 12px;
    font-weight: bold;
  }
`;

export const SidePaneHeaderIcon = styled.div`
  margin-right: 15px;
  float: right;
`;

export const SidePaneFooter = styled.div`
  width: 100%;
  padding: 5px 10px;
  border-top: 2px solid #f6f6f6;
  background: #fff;
  position: absolute;
  bottom: 60px;
  z-index: 2;

  button {
    margin: 5px 0;
    width: 100%;
  }

  .button-delete {
    background: #cf4040;
    color: white;
  }
`;

import { defineMessages } from 'react-intl';
var scope = 'app.components.Calendar.DraftsPane';
export default defineMessages({
    headerDetails: {
        id: "".concat(scope, ".headerDetails"),
        defaultMessage: 'Detaljer',
    },
    eventParticipants: {
        id: "".concat(scope, ".eventParticipants"),
        defaultMessage: 'Deltagare',
    },
    buttonSelectAll: {
        id: "".concat(scope, ".buttonSelectAll"),
        defaultMessage: 'Välj alla',
    },
    buttonBookSelected: {
        id: "".concat(scope, ".buttonBookSelected"),
        defaultMessage: 'Boka {size} tider',
    },
});

import { useContext } from 'react';
import { ExportStateContext } from '@hooks/useExport/ExportProvider';
var useExportState = function () {
    var context = useContext(ExportStateContext);
    if (context === undefined) {
        throw new Error('useExportState must be used within a ExportProvider');
    }
    return context;
};
export default useExportState;

/*
 * Patient Detail Page Messages
 *
 * This contains the message translations that are used in the patient detail page
 * when clicking on a patient in the patient overview list
 */
import { defineMessages } from 'react-intl';
var scope = 'app.pages.PatientDetailPage';
export default defineMessages({
    assessments: {
        id: "".concat(scope, ".assessments"),
        defaultMessage: 'Skattningar',
    },
    awaitingConsent: {
        id: "".concat(scope, ".awaitingConsent"),
        defaultMessage: 'INVÄNTAR SAMTYCKE',
    },
    awaitingPatientsConsent: {
        id: "".concat(scope, ".awaitingPatientsConsent"),
        defaultMessage: 'Inväntar patientens samtycke',
    },
    calendarModuleHeadline: {
        id: "".concat(scope, ".calendarModuleHeadline"),
        defaultMessage: 'KALENDER',
    },
    connected: {
        id: "".concat(scope, ".connected"),
        defaultMessage: 'ANSLUTEN',
    },
    connectionCompleted: {
        id: "".concat(scope, ".connectionCompleted"),
        defaultMessage: 'Parkoppling genomförd.',
    },
    connectPatient: {
        id: "".concat(scope, ".connectPatient"),
        defaultMessage: 'Koppla till patient',
    },
    discharge: {
        id: "".concat(scope, ".discharge"),
        defaultMessage: 'Skriv ut patient',
    },
    dischargedPatient: {
        id: "".concat(scope, ".dischargedPatient"),
        defaultMessage: 'Patienten är utskriven',
    },
    disconnect: {
        id: "".concat(scope, ".disconnect"),
        defaultMessage: 'Koppla ifrån',
    },
    editDetails: {
        id: "".concat(scope, ".editDetails"),
        defaultMessage: 'Ändra uppgifter',
    },
    instructionsQRCode: {
        id: "".concat(scope, ".instructionsQRCode"),
        defaultMessage: 'Starta patientappen och scanna QR-koden för att göra en koppling till patienten',
    },
    notConnected: {
        id: "".concat(scope, ".notConnected"),
        defaultMessage: 'EJ ANSLUTEN',
    },
    pair: {
        id: "".concat(scope, ".pair"),
        defaultMessage: 'Parkoppla',
    },
    pairingMissing: {
        id: "".concat(scope, ".pairMissing"),
        defaultMessage: 'Koppling saknas',
    },
    transfer: {
        id: "".concat(scope, ".transfer"),
        defaultMessage: 'Flytta patient',
    },
    showQRCode: {
        id: "".concat(scope, ".showQRCode"),
        defaultMessage: 'Visa QR-kod',
    },
    successMessage: {
        id: "".concat(scope, ".successMessage"),
        defaultMessage: 'Patienten kan använda applikationen.',
    },
    qrCode: {
        id: "".concat(scope, ".qrCode"),
        defaultMessage: 'QR-kod',
    },
});

/*
 * Send home Modal Messages
 *
 * This contains the message translations that are used in the send home
 * modal
 */
import { defineMessages } from 'react-intl';
var scope = 'app.components.SendHomeModal';
export default defineMessages({
    confirmTransferPatient: {
        id: "".concat(scope, ".confirmTransferPatient"),
        defaultMessage: 'Jag vill flytta patienten',
    },
    modalHeadline: {
        id: "".concat(scope, ".modalHeadline"),
        defaultMessage: 'Flytta patient hemmavarande',
    },
    transferPatientButton: {
        id: "".concat(scope, ".transferPatient"),
        defaultMessage: 'Flytta patient',
    },
    transferPatientInfoMessage: {
        id: "".concat(scope, ".transferPatientInfoMessage"),
        defaultMessage: 'Patienten flyttas till patientöversikten för hemmavarande patienter',
    },
});

export var AdminTypeKeys;
(function (AdminTypeKeys) {
    AdminTypeKeys["SET_PERSONNEL"] = "SET_PERSONNEL";
    AdminTypeKeys["CLEAR_PERSONNEL"] = "CLEAR_PERSONNEL";
    AdminTypeKeys["SET_ORGANISATIONS"] = "SET_ORGANISATIONS";
    AdminTypeKeys["CLEAR_ORGANISATIONS"] = "CLEAR_ORGANISATIONS";
    AdminTypeKeys["SET_ORGANISATION"] = "SET_ORGANISATION";
    AdminTypeKeys["SET_CHECKLIST_TEMPLATES"] = "SET_CHECKLIST_TEMPLATES";
    AdminTypeKeys["CLEAR_ADMIN"] = "CLEAR_ADMIN";
})(AdminTypeKeys || (AdminTypeKeys = {}));

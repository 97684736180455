import { defineMessages } from 'react-intl';
export var scope = 'app.pages.Admin.ChecklistPage';
export default defineMessages({
    checklistTitle: {
        id: "".concat(scope, ".checklistTitle"),
        defaultMessage: 'Checklistor',
    },
    checklistSubtitle: {
        id: "".concat(scope, ".checklistSubtitle"),
        defaultMessage: 'Här kan du ändra innehåll i den checklista som visas i patientappen. ',
    },
    checklistWarningSubtitle: {
        id: "".concat(scope, ".checklistWarningSubtitle"),
        defaultMessage: 'Notera att ändringarna du gör publiceras för nya patienter som läggs in i Zengio.',
    },
});

import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
export default class EnvironmentBanner extends React.PureComponent {
  state = {
    show: false,
    env: ''
  };

  componentDidMount() {
    let hostname = window.location.hostname;
    if (hostname.includes('demo')) {
      this.setState({
        show: true,
        env: 'demo'
      });
    } else if (hostname.includes('test')) {
      this.setState({
        show: true,
        env: 'test'
      });
    } else if (hostname.includes('dev') || hostname.includes('localhost') || hostname.includes('0.0.0.0')) {
      this.setState({
        show: true,
        env: 'dev'
      });
    }
  }

  render() {
    return this.state.show && <StyledEnvironmentBanner env={this.state.env}>{this.state.env.toUpperCase()}</StyledEnvironmentBanner>;
  }
}

const StyledEnvironmentBanner = styled.div`
  position: fixed;
  z-index: 20;
  top: 0px;
  left: 0px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  pointer-events: none;
  text-align: center;
  color: white;
  ${({ theme, env }) => {
    if (env === 'demo') {
      return css`
        background: ${theme.environmentBanner.demo.color};
      `;
    } else if (env === 'test') {
      return css`
        background: ${theme.environmentBanner.test.color};
      `;
    } else if (env === 'dev') {
      return css`
        background: ${theme.environmentBanner.dev.color};
      `;
    }
  }}
`;

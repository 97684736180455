/*
 * Global Messages
 *
 * This contains some of the messages that not scope specific and are used globally throughout the application.
 * For example the navigation buttons and the select placeholder.
 */
import { defineMessages } from 'react-intl';
export var scope = 'global';
export var errorScope = 'global.Errors';
export default defineMessages({
    add: {
        id: "".concat(scope, ".buttonAdd"),
        defaultMessage: 'Lägg till',
    },
    addPatient: {
        id: "".concat(scope, ".buttonAddPatient"),
        defaultMessage: 'Lägg till patient',
    },
    confirm: {
        id: "".concat(scope, ".buttonConfirm"),
        defaultMessage: 'Ja',
    },
    cancel: {
        id: "".concat(scope, ".buttonCancel"),
        defaultMessage: 'Avbryt',
    },
    close: {
        id: "".concat(scope, ".buttonClose"),
        defaultMessage: 'Stäng',
    },
    delete: {
        id: "".concat(scope, ".buttonDelete"),
        defaultMessage: 'Radera',
    },
    edit: {
        id: "".concat(scope, ".buttonEdit"),
        defaultMessage: 'Ändra',
    },
    helpFeedback: {
        id: "".concat(scope, ".helpFeedback"),
        defaultMessage: 'Hjälp & Förslag',
    },
    login: {
        id: "".concat(scope, ".buttonLogin"),
        defaultMessage: 'Logga in',
    },
    logout: {
        id: "".concat(scope, ".buttonLogout"),
        defaultMessage: 'Logga ut',
    },
    navigationBackButton: {
        id: "".concat(scope, ".navigationBackButton"),
        defaultMessage: 'Tillbaka',
    },
    ok: {
        id: "".concat(scope, ".buttonOk"),
        defaultMessage: 'Ok',
    },
    remove: {
        id: "".concat(scope, ".buttonRemove"),
        defaultMessage: 'Ta bort',
    },
    refreshPageMessage: {
        id: "".concat(scope, ".refreshPage"),
        defaultMessage: 'Vänligen ladda om sidan.',
    },
    resetBrowserMessage: {
        id: "".concat(scope, ".resetMessage"),
        defaultMessage: 'Stäng webbläsaren och försök igen.',
    },
    reportProblem: {
        id: "".concat(scope, ".reportProblem"),
        defaultMessage: 'Rapportera fel',
    },
    save: {
        id: "".concat(scope, ".buttonSave"),
        defaultMessage: 'Spara',
    },
    show: {
        id: "".concat(scope, ".buttonShow"),
        defaultMessage: 'Visa',
    },
    selectPlaceholder: {
        id: "".concat(scope, ".selectPlaceholder"),
        defaultMessage: 'Välj',
    },
    unsavedChangesMessage: {
        id: "".concat(scope, ".unsavedChangesMessage"),
        defaultMessage: 'Osparade ändringar',
    },
    unknownError: {
        id: "".concat(errorScope, ".unknownError"),
        defaultMessage: 'Ett okänt fel inträffade',
    },
    unexpectedError: {
        id: "".concat(errorScope, ".unexpectedError"),
        defaultMessage: 'Ett oväntat fel inträffade',
    },
    unsavedChanges: {
        id: "".concat(scope, ".unsavedChanges"),
        defaultMessage: 'Osparade ändringar',
    },
    start: {
        id: "".concat(scope, ".buttonStart"),
        defaultMessage: 'Starta',
    },
    zengioUserManual: {
        id: "".concat(scope, ".zengioUserManual"),
        defaultMessage: 'Zengio användarmanual',
    },
});

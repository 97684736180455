import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import useAuthAction from '@hooks/useAuth/useAuthAction';
import { getOrganization, loadMonitorPatients } from '@api/monitor/actions';
import LoadingIndicatorComponent from '@components/Common/LoadingIndicatorComponent/LoadingIndicatorComponent';
import { useInterval } from '@hooks/useInterval/useInterval';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import MonitorPatientList from '@pages/MonitorPage/MonitorConnected/MonitorPatientList';
var MonitorConnected = function (_a) {
    var authDispatch = useAuthAction();
    var history = useHistory();
    var _b = useState(undefined), organization = _b[0], setOrganization = _b[1];
    var _c = useState(undefined), patients = _c[0], setPatients = _c[1];
    var _d = useState(moment()), lastUpdated = _d[0], setLastUpdated = _d[1];
    var _e = useState(false), timedOut = _e[0], setTimesOut = _e[1];
    useEffect(function () {
        var isCancelled = false;
        if (!organization) {
            getOrganization()
                .then(function (value) {
                if (!isCancelled) {
                    setOrganization(value);
                    loadPatients();
                }
            })
                .catch(function (reason) {
                if (!isCancelled) {
                    console.warn('GetOrganization failed. Logging out.', reason);
                    history.push('/logout');
                }
            });
        }
        return function () {
            isCancelled = true;
        };
    }, [organization, authDispatch]);
    useInterval(function () {
        if (organization) {
            loadPatients();
        }
    }, 15000);
    var loadPatients = function () {
        loadMonitorPatients()
            .then(function (result) {
            setPatients(result);
            setLastUpdated(moment());
            setTimesOut(false);
        })
            .catch(function (ex) {
            if (ex.response && ex.response.status == 403) {
                //jwt expiration
                history.push('/logout');
            }
            else {
                if (lastUpdated.isBefore(moment().subtract(60, 'second'))) {
                    setTimesOut(true);
                }
                console.error(ex);
            }
        });
    };
    if (!organization || !patients) {
        return _jsx(LoadingIndicatorComponent, {});
    }
    return _jsx(MonitorPatientList, { organization: organization, patients: patients, lastUpdated: lastUpdated, timedOut: timedOut, compact: true });
};
export default MonitorConnected;

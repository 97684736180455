var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Permission } from '@src/api/auth/types';
import { ListItem, Box } from '@components/Admin/common/styling/list';
import { translatePermission } from '@components/Admin/common/helpers';
import { deletePersonnel } from '@api/admin/actions';
import { clearPersonnelAction } from '@hooks/useAdmin/actions';
import IconEdit from '../../../../resources/img/edit.png';
import IconTrash from '../../../../resources/img/trash.png';
import ConfirmationModal from '@components/Modal/ConfirmationModal';
import CreatePersonnelModal from '@pages/Admin/PersonnelPage/Modal/CreatePersonnelModal';
import useAdminAction from '@hooks/useAdmin/useAdminAction';
import globalMessages from '@src/globalMessages';
import messages from '../messages';
var PersonnelListItem = function (_a) {
    var _b, _c;
    var className = _a.className, personnel = _a.personnel;
    var dispatch = useAdminAction();
    var formatMessage = useIntl().formatMessage;
    var _d = useState(false), showEditPersonnelModal = _d[0], setShowEditPersonnelModal = _d[1];
    var _e = useState(false), showConfirmationModal = _e[0], setShowConfirmationModal = _e[1];
    var onDelete = function () {
        var id = personnel.id;
        if (id != undefined) {
            deletePersonnel(id).then(function () {
                dispatch(clearPersonnelAction());
            });
        }
    };
    var adminRole = (_b = personnel.roles.find(function (role) { return role.permission === Permission.ADMIN; })) === null || _b === void 0 ? void 0 : _b.permission;
    var practitionerRole = (_c = personnel.roles.find(function (role) { return role.permission === Permission.PRACTITIONER; })) === null || _c === void 0 ? void 0 : _c.permission;
    var role = adminRole ? adminRole : practitionerRole ? practitionerRole : personnel.roles[0].permission;
    var permission = translatePermission(role);
    var name = "".concat(personnel.firstName, " ").concat(personnel.surname);
    return (_jsxs(React.Fragment, { children: [_jsxs(ListItem, __assign({ className: className }, { children: [_jsxs(Box, __assign({ className: "left" }, { children: [_jsx(Box, __assign({ className: "role text-14" }, { children: _jsx("p", { children: permission }) })), _jsx(Box, __assign({ className: "name title-16" }, { children: _jsx("p", { children: name }) })), _jsx(Box, __assign({ className: "hsa-id text-14" }, { children: _jsx("p", { children: personnel.hsaId }) }))] })), _jsxs(Box, __assign({ className: "right" }, { children: [_jsxs(Box, __assign({ className: "link", onClick: function () { return setShowEditPersonnelModal(true); } }, { children: [_jsx("img", { src: IconEdit }), _jsx("p", { children: formatMessage(globalMessages.edit) })] })), _jsxs(Box, __assign({ className: "link", onClick: function () { return setShowConfirmationModal(true); } }, { children: [_jsx("img", { src: IconTrash }), _jsx("p", { children: formatMessage(globalMessages.remove) })] }))] }))] })), showEditPersonnelModal && _jsx(CreatePersonnelModal, { personnel: personnel, dismiss: function () { return setShowEditPersonnelModal(false); } }), showConfirmationModal && (_jsx(ConfirmationModal, { text: formatMessage(messages.confirmationRemoveUser) + "".concat(personnel.firstName, " ").concat(personnel.surname, "?"), dismiss: function () { return setShowConfirmationModal(false); }, onConfirm: onDelete, title: formatMessage(messages.headerRemoveUser) }))] }));
};
export default PersonnelListItem;

import React from 'react';
import { PatientIcon, PersonnelIcon, CategoryIcon, RoomIcon, TimeIcon } from '../../svg/CustomIcons';

export const EventLabelIcon = ({ name }) => {
  let props = {
    width: '16px',
    height: '16px',
    mr: '12px',
    ml: '4px',
  };
  if (name === 'activity') return <CategoryIcon {...props} />;
  if (name === 'patient') return <PatientIcon {...props} />;
  if (name === 'room') return <RoomIcon {...props} />;
  if (name === 'resource') return <PersonnelIcon {...props} />;
  if (name === 'time') return <TimeIcon {...props} />;
  else return null;
};

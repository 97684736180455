var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import Cell from './Cell';
var StyledIconCell = styled(Cell)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  padding-left: 20px;\n  img {\n    width: 33px;\n    height: 33px;\n  }\n"], ["\n  display: flex;\n  padding-left: 20px;\n  img {\n    width: 33px;\n    height: 33px;\n  }\n"])));
var IconCell = function (_a) {
    var icon = _a.icon, width = _a.width;
    return (_jsx(StyledIconCell, __assign({ width: width }, { children: _jsx("img", { src: icon }) })));
};
export default IconCell;
var templateObject_1;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { devLogin, devSelectOrganization, getDevLoginOptions } from '@api/auth/actions';
import { setDevLoginOptionsAction } from '@hooks/useAuth/actions';
import { pushErrorAction } from '@hooks/useError/actions';
import Button from '@components/Button';
import useAuthAction from '@hooks/useAuth/useAuthAction';
import useAuthState from '@hooks/useAuth/useAuthState';
import LoadingIndicatorComponent from '@components/Common/LoadingIndicatorComponent/LoadingIndicatorComponent';
import useErrorAction from '@hooks/useError/useErrorAction';
import OrganizationModal from '@pages/LoginPage/OrganizationModal';
import globalMessages from '@src/globalMessages';
var DevLoginComponent = function (_a) {
    var handleLoginResponse = _a.handleLoginResponse;
    var errorDispatch = useErrorAction();
    var authDispatch = useAuthAction();
    var formatMessage = useIntl().formatMessage;
    var devLoginOptions = useAuthState().devLoginOptions;
    var _b = useState(-1), selectedUserId = _b[0], setSelectedUserId = _b[1];
    var _c = useState(undefined), organizations = _c[0], setOrganizations = _c[1];
    useEffect(function () {
        var isCancelled = false;
        if (!devLoginOptions) {
            getDevLoginOptions()
                .then(function (response) {
                if (!isCancelled) {
                    authDispatch(setDevLoginOptionsAction(response));
                }
            })
                .catch(function (reason) {
                if (!isCancelled) {
                    console.log('Failed to fetch options', reason);
                }
            });
        }
        return function () {
            isCancelled = true;
        };
    }, [devLoginOptions, authDispatch]);
    var handleDevLogin = function () {
        if (selectedUserId != -1) {
            devLogin(selectedUserId).then(function (response) {
                if (response.organizationIds) {
                    setOrganizations(response.organizationIds);
                }
                else {
                    handleLoginResponse(response);
                }
            });
        }
    };
    var handleSelectOrganization = function (organizationId) {
        devSelectOrganization(organizationId)
            .then(function (response) {
            handleLoginResponse(response);
        })
            .catch(function () {
            errorDispatch(pushErrorAction({
                title: 'Ett okänt fel inträffade',
                message: 'Stäng webläsaren och försök igen.',
            }));
        });
    };
    return devLoginOptions ? (_jsxs("div", __assign({ className: "testLogin" }, { children: [_jsx("div", { children: "Testinlogg" }), _jsxs("select", __assign({ value: selectedUserId, onChange: function (e) { return setSelectedUserId(parseInt(e.target.value)); } }, { children: [_jsx("option", __assign({ value: -1 }, { children: formatMessage(globalMessages.selectPlaceholder) })), devLoginOptions === null || devLoginOptions === void 0 ? void 0 : devLoginOptions.map(function (practitioner) { return (_jsx("option", __assign({ value: practitioner.id }, { children: "".concat(practitioner.firstName, " ").concat(practitioner.surname) }), practitioner.id)); })] })), _jsx(Button, __assign({ primary: true, disabled: selectedUserId === -1, onClick: handleDevLogin }, { children: formatMessage(globalMessages.login) })), organizations && (_jsx(OrganizationModal, { dismiss: function () { return setOrganizations(undefined); }, organizations: organizations, selectOrganization: handleSelectOrganization }))] }))) : (_jsx(LoadingIndicatorComponent, {}));
};
export default DevLoginComponent;

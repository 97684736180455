import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import QrCodePlaceholder from '@resources/img/qr_code_placeholder.png';
import { pairWithDevice } from '@api/patient/patient';
var QrCode = function (_a) {
    var patientId = _a.patientId;
    var _b = useState(undefined), qrCode = _b[0], setQrCode = _b[1];
    useEffect(function () {
        pairWithDevice(patientId)
            .then(function (result) {
            var urlCreator = window.URL || window.webkitURL;
            var imageUrl = urlCreator.createObjectURL(result);
            setQrCode(imageUrl);
        })
            .catch(function (err) {
            console.warn('pair with device failed', err);
        });
    }, [patientId]);
    return qrCode ? _jsx("img", { id: "qrcode", alt: "qr-code", width: "300", height: "300", src: qrCode }) : _jsx("img", { className: "qrPlaceholder", src: QrCodePlaceholder });
};
export default QrCode;

/*
 * Patient Discharge Modal Messages
 *
 * This contains the message translations that are used in the patient discharge
 * modal
 */
import { defineMessages } from 'react-intl';
var scope = 'app.components.PatientDischarge.PatientDischargeModal';
export default defineMessages({
    dischargePatient: {
        id: "".concat(scope, ".dischargePatient"),
        defaultMessage: 'Skriv ut patient',
    },
    confirmDischarge: {
        id: "".concat(scope, ".confirmDischarge"),
        defaultMessage: 'Är du säker på att du vill skriva ut patienten?',
    },
    confirmJournalDocumentation: {
        id: "".concat(scope, ".confirmJournalDocumentation"),
        defaultMessage: 'Jag har journalfört patientdata',
    },
    journalInfoDischarge: {
        id: "".concat(scope, ".journalInfoDischarge"),
        defaultMessage: 'Vid utskrivning från systemet anonymiseras patientdatan och kan ej återskapas.',
    },
    transferPatientInfoMessage: {
        id: "".concat(scope, ".transferPatientInfoMessage"),
        defaultMessage: 'Patienten flyttas till patientöversikten för hemmavarande patienter',
    },
});

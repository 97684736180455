import { jsx as _jsx } from "react/jsx-runtime";
import useAuthState from '@hooks/useAuth/useAuthState';
import { useEffect } from 'react';
import { getDiagnoses, getPatients } from '@api/patient/patients';
import usePatientListAction from '@hooks/usePatientList/usePatientListAction';
import { setPatientsAction } from '@hooks/usePatientList/actions';
import usePatientListState from '@hooks/usePatientList/usePatientListState';
import PatientList from '@pages/PatientListPage/PatientList/PatientList';
import LoadingIndicatorComponent from '@components/Common/LoadingIndicatorComponent/LoadingIndicatorComponent';
import useOrganizationState from '@hooks/useOrganization/useOrganizationState';
import useOrganizationAction from '@hooks/useOrganization/useOrganizationAction';
import { setDiagnosesAction } from '@hooks/useOrganization/actions';
var PatientListPage = function (_a) {
    var user = useAuthState().user;
    var _b = usePatientListState(), patients = _b.patients, showAtHome = _b.showAtHome;
    var diagnoses = useOrganizationState().diagnoses;
    var patientListDispatch = usePatientListAction();
    var organizationDispatch = useOrganizationAction();
    useEffect(function () {
        getPatients(showAtHome)
            .then(function (value) {
            patientListDispatch(setPatientsAction(value));
        })
            .then(function () {
            if (user === null || user === void 0 ? void 0 : user.organization.diagnosisEnabled) {
                getDiagnoses().then(function (value) {
                    organizationDispatch(setDiagnosesAction(value));
                }); //TODO handle fail
            }
            else {
                organizationDispatch(setDiagnosesAction([]));
            }
        }); //TODO handle fail
    }, [showAtHome, user === null || user === void 0 ? void 0 : user.organization.diagnosisEnabled]);
    return (user === null || user === void 0 ? void 0 : user.organization) && patients && diagnoses ? (_jsx(PatientList, { patients: patients, organization: user.organization, diagnoses: diagnoses })) : (_jsx(LoadingIndicatorComponent, {}));
};
export default PatientListPage;

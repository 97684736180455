import { useReducer } from 'react';
import produce from 'immer';
import { ExportTypeKeys } from '@hooks/useExport/types';
var initialState = {
    tasks: [],
};
var exportReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    return produce(state, function (draft) {
        switch (action.type) {
            case ExportTypeKeys.ADD_TASK:
                draft.tasks.push(action.task);
                return;
            case ExportTypeKeys.COMPLETE_TASK:
                var index = draft.tasks.findIndex(function (t) { return t.id === action.id; });
                if (index >= 0 && index < draft.tasks.length) {
                    draft.tasks[index].completed = true;
                }
                return;
            case ExportTypeKeys.CLEAR_EXPORT:
                return initialState;
        }
    });
};
export var useExport = function () {
    var _a = useReducer(exportReducer, initialState), state = _a[0], dispatch = _a[1];
    return [state, dispatch];
};

import { ErrorTypeKeys } from './types';
import { useReducer } from 'react';
import produce from 'immer';
var initialState = {
    errors: [],
};
var errorReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    return produce(state, function (draft) {
        switch (action.type) {
            case ErrorTypeKeys.PUSH_ERROR:
                draft.errors = draft.errors.concat(action.error);
                return;
            case ErrorTypeKeys.POP_ERROR:
                draft.errors = draft.errors.slice(0, -1);
                return;
        }
    });
};
export var useError = function () {
    var _a = useReducer(errorReducer, initialState), state = _a[0], dispatch = _a[1];
    return [state, dispatch];
};

import * as React from 'react';
var useEffect = React.useEffect, useRef = React.useRef;
export function useInterval(callback, delay) {
    var savedCallback = useRef(null);
    useEffect(function () {
        savedCallback.current = callback;
    });
    useEffect(function () {
        function tick() {
            if (savedCallback.current !== null) {
                savedCallback.current();
            }
        }
        var id = setInterval(tick, delay);
        return function () { return clearInterval(id); };
    }, [delay]);
}

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import useAuthAction from '@hooks/useAuth/useAuthAction';
import useAuthState from '@hooks/useAuth/useAuthState';
import { getMonitorStatus, initiateMonitor } from '@api/auth/actions';
import { MonitorPairingStatus } from '@api/auth/types';
import { useInterval } from '@hooks/useInterval/useInterval';
import { setJwtAction } from '@hooks/useAuth/actions';
import styled from '@emotion/styled';
import MonitorIcon from '@resources/img/ikon-tv.svg';
var MonitorPairing = function (_a) {
    var authDispatch = useAuthAction();
    var monitorSecret = useAuthState().monitorSecret;
    var _b = useState(undefined), code = _b[0], setCode = _b[1];
    useEffect(function () {
        var isCancelled = false;
        if (!code) {
            initiateMonitor(monitorSecret)
                .then(function (response) {
                if (!isCancelled) {
                    setCode(response.code);
                }
            })
                .catch(function (reason) {
                console.warn('InitiateMonitor failed', reason);
            });
        }
        return function () {
            isCancelled = true;
        };
    }, [code, authDispatch]);
    useInterval(function () {
        if (monitorSecret && code) {
            getMonitorStatus(monitorSecret, code)
                .then(function (response) {
                if (response.status === MonitorPairingStatus.ACCEPTED) {
                    if (response.login) {
                        authDispatch(setJwtAction({ refreshExpiration: response.login.refreshExpiration, ttlSeconds: response.login.ttlSeconds }));
                    }
                    else {
                        console.warn('Got status ACCEPTED but no data');
                    }
                }
                else if (response.status === MonitorPairingStatus.REJECTED) {
                    setCode(undefined);
                }
                else {
                }
            })
                .catch(function (reason) {
                console.warn('GetMonitorStatus failed', reason);
            });
        }
    }, 2000);
    return (_jsxs(ConnectWrapper, { children: [_jsx("img", { src: MonitorIcon }), _jsx("div", { children: code }), _jsx("h3", { children: "Logga in p\u00E5 personal.zengio.se och anslut med koden" })] }));
};
var ConnectWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  margin-top: 100px;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  div {\n    border: 1px solid #d2d8dd;\n    border-radius: 5px;\n    padding: 20px;\n    margin-top: 35px;\n    margin-bottom: 28px;\n    color: #374a6a;\n    font-family: 'Open Sans';\n    font-size: 52px;\n    font-weight: bold;\n    letter-spacing: 21.45px;\n    line-height: 71px;\n    text-align: center;\n  }\n  h3 {\n    color: #5e768e;\n    font-family: 'Open Sans';\n    font-size: 18px;\n    font-weight: 600;\n    letter-spacing: 0.01px;\n    line-height: 24px;\n    text-align: center;\n  }\n"], ["\n  display: flex;\n  margin-top: 100px;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  div {\n    border: 1px solid #d2d8dd;\n    border-radius: 5px;\n    padding: 20px;\n    margin-top: 35px;\n    margin-bottom: 28px;\n    color: #374a6a;\n    font-family: 'Open Sans';\n    font-size: 52px;\n    font-weight: bold;\n    letter-spacing: 21.45px;\n    line-height: 71px;\n    text-align: center;\n  }\n  h3 {\n    color: #5e768e;\n    font-family: 'Open Sans';\n    font-size: 18px;\n    font-weight: 600;\n    letter-spacing: 0.01px;\n    line-height: 24px;\n    text-align: center;\n  }\n"])));
export default MonitorPairing;
var templateObject_1;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { List, Header, Box } from '@components/Admin/common/styling/list';
import { translatePermission } from '@components/Admin/common/helpers';
import { Permission } from '@src/api/auth/types';
import PersonnelListItem from './PersonnelListItem';
import IconArrowDownBold from '../../../../resources/img/arrow-down@3x.svg';
import messages from '../messages';
var PersonnelList = function (_a) {
    var personnel = _a.personnel, filter = _a.filter;
    var formatMessage = useIntl().formatMessage;
    var _b = useState({
        column: COLUMN.ROLE,
        order: SORT_ORDER.DESC,
    }), sortedBy = _b[0], setSortedBy = _b[1];
    var toggleSortOrder = function (column) {
        var order = undefined;
        if (sortedBy.column !== column) {
            order = SORT_ORDER.DESC;
        }
        else if (sortedBy.order === SORT_ORDER.ASC) {
            order = SORT_ORDER.DESC;
        }
        else {
            order = SORT_ORDER.ASC;
        }
        setSortedBy({
            column: column,
            order: order,
        });
    };
    var sortedPersonnel = personnel
        .filter(function (personnel) {
        var combinedFields = personnel.firstName + personnel.surname + personnel.hsaId;
        return combinedFields.match(new RegExp(filter, 'i'));
    })
        .sort(function (a, b) {
        var _a, _b;
        var roleA = ((_a = a.roles.find(function (role) { return role.permission === Permission.ADMIN; })) === null || _a === void 0 ? void 0 : _a.permission)
            ? Permission.ADMIN
            : Permission.PRACTITIONER
                ? Permission.PRACTITIONER
                : a.roles[0].permission;
        var roleB = ((_b = b.roles.find(function (role) { return role.permission === Permission.ADMIN; })) === null || _b === void 0 ? void 0 : _b.permission)
            ? Permission.ADMIN
            : Permission.PRACTITIONER
                ? Permission.PRACTITIONER
                : b.roles[0].permission;
        switch (sortedBy.column) {
            case COLUMN.ROLE:
                return stringSort(translatePermission(roleA), translatePermission(roleB), sortedBy.order);
            case COLUMN.NAME:
                return stringSort(a.firstName + a.surname, b.firstName + b.surname, sortedBy.order);
            case COLUMN.HSAID:
                return stringSort(a.hsaId || '', b.hsaId || '', sortedBy.order);
        }
    });
    return (_jsxs(List, { children: [_jsxs(Header, __assign({ className: "unselectable" }, { children: [_jsx(Box, __assign({ className: "button", onClick: function () { return toggleSortOrder(COLUMN.ROLE); }, style: { width: '110px' } }, { children: _jsxs("p", __assign({ className: "".concat(sortedBy.column === COLUMN.ROLE ? 'selected' : '', " title-14") }, { children: [formatMessage(messages.columnRole), sortedBy.column === COLUMN.ROLE && (_jsx("img", { src: IconArrowDownBold, className: "icon ".concat(sortedBy.order === SORT_ORDER.ASC && 'flip-vertical'), alt: "" }))] })) })), _jsx(Box, __assign({ className: "button", onClick: function () { return toggleSortOrder(COLUMN.NAME); }, style: { width: '300px' } }, { children: _jsxs("p", __assign({ className: "".concat(sortedBy.column === COLUMN.NAME ? 'selected' : '', " title-14") }, { children: [formatMessage(messages.columnName), sortedBy.column === COLUMN.NAME && (_jsx("img", { src: IconArrowDownBold, className: "icon ".concat(sortedBy.order === SORT_ORDER.ASC && 'flip-vertical'), alt: "" }))] })) })), _jsx(Box, __assign({ className: "button", onClick: function () { return toggleSortOrder(COLUMN.HSAID); } }, { children: _jsxs("p", __assign({ className: "".concat(sortedBy.column === COLUMN.HSAID ? 'selected' : '', " title-14") }, { children: [formatMessage(messages.columnHsaId), sortedBy.column === COLUMN.HSAID && (_jsx("img", { src: IconArrowDownBold, className: "icon ".concat(sortedBy.order === SORT_ORDER.ASC && 'flip-vertical'), alt: "" }))] })) }))] })), sortedPersonnel.map(function (p, index) { return (_jsx(PersonnelListItem, { personnel: p, className: "listItem" }, index)); })] }));
};
export default PersonnelList;
var SORT_ORDER;
(function (SORT_ORDER) {
    SORT_ORDER["ASC"] = "ASCEND";
    SORT_ORDER["DESC"] = "DESCEND";
})(SORT_ORDER || (SORT_ORDER = {}));
var COLUMN;
(function (COLUMN) {
    COLUMN["ROLE"] = "ROLE";
    COLUMN["NAME"] = "NAME";
    COLUMN["HSAID"] = "HSAID";
})(COLUMN || (COLUMN = {}));
function stringSort(a, b, order) {
    var A = a.toUpperCase();
    var B = b.toUpperCase();
    switch (order) {
        case SORT_ORDER.ASC:
            return A < B ? -1 : A > B ? 1 : 0;
        case SORT_ORDER.DESC:
            return A > B ? -1 : A < B ? 1 : 0;
    }
}

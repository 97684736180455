var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CornerIconCell from '@pages/PatientListPage/PatientList/PatientListItem/components/CornerIconCell';
import Cell from '@pages/PatientListPage/PatientList/PatientListItem/components/Cell';
import SubTitleCell from '@pages/PatientListPage/PatientList/PatientListItem/components/SubTitleCell';
import IconCell from '@pages/PatientListPage/PatientList/PatientListItem/components/IconCell';
import TrendCell from '@pages/PatientListPage/PatientList/PatientListItem/components/TrendCell';
import { Row } from '@pages/PatientListPage/PatientList/PatientListItem/components/Row';
import IconChecklistComplete from '@resources/img/ikon-checklista-ansluten.png';
import IconChecklistStarted from '@resources/img/ikon-checklista-invantar.png';
import IconChecklistPending from '@resources/img/ikon-checklista-ejansluten.png';
import { MainContent } from '@pages/PatientListPage/PatientList/PatientListItem/components/MainContent';
import { useHistory } from 'react-router-dom';
import usePatientListState from '@hooks/usePatientList/usePatientListState';
import Moment from 'react-moment';
import { useIntl } from 'react-intl';
import messages from './messages';
var PatientListItem = function (_a) {
    var _b;
    var patient = _a.patient, organization = _a.organization, compact = _a.compact;
    var history = useHistory();
    var showAtHome = usePatientListState().showAtHome;
    var formatMessage = useIntl().formatMessage;
    var getChecklistIcon = function (checklist) {
        if (!checklist) {
            return undefined;
        }
        if (checklist.completed) {
            return IconChecklistComplete;
        }
        if (checklist.completedItems > 0) {
            return IconChecklistStarted;
        }
        return IconChecklistPending;
    };
    var bedText = organization.medicalBoardingEnabled && patient.room ? (patient.bed ? "".concat(patient.room.name, ":").concat(patient.bed.name) : "".concat(patient.room.name)) : '';
    var patientGender = patient.gender && patient.gender === 'MALE' ? formatMessage(messages.maleSubtitle) : formatMessage(messages.femaleSubtitle);
    var patientAge = patient.age && patient.age >= 1
        ? patient.age + ' ' + formatMessage(messages.ageSubtitle)
        : patient.age + ' ' + formatMessage(messages.ageSubtitleSingular);
    return (_jsxs(Row, __assign({ compact: compact, onClick: compact ? undefined : function () { return history.push("/details/".concat(patient.id)); } }, { children: [_jsx(CornerIconCell, { compact: compact, unlinked: !patient.device, text: bedText }), _jsxs(MainContent, __assign({ compact: compact }, { children: [_jsx(Cell, __assign({ width: compact ? '80px' : '120px', style: { marginLeft: compact ? '30px' : '40px' } }, { children: "".concat(patient.initials.toUpperCase()) })), _jsx(SubTitleCell, { compact: compact, title: patient.yearOfBirth, subtitle: patientGender + ' ' + patientAge, minWidth: compact ? '180px' : undefined }), _jsx(SubTitleCell, { compact: compact, subtitle: patient.createdAt ? (_jsx(Moment, __assign({ format: "YYYY-MM-DD" }, { children: new Date(patient.createdAt * 1000) }))) : '', minWidth: compact ? '180px' : undefined }), patient.modules
                        .filter(function (m) { return m.type === 'CHECKLIST'; })
                        .map(function (checklist) { return (_jsx(IconCell, { width: compact ? '80px' : undefined, icon: getChecklistIcon(checklist) }, "checklist_".concat(patient.id, "_").concat(checklist.id))); }), organization.diagnosisEnabled && (_jsx(SubTitleCell, { width: compact ? '100px' : '125px', compact: compact, title: (_b = patient.diagnosis) === null || _b === void 0 ? void 0 : _b.code }))] })), organization.modules
                .filter(function (m) { return m.type === 'SCALE'; })
                .map(function (module) {
                var patientModule = patient.modules.find(function (m) { return m.moduleDefinitionId === module.id; });
                return (patientModule || {
                    id: 0,
                    moduleDefinitionId: module.id,
                    type: module.type,
                    value: null,
                    trend: null,
                    timestamp: null,
                });
            })
                .map(function (scale, i) { return (_jsx(TrendCell, { value: scale.value, trend: scale.trend, timestamp: scale.timestamp, compact: compact, consented: patient.consented }, "trend_cell_".concat(patient.id, "_").concat(i))); })] })));
};
export default PatientListItem;

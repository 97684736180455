import React from 'react';
import styled from '@emotion/styled';
import { FormattedMessage, injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import Button from 'components/Button';
import ShareUserCalendar from 'components/Calendar/ShareUserCalendar';
import CalendarWidget from 'components/Calendar/CalendarWidget/CalendarWidget';
import eventMessages from './DraftsPane/messages';
import messages from './messages';

class CalendarModuleLarge extends React.Component {
  static propTypes = {
    module: PropTypes.object.isRequired,
    history: PropTypes.any.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showShareCalendar: false,
    };
  }

  openCalenderPlannerView = (calendarId) => {
    this.props.history.push(`/calendar?userId=${calendarId}`);
  };

  showShareCalendar = () => {
    this.setState({ showShareCalendar: true });
  };

  hideShareCalendar = () => {
    this.setState({ showShareCalendar: false });
  };

  eventDataTransform = (eventData) => ({
    id: eventData.id,
    title: eventData.title,
    start: eventData.start * 1000,
    end: eventData.end * 1000,
    color: eventData.category ? eventData.category.color : '#53a0fc',
    extendedProps: {
      description: eventData.description,
      organizerDisplayName: '', //eventData.updatedBy.displayName,
      location: eventData.location ? eventData.location.displayName : '',
      participants:
        `${eventData.participants ? eventData.participants.length : '0'}` + this.props.intl.formatMessage(eventMessages.eventParticipants),
    },
  });

  render() {
    return (
      <div className="calendar-module">
        <ButtonWrapper>
          <div>
            <Button hover className="button" onClick={() => this.openCalenderPlannerView(this.props.module.calendarId)}>
              <p>
                <FormattedMessage {...messages.buttonPlan} />
              </p>
            </Button>
          </div>
          <div>
            <Button hover className="button" onClick={this.showShareCalendar}>
              <p>
                <FormattedMessage {...messages.buttonShare} />
              </p>
            </Button>
          </div>
          <div>
            <Button hover className="button">
              <FontAwesomeIcon icon={faPrint} />
            </Button>
          </div>
        </ButtonWrapper>
        <div className={'calendar-module-container'}>
          <CalendarWidget
            settings={{
              eventSources: [{ url: `/rest/calendar/v1/user/${this.props.module.calendarId}/event` }],
              eventDataTransform: this.eventDataTransform,
              editable: false,
              selectable: false,
            }}
          />
        </div>
        {this.state.showShareCalendar && (
          <ShareUserCalendar calendarIdentifier={this.props.module.calendarIdentifier} dismiss={this.hideShareCalendar} />
        )}
      </div>
    );
  }
}

const ButtonWrapper = styled.div`
  text-align: left;
  margin-bottom: 32px;
  display: flex;
  width: 100%;
  .button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: ${({ theme }) => theme.darkGrey};
    font-size: 14px;
    padding: 0px 13px 0px 13px;
    vertical-align: top;
    height: 34px;

    p {
      margin: 0;
      display: inline-block;
    }

    img {
      width: 1.7rem;
      height: 1.7rem;
      margin: 0 10px 0 0;
    }
  }

  div:nth-of-type(2) {
    margin-left: auto;
    margin-right: 8px;
  }
}`;

export default injectIntl(CalendarModuleLarge);

import * as actions from '../constants/actions';
import produce from 'immer';

const INITIAL_STATE = {
  patients: undefined,
  personnel: undefined,
  locations: undefined,
  categories: undefined,
  drafts: undefined,
  events: undefined,
  loading: {
    patients: true,
    personnel: false,
    locations: false,
    categories: false,
    drafts: false,
    user: false,
    event: false,
  },
  visibleUserList: undefined,
  selectedUser: undefined,
  selectedUsers: new Set(),
  selectedDrafts: new Set(),
  filter: {
    start: undefined,
    end: undefined,
    patientIds: new Set(),
    personnelIds: new Set(),
    categoryIds: new Set(),
    locationIds: new Set(),
  },
  result: undefined,
  focused: undefined,
};

function compareDisplayName(a, b) {
  if (a.displayName < b.displayName) {
    return -1;
  }
  if (a.displayName > b.displayName) {
    return 1;
  }
  return 0;
}

export default function CalendarReducer(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case actions.CLEAR_STATE: {
        return INITIAL_STATE;
      }
      case actions.UPDATE_LOADING: {
        draft.loading[action.data.key] = action.data.value;
        return;
      }

      case actions.SET_VISIBLE_USER_LIST: {
        draft.visibleUserList = draft.visibleUserList === action.data.key ? undefined : action.data.key;
        return;
      }

      case actions.LOADED_CALENDAR_PATIENTS: {
        draft.patients = action.data.sort(compareDisplayName);
        draft.loading.patients = false;
        return;
      }

      case actions.LOADED_CALENDAR_PERSONNEL: {
        draft.personnel = action.data.sort(compareDisplayName);
        draft.loading.personnel = false;
        return;
      }

      case actions.LOADED_CALENDAR_LOCATIONS: {
        draft.locations = action.data.sort(compareDisplayName);
        draft.loading.locations = false;
        return;
      }

      case actions.LOADED_CALENDAR_CATEGORIES: {
        draft.categories = action.data.sort(compareDisplayName);
        draft.loading.categories = false;
        return;
      }

      case actions.LOADED_CALENDAR_DRAFTS: {
        draft.drafts = action.data;
        draft.loading.drafts = false;
        return;
      }

      case actions.EDIT_USER: {
        draft.selectedUser = action.data;
        return;
      }

      case actions.CANCEL_EDIT_USER: {
        draft.selectedUser = undefined;
        return;
      }

      case actions.SELECT_DRAFTS: {
        action.data.forEach((id) => {
          draft.selectedDrafts.add(id);
        });
        return;
      }

      case actions.UNSELECT_DRAFTS: {
        action.data.forEach((id) => {
          draft.selectedDrafts.delete(id);
        });
        return;
      }

      case actions.DRAFTS_BOOKED: {
        draft.selectedDrafts.clear();
        return;
      }

      case actions.EDIT_EVENT: {
        const event = action.data;
        draft.selectedEvent = event;
        const events = [...draft.result.events.filter((e) => e.id !== event.id), event];
        draft.events = generateEventList(events, draft.focused);
        return;
      }

      case actions.CANCEL_EDIT_EVENT: {
        draft.events = generateEventList(draft.result.events, draft.focused);
        draft.selectedEvent = undefined;
        return;
      }

      case actions.ADDED_CALENDAR_EVENT: {
        draft.events = generateEventList(draft.result.events, draft.focused);
        draft.selectedEvent = undefined;
        return;
      }

      case actions.UPDATED_CALENDAR_EVENT: {
        draft.events = generateEventList(draft.result.events, draft.focused);
        draft.selectedEvent = undefined;
        return;
      }

      case actions.DELETED_CALENDAR_EVENT: {
        draft.events = generateEventList(draft.result.events, draft.focused);
        draft.selectedEvent = undefined;
        return;
      }

      case actions.UPDATE_FILTER: {
        draft.filter = action.data;
        return;
      }

      case actions.LOADED_EVENTS: {
        const userId = draft.focused?.id;
        draft.result = action.data;
        draft.focused = action.data.users.find((u) => u.id === userId);
        const event = { ...draft.selectedEvent };
        const events = event ? action.data.events.filter((e) => e.id !== event.id).concat(event) : action.data.events;
        draft.events = generateEventList(events, draft.focused);
        return;
      }

      case actions.FOCUS_USER: {
        const result = draft.result;
        const focused = result.users.find((u) => u.id === action.data.id);
        draft.focused = focused;
        draft.events = generateEventList(draft.events, focused);
        return;
      }
    }
  });
}

function generateEventList(events, focused) {
  const focusedIds = new Set([...(focused?.events || [])]);
  const eventArray = [...(events || [])];
  return eventArray.map((e) => {
    e.focused = focusedIds.has(e.id);
    return e;
  });
}

import { defineMessages } from 'react-intl';
var scope = 'app.components.Calendar.UserPane';
export default defineMessages({
    sidePaneHeader: {
        id: "".concat(scope, ".sidePaneHeader"),
        defaultMessage: 'Välj',
    },
    sidePaneHeaderSelected: {
        id: "".concat(scope, ".sidePaneHeaderSelected"),
        defaultMessage: 'Valda',
    },
});

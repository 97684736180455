var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
export var MainContentHeading = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  flex: 7;\n  margin-left: 5px;\n  // > div:nth-of-type(1) {\n  //   padding-left: 60px;\n  //   flex-basis: ", ";\n  // }\n  // > div:nth-of-type(2) {\n  //   flex-basis: ", ";\n  // }\n  // > div:nth-of-type(3) {\n  //   flex-basis: 20%;\n  // }\n  // > div:nth-of-type(4) {\n  //   flex-basis: ", ";\n  // }\n"], ["\n  align-items: center;\n  display: flex;\n  flex: 7;\n  margin-left: 5px;\n  // > div:nth-of-type(1) {\n  //   padding-left: 60px;\n  //   flex-basis: ", ";\n  // }\n  // > div:nth-of-type(2) {\n  //   flex-basis: ", ";\n  // }\n  // > div:nth-of-type(3) {\n  //   flex-basis: 20%;\n  // }\n  // > div:nth-of-type(4) {\n  //   flex-basis: ", ";\n  // }\n"])), function (_a) {
    var compact = _a.compact;
    return (compact ? '17%' : '26%');
}, function (_a) {
    var compact = _a.compact;
    return (compact ? '41%' : '26%');
}, function (_a) {
    var compact = _a.compact;
    return (compact ? '22%' : '28%');
});
var templateObject_1;

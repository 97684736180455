var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { getPatient } from '@api/patient/patient';
import { getDiagnoses } from '@api/patient/patients';
import LoadingIndicatorComponent from '@components/Common/LoadingIndicatorComponent/LoadingIndicatorComponent';
import { clearPatientDetailAction, setPatientAction } from '@hooks/usePatientDetail/actions';
import { setDiagnosesAction } from '@hooks/useOrganization/actions';
import usePatientDetailAction from '@hooks/usePatientDetail/usePatientDetailAction';
import usePatientDetailState from '@hooks/usePatientDetail/usePatientDetailState';
import useOrganizationAction from '@hooks/useOrganization/useOrganizationAction';
import useOrganizationState from '@hooks/useOrganization/useOrganizationState';
import useAuthState from '@hooks/useAuth/useAuthState';
import PatientDetail from '@pages/PatientDetailPage/PatientDetail';
import messages from './messages';
var PatientDetailPage = function (_a) {
    var id = useParams().id;
    var patient = usePatientDetailState().patient;
    var user = useAuthState().user;
    var diagnoses = useOrganizationState().diagnoses;
    var patientDispatch = usePatientDetailAction();
    var organizationDispatch = useOrganizationAction();
    useEffect(function () {
        var idNum = Number(id);
        if ((patient === null || patient === void 0 ? void 0 : patient.id) !== idNum) {
            patientDispatch(clearPatientDetailAction());
            getPatient(idNum).then(function (value) {
                patientDispatch(setPatientAction(value));
            });
        }
    }, [id]);
    useEffect(function () {
        if (user === null || user === void 0 ? void 0 : user.organization.diagnosisEnabled) {
            getDiagnoses().then(function (value) {
                organizationDispatch(setDiagnosesAction(value));
            }); //TODO handle fail
        }
        else {
            organizationDispatch(setDiagnosesAction([]));
        }
    }, [user === null || user === void 0 ? void 0 : user.organization.diagnosisEnabled]);
    var organization = user === null || user === void 0 ? void 0 : user.organization;
    if (!patient || patient.id !== Number(id) || !organization || !diagnoses) {
        return _jsx(LoadingIndicatorComponent, {});
    }
    return patient.discharged ? (_jsx("div", { children: _jsx("p", { children: _jsx(FormattedMessage, __assign({}, messages.dischargedPatient)) }) })) : (_jsx(PatientDetail, { patient: patient, organization: organization, diagnoses: diagnoses }));
};
export default PatientDetailPage;

import { defineMessages } from 'react-intl';
export var scope = 'app.pages.Admin.ChecklistPage.ChecklistList';
export var errorScope = 'app.pages.Admin.ChecklistPage.ChecklistList.Errors';
export var modalScope = 'app.pages.Admin.ChecklistPage.ChecklistList.Modal';
export default defineMessages({
    headerName: {
        id: "".concat(scope, ".headerName"),
        defaultMessage: 'Namn',
    },
    headerStatus: {
        id: "".concat(scope, ".headerStatus"),
        defaultMessage: 'Status',
    },
    headerVersion: {
        id: "".concat(scope, ".headerVersion"),
        defaultMessage: 'Version',
    },
    statusDraft: {
        id: "".concat(scope, ".statusDraft"),
        defaultMessage: 'Utkast',
    },
    statusPublished: {
        id: "".concat(scope, ".statusPublished"),
        defaultMessage: 'Publicerad',
    },
    statusInactive: {
        id: "".concat(scope, ".statusInactive"),
        defaultMessage: 'Inaktiv',
    },
    publishChecklist: {
        id: "".concat(scope, ".publishChecklist"),
        defaultMessage: 'Publicera',
    },
    confirmationPublishHeader: {
        id: "".concat(modalScope, ".confirmationPublishHeader"),
        defaultMessage: 'Publicera ny checklista',
    },
    confirmationPublishChecklist: {
        id: "".concat(modalScope, ".confirmationPublishChecklist"),
        defaultMessage: 'Vill du publicera den nya version av',
    },
    confirmationRemoveHeader: {
        id: "".concat(modalScope, ".confirmationRemoveHeader"),
        defaultMessage: 'Ta bort checklista',
    },
    confirmationRemoveText: {
        id: "".concat(modalScope, ".confirmationRemoveText"),
        defaultMessage: 'Är du säker att du vill ta bort ',
    },
    publishErrorMessage: {
        id: "".concat(errorScope, ".publishErrorMessage"),
        defaultMessage: 'Det gick inte att publicera checklistan',
    },
    removeErrorMessage: {
        id: "".concat(errorScope, ".removeErrorMessage"),
        defaultMessage: 'Det gick inte att ta bort checklistan',
    },
});

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { css } from '@emotion/react';
export var ListHeader = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 13px 0;\n  box-shadow: ", ";\n  background: white;\n  font-size: 11px;\n  font-weight: 600;\n  border-radius: 5px;\n  display: flex;\n  width: 100%;\n  min-width: 850px;\n  // > div:nth-of-type(1) {\n  //   margin-right: 5px;\n  //   text-align: center;\n  //   width: ", ";\n  // }\n  // > div:nth-of-type(n + 3) {\n  //   margin-left: 5px;\n  //   text-align: left;\n  //   flex-basis: ", ";\n  // }\n"], ["\n  padding: 13px 0;\n  box-shadow: ", ";\n  background: white;\n  font-size: 11px;\n  font-weight: 600;\n  border-radius: 5px;\n  display: flex;\n  width: 100%;\n  min-width: 850px;\n  // > div:nth-of-type(1) {\n  //   margin-right: 5px;\n  //   text-align: center;\n  //   width: ", ";\n  // }\n  // > div:nth-of-type(n + 3) {\n  //   margin-left: 5px;\n  //   text-align: left;\n  //   flex-basis: ", ";\n  // }\n"])), function (props) { return props.theme.bs; }, function (_a) {
    var compact = _a.compact;
    return (compact ? '174px' : '125px');
}, function (_a) {
    var compact = _a.compact;
    return (compact ? '160px' : '104px');
});
export var ListHeaderItem = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), function (_a) {
    var width = _a.width;
    if (width) {
        return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        width: ", ";\n      "], ["\n        width: ", ";\n      "])), width || '110px');
    }
    else {
        return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        flex: 2;\n      "], ["\n        flex: 2;\n      "])));
    }
}, function (_a) {
    var minWidth = _a.minWidth;
    return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      min-width: ", ";\n    "], ["\n      min-width: ", ";\n    "])), minWidth);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useAuthAction from '@hooks/useAuth/useAuthAction';
import useAuthState from '@hooks/useAuth/useAuthState';
import { clearAuthAction, setJwtAction, setUserAction } from '@hooks/useAuth/actions';
import { getMe, refreshJwt } from '@api/auth/actions';
import LoadingIndicatorComponent from '@components/Common/LoadingIndicatorComponent/LoadingIndicatorComponent';
import { AuthStatus } from '@api/auth/types';
import useErrorState from '@hooks/useError/useErrorState';
import useErrorAction from '@hooks/useError/useErrorAction';
import MainContainer from '@containers/MainContainer/MainContainer';
import { GlobalSyles } from '@constants/styling';
import Routes from '@app/Routes';
import JwtRefreshComponent from '@components/Main/JwtRefreshComponent';
import NotificationModal from '@components/Modal/NotificationModal';
import { popErrorAction } from '@hooks/useError/actions';
import WebSocketComponent from '@components/Main/WebSocketComponent';
var AppContent = function (_a) {
    var disableWebsockets = process.env.features_disable_websockets;
    var location = useLocation();
    var authDispatch = useAuthAction();
    var errorDispatch = useErrorAction();
    var _b = useAuthState(), user = _b.user, status = _b.status;
    var errors = useErrorState().errors;
    var history = useHistory();
    var _c = useState(false), authVerified = _c[0], setAuthVerified = _c[1];
    useEffect(function () {
        var isCancelled = false;
        if (!authVerified) {
            refreshJwt()
                .then(function (jwt) {
                if (!isCancelled) {
                    authDispatch(setJwtAction({ refreshExpiration: jwt.refreshExpiration, ttlSeconds: jwt.ttlSeconds }));
                    if (user || location.pathname === '/tv') {
                        setAuthVerified(true);
                    }
                    else {
                        getMe()
                            .then(function (response) {
                            if (!isCancelled) {
                                authDispatch(setUserAction(response));
                            }
                        })
                            .catch(function (reason) {
                            if (!isCancelled) {
                                console.warn('Failed to fetch user. Logging out.', reason);
                                history.push('/logout');
                            }
                        })
                            .finally(function () {
                            if (!isCancelled) {
                                setAuthVerified(true);
                            }
                        });
                    }
                }
            })
                .catch(function (reason) {
                if (!isCancelled) {
                    console.error('Failed to refresh.', reason);
                    authDispatch(clearAuthAction());
                    setAuthVerified(true);
                }
            });
        }
        return function () {
            isCancelled = true;
        };
    }, [user, authVerified, authDispatch]);
    return !authVerified ? (_jsx(LoadingIndicatorComponent, {})) : (_jsx(_Fragment, { children: _jsxs(MainContainer, __assign({ user: user, location: location }, { children: [status === AuthStatus.AUTHENTICATED && _jsx(JwtRefreshComponent, {}), !disableWebsockets && status === AuthStatus.AUTHENTICATED && user ? _jsx(WebSocketComponent, {}) : null, _jsx(GlobalSyles, {}), _jsx(Routes, {}), errors && errors.length > 0 && (_jsx(NotificationModal, { dismiss: function () { return errorDispatch(popErrorAction()); }, title: errors[errors.length - 1].title, message: errors[errors.length - 1].message }))] })) }));
};
export default AppContent;

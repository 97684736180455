import { AuthTypeKeys } from './types';
export var setUserAction = function (user) { return ({
    type: AuthTypeKeys.SET_USER,
    user: user,
}); };
export var setJwtAction = function (jwt) { return ({
    type: AuthTypeKeys.SET_JWT,
    jwt: jwt,
}); };
export var setDevLoginOptionsAction = function (devLoginOptions) { return ({
    type: AuthTypeKeys.SET_DEV_LOGIN_OPTIONS,
    devLoginOptions: devLoginOptions,
}); };
export var clearAuthAction = function () { return ({
    type: AuthTypeKeys.CLEAR_AUTH,
}); };

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash.uniqueid';
import { EventLabelIcon } from './EventLabelIcon';

export default class EventTextBox extends PureComponent {
  static propTypes = {
    title: PropTypes.node,
    iconName: PropTypes.string,
    disabled: PropTypes.bool,
    invalid: PropTypes.bool,
    errorMessage: PropTypes.node,
    value: PropTypes.string,
    onValueChange: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.id = uniqueId('event-text-box-');
  }

  render() {
    return (
      <div>
        <label htmlFor={this.id}>
          <EventLabelIcon name={this.props.iconName} />
          <span>{this.props.title}</span>
        </label>
        <input
          disabled={this.props.disabled}
          autoComplete="off"
          id={this.id}
          className="textInput"
          type="text"
          value={this.props.value || ''}
          onChange={(e) => this.props.onValueChange(e.target.value)}
        />
        {this.props.invalid && this.props.errorMessage && <span className="error">{this.props.errorMessage}</span>}
      </div>
    );
  }
}

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
var ModalFooter = function (_a) {
    var className = _a.className, children = _a.children, rest = __rest(_a, ["className", "children"]);
    return (_jsx(ModalFooterWrapper, __assign({ className: "".concat(className || '') }, rest, { children: _jsx("div", __assign({ className: "buttons" }, { children: children })) })));
};
var ModalFooterWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 124px;\n  box-shadow: 0 20px 30px 2px rgba(0, 0, 0, 0.2);\n  text-align: center;\n  margin-top: 37px;\n\n  .buttons {\n    margin: 38px 10px;\n\n    button {\n      margin: 0 10px;\n      height: 48px;\n      width: 234px;\n      font-size: 18px;\n    }\n  }\n"], ["\n  display: inline-flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 124px;\n  box-shadow: 0 20px 30px 2px rgba(0, 0, 0, 0.2);\n  text-align: center;\n  margin-top: 37px;\n\n  .buttons {\n    margin: 38px 10px;\n\n    button {\n      margin: 0 10px;\n      height: 48px;\n      width: 234px;\n      font-size: 18px;\n    }\n  }\n"])));
export default ModalFooter;
var templateObject_1;

import React, { PureComponent } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import uniqueId from 'lodash.uniqueid';
import { EventLabelIcon } from './EventLabelIcon';

export default class EventTimeBox extends PureComponent {
  static propTypes = {
    title: PropTypes.node,
    iconName: PropTypes.string,
    disabled: PropTypes.bool,
    invalid: PropTypes.bool,
    errorMessage: PropTypes.node,
    value: PropTypes.any,
    onValueChange: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.id = uniqueId('event-time-box-');
  }

  render() {
    return (
      <div>
        <label htmlFor={this.id}>
          <EventLabelIcon name={this.props.iconName} />
          <span>{this.props.title}</span>
        </label>
        <DatePicker
          className={'timeInput'}
          id={this.id}
          selected={moment.unix(this.props.value).toDate()}
          onChange={(date) => this.props.onValueChange(moment(date).unix())}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={30}
          timeCaption="Tid"
          dateFormat="yyyy/MM/dd HH:mm"
        />
        {this.props.invalid && this.props.errorMessage && <span className="error">{this.props.errorMessage}</span>}
      </div>
    );
  }
}

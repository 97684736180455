import { jsx as _jsx } from "react/jsx-runtime";
import useAuthState from '@hooks/useAuth/useAuthState';
import { AuthStatus } from '@api/auth/types';
import MonitorConnected from '@pages/MonitorPage/MonitorConnected';
import MonitorPairing from '@pages/MonitorPage/MonitorPairing';
var MonitorPage = function (_a) {
    var status = useAuthState().status;
    return status === AuthStatus.AUTHENTICATED ? _jsx(MonitorConnected, {}) : _jsx(MonitorPairing, {});
};
export default MonitorPage;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
export var Row = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: ", ";\n  display: flex;\n  width: 100%;\n  min-width: 850px;\n\n  > :last-child {\n    border-radius: 0 5px 5px 0;\n  }\n\n  height: ", ";\n  margin-bottom: 5px;\n  &:hover {\n    opacity: 0.7;\n  }\n"], ["\n  cursor: ", ";\n  display: flex;\n  width: 100%;\n  min-width: 850px;\n\n  > :last-child {\n    border-radius: 0 5px 5px 0;\n  }\n\n  height: ", ";\n  margin-bottom: 5px;\n  &:hover {\n    opacity: 0.7;\n  }\n"])), function (_a) {
    var onClick = _a.onClick;
    return (onClick ? 'pointer' : 'inherit');
}, function (_a) {
    var compact = _a.compact;
    return (compact ? '46px' : '80px');
});
var templateObject_1;

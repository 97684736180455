/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import Swedish from '../../translations/sv.json';
import English from '../../translations/en.json';

const messages = {
  'sv': Swedish,
  'en': English,
};

/*
  Handles translation errors including when language not supported and the fallback locale is used instead.
  This will prevent errors being displayed in the console possibly causing the app to crash
*/
const handleTranslationError = () => {
  console.log('Setting fallback as locale');
};
export class LanguageProvider extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  render() {
    return (
      <IntlProvider locale={this.props.locale} messages={messages[this.props.locale]} onError={handleTranslationError}>
        {React.Children.only(this.props.children)}
      </IntlProvider>
    );
  }
}

LanguageProvider.propTypes = {
  locale: PropTypes.string,
  messages: PropTypes.object,
  children: PropTypes.element.isRequired,
};

const mapStateToProps = (state) => ({
  locale: state.language.locale,
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageProvider);

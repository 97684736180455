import styled from '@emotion/styled';

export const Wrapper = styled.div`
  padding: 60px 0px;
  margin: auto;
  width: 960px;

  input:focus,  
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }
`;

export const Title = styled.div`
    p {
      margin: 0 0 9px 0;
    }
`;

export const HorizontalRule = styled.div`
  height: 1px;
  border: 1.5px solid 95,117,136,1;
  width: auto;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.lynch};
  margin: 0;
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 97px;
  width: 100%;
  
  .button {
    color: black;
    background-color:  ${({ theme }) => theme.wildRose};
  }
  .search {
    display: inline-flex;
    align-items: center;
    margin: 0 25px;
    padding-right: 10px;
    background-color: white;
    height: 34px;
    border-radius: 4px;
    
    input {
      height: 34px;	
      width: 198px;	
      padding: 0 7px;
      border: 0; 
    }
  }
  
  .right {
    display: inline-flex;
  }
`;

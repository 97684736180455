import React from 'react';
import { connect } from 'react-redux';

import Main from '../../components/Main/Main';
import { dismissNotification } from 'actions/notifications';

class MainContainer extends React.Component {
  componentDidMount() {}

  render() {
    return <Main {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  showNotification: state.notifications.show,
  notificationTitle: state.notifications.title,
  notificationMessage: state.notifications.message,
});

const mapDispatchToProps = (dispatch) => ({
  dismissNotification: () => dispatch(dismissNotification()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);

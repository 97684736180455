import { useIntl } from 'react-intl';
import { Permission } from '@src/api/auth/types';
import messages from '../messages';
export var translatePermission = function (permission) {
    var formatMessage = useIntl().formatMessage;
    switch (permission) {
        case Permission.ADMIN:
            return formatMessage(messages.adminPermission);
        case Permission.PRACTITIONER:
            return formatMessage(messages.practitionerPermission);
        case Permission.NONE:
            return formatMessage(messages.userPermission);
        default:
            return permission.charAt(0).toUpperCase() + permission.slice(1).toLowerCase();
    }
};

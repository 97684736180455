import { ExportTypeKeys } from './types';
export var addTaskAction = function (task) { return ({
    type: ExportTypeKeys.ADD_TASK,
    task: task,
}); };
export var completeTaskAction = function (id) { return ({
    type: ExportTypeKeys.COMPLETE_TASK,
    id: id,
}); };
export var clearExportAction = function () { return ({
    type: ExportTypeKeys.CLEAR_EXPORT,
}); };

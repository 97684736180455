export function isRoot(user) {
    var permissions = user && user.organization && user.organization.permissions;
    return isNotNullOrEmpty(permissions) && permissions[0] === 'ROOT';
}
export function canShowCareView(user, view) {
    var permissions = user && user.organization && user.organization.permissions;
    return isNotNullOrEmpty(permissions) && permissions[0] !== 'NONE' && user.views.includes(view);
}
function isNotNullOrEmpty(arr) {
    return arr !== undefined && arr.length > 0;
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { List, Header, Box } from '@components/Admin/common/styling/list';
import OrganizationListItem from './OrganizationListItem';
import IconArrowDownBold from '../../../../resources/img/arrow-down@3x.svg';
import messages from '../messages';
var OrganizationList = function (_a) {
    var organizations = _a.organizations, filter = _a.filter;
    var formatMessage = useIntl().formatMessage;
    var _b = useState(SORT_ORDER.DESC), sortedOrder = _b[0], setSortedOrder = _b[1];
    var toggleSortOrder = function () {
        setSortedOrder(sortedOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC);
    };
    var sortedOrganizations = organizations
        .filter(function (organization) {
        var combinedFields = organization.name + organization.title + organization.hsaId;
        return combinedFields.match(new RegExp(filter, 'i'));
    })
        .sort(function (a, b) { return sortOrganizationName(a, b, sortedOrder); });
    return (_jsxs(List, { children: [_jsx(Header, __assign({ className: "unselectable" }, { children: _jsx(Box, __assign({ className: "button", onClick: toggleSortOrder }, { children: _jsxs("p", __assign({ className: "selected title-14" }, { children: [formatMessage(messages.heading), ' ', _jsx("img", { src: IconArrowDownBold, className: "icon ".concat(sortedOrder === SORT_ORDER.ASC && 'flip-vertical'), alt: "" })] })) })) })), sortedOrganizations.map(function (o, index) { return (_jsx(OrganizationListItem, { organization: o, className: "listItem" }, index)); })] }));
};
export default OrganizationList;
var SORT_ORDER;
(function (SORT_ORDER) {
    SORT_ORDER["ASC"] = "ASCEND";
    SORT_ORDER["DESC"] = "DESCEND";
})(SORT_ORDER || (SORT_ORDER = {}));
function sortOrganizationName(a, b, order) {
    var A = a.name.toUpperCase();
    var B = b.name.toUpperCase();
    switch (order) {
        case SORT_ORDER.ASC:
            return A < B ? -1 : A > B ? 1 : 0;
        case SORT_ORDER.DESC:
            return A > B ? -1 : A < B ? 1 : 0;
    }
}

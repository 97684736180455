import { PatientListTypeKeys } from './types';
export var setPatientsAction = function (patients) { return ({
    type: PatientListTypeKeys.SET_PATIENTS,
    patients: patients,
}); };
export var setShowAtHomeAction = function (value) { return ({
    type: PatientListTypeKeys.SET_SHOW_AT_HOME,
    value: value,
}); };
export var clearPatientListAction = function () { return ({
    type: PatientListTypeKeys.CLEAR_PATIENT_LIST,
}); };

/**
 *
 * NotFoundContainer
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

function NotFoundContainer() {
  return (
    <div>
      <h1>404</h1>
    </div>
  );
}

NotFoundContainer.propTypes = {};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(NotFoundContainer);

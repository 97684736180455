import React from 'react';
import sv from 'date-fns/locale/sv';
import enGB from 'date-fns/locale/en-GB';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

registerLocale('sv', sv);
registerLocale('en-GB', enGB);

export default class DatePicker extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    placeholderText: PropTypes.string,
    cleared: PropTypes.bool,
  };
  static defaultProps = {
    cleared: true,
  };
  state = {
    selectedDate: null,
  };
  componentDidMount() {
    const input = document.querySelectorAll('.react-datepicker__input-container > input');
    let max = -1;
    for (let i = 0; i < input.length; i++) {
      const newMax = input[i].getAttribute('placeholder').length;
      max = max < newMax ? newMax : max;
    }
    for (let i = 0; i < input.length; i++) {
      input[i].setAttribute('size', max);
    }
  }
  onChangeHandler = (date) => {
    this.setState({ selectedDate: date });
    if (this.props.onChange) {
      this.props.onChange(moment(date));
    }
  };
  componentDidUpdate = (prevProps) => {
    if (this.props.cleared && !prevProps.cleared) {
      this.setState({ selectedDate: null });
    }
  };

  render() {
    const { colorful, placeholderText, ...rest } = this.props;
    const localeLanguage = navigator.language && navigator.language.split('-')[0] === 'en' ? 'en-GB' : 'sv';
    return (
      <Styles colorful={colorful}>
        <ReactDatePicker
          {...rest}
          placeholderText={placeholderText}
          selected={this.state.selectedDate}
          onChange={this.onChangeHandler}
          dateFormat="yyyy-MM-dd"
          locale={localeLanguage}
        />
      </Styles>
    );
  }
}

const Styles = styled.div`
  .react-datepicker {
    border-width: 0;
    /* width: 285px;
    max-height: 330px; */
    font-size: 12px;
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }
  .react-datepicker__header {
    background-color: ${({ theme }) => theme.loblolly};
    border-color: ${({ theme }) => theme.athensGray};
    /* width: 285px; */
  }
  .react-datepicker__navigation {
    top: 4px;
  }
  .react-datepicker__navigation:focus {
    outline: none;
  }
  .react-datepicker__navigation--next {
    border-left-color: #000000;
  }
  .react-datepicker__navigation--previous {
    border-right-color: #000000;
  }
  .react-datepicker__current-month {
    margin: 0 0 5px 0;
    padding: 6px 0;
    font-size: 12px;
    text-transform: capitalize;
    font-weight: 400;
    font-family: helvetica;
    line-height: 11px;
  }
  .react-datepicker__month {
    width: 100%;
    margin: 0;
  }
  .react-datepicker__day {
    margin: 8px;
    padding: 4px;
    height: 25px;
    width: 25px;
    border-radius: 50%;
  }
  .react-datepicker__day-names {
    text-transform: capitalize;
    background-color: #ffffff;
  }
  .react-datepicker__day-name {
    margin: 4px 12px;
    color: ${({ theme }) => theme.loblolly};
  }
  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.dodgerBlue};
  }
  .react-datepicker__day:hover {
    background-color: ${({ theme }) => theme.athensGray};
  }
  .react-datepicker__day--keyboard-selected {
    color: ${({ theme }) => theme.black};
    background-color: ${({ theme }) => theme.athensGray};
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__day--outside-month {
    color: ${({ theme }) => theme.alto};
  }
  .react-datepicker__input-container {
    border-width: 0;
    input {
      height: 100%;
      width: 100%;
      outline: none;
      padding: 6px 10px;
      color: ${({ theme }) => theme.lynch};
      border: 1px solid ${({ theme }) => theme.alto};
      border-radius: 5px;
      box-shadow: 0 3px 5px 1px rgba(55, 74, 106, 0.05);
    }
    input:focus {
      border-color: ${({ theme }) => theme.sanJuan};
    }
  }

  ${({ colorful, theme }) =>
    colorful &&
    css`
      .react-datepicker__input-container {
        border-width: 0;
        input {
          text-align: center;
          font-weight: bold;
          outline: none;
          border: 1.1px solid #bbc4cc;
          border-radius: 3px;
          font-size: 1.2rem;
          background: transparent;
          box-shadow: none;
          color: ${theme.dodgerBlue};
        }
        input::placeholder {
          color: ${theme.darkGrey};
        }
      }
    `}
`;

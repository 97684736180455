var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import IconUpdated from '@resources/img/ikon-uppdaterad-2x.png';
import MonitorIcon from '@resources/img/ikon-tv.svg';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import PatientListItem from '@pages/PatientListPage/PatientList/PatientListItem/PatientListItem';
import { ListHeader, ListHeaderItem } from '@pages/PatientListPage/PatientList/PatientListItem/components/ListHeader';
import { MainContentHeading } from '@pages/PatientListPage/PatientList/PatientListItem/components/MainContentHeading';
import { ModuleType } from '@api/auth/types';
var MonitorPatientList = function (_a) {
    var patients = _a.patients, organization = _a.organization, compact = _a.compact, lastUpdated = _a.lastUpdated, timedOut = _a.timedOut;
    return (_jsxs(Wrapper, { children: [_jsxs(Heading, __assign({ timedOut: timedOut }, { children: [_jsxs("div", __assign({ className: "page-title" }, { children: [_jsx("h1", { children: _jsx(FormattedMessage, __assign({}, messages.header)) }), lastUpdated && (_jsxs("div", __assign({ className: "status" }, { children: [_jsx("img", { src: IconUpdated, className: "statusIcon" }), _jsx("span", { children: _jsx(FormattedMessage, __assign({}, messages.lastUpdated)) }), _jsxs("span", __assign({ className: "statusTime" }, { children: [" ", lastUpdated.format('YYYY-MM-DD   HH:mm:ss')] }))] })))] })), _jsxs(ListHeader, __assign({ compact: compact, className: "headline" }, { children: [_jsx(ListHeaderItem, __assign({ style: { display: 'flex', justifyContent: 'center' }, width: compact ? '174px' : '125px' }, { children: organization.medicalBoardingEnabled && (_jsx("span", { children: _jsx(FormattedMessage, __assign({}, messages.room)) })) })), _jsxs(MainContentHeading, __assign({ compact: compact }, { children: [_jsx(ListHeaderItem, __assign({ width: '80px', style: { marginLeft: '30px' } }, { children: _jsx("span", { children: _jsx(FormattedMessage, __assign({}, messages.name)) }) })), _jsx(ListHeaderItem, __assign({ minWidth: '180px' }, { children: _jsx("span", { children: _jsx(FormattedMessage, __assign({}, messages.patient)) }) })), organization.modules
                                        .filter(function (m) { return m.type === 'CHECKLIST'; })
                                        .map(function (o, i) { return (_jsx(ListHeaderItem, __assign({ width: '80px' }, { children: _jsx("span", { children: _jsx(FormattedMessage, __assign({}, messages.checklist)) }) }), "checklist_header_".concat(i))); }), organization.diagnosisEnabled && (_jsx(ListHeaderItem, __assign({ width: '100px' }, { children: _jsx("span", { children: _jsx(FormattedMessage, __assign({}, messages.diagnosis)) }) })))] })), organization.modules
                                .filter(function (m) { return m.type === ModuleType.SCALE; })
                                .map(function (module) {
                                var _a;
                                return (_jsx(ListHeaderItem, __assign({ width: compact ? '160px' : '104px', style: { marginLeft: '5px' } }, { children: _jsx("span", { children: ((_a = module.templates[0].scaleTemplate) === null || _a === void 0 ? void 0 : _a.scaleType) === 'NRS_NAUSEA' ? (_jsx(FormattedMessage, __assign({}, messages.nausea))) : (_jsx(FormattedMessage, __assign({}, messages.pain))) }) }), "scale_".concat(module.id)));
                            })] }))] })), patients.length === 0 ? (_jsxs(NoPatients, { children: [_jsx("img", { src: MonitorIcon }), _jsx("p", { children: _jsx(FormattedMessage, __assign({}, messages.noPatients)) })] })) : (_jsx("div", { children: patients.map(function (patient) { return (_jsx(PatientListItem, { patient: patient, compact: compact, organization: organization }, patient.id)); }) }))] }));
};
export default MonitorPatientList;
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-top: 3rem;\n"], ["\n  padding-top: 3rem;\n"])));
var Heading = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  text-align: left;\n  margin-top: 10px;\n  margin-bottom: 0.67rem;\n\n  .page-title {\n    display: flex;\n    justify-content: space-between;\n    align-items: flex-end;\n\n    margin-bottom: 19px;\n\n    h1 {\n      margin: 0;\n      display: inline-block;\n      font-family: 'Open Sans';\n      font-size: 32px;\n      font-weight: bold;\n      line-height: 43px;\n    }\n    .status {\n      font-size: 1.1rem;\n      color: ", ";\n    }\n    .statusIcon {\n      width: 10px;\n      height: 10px;\n      margin-right: 5px;\n    }\n    .statusTime {\n      font-weight: 600;\n    }\n  }\n"], ["\n  text-align: left;\n  margin-top: 10px;\n  margin-bottom: 0.67rem;\n\n  .page-title {\n    display: flex;\n    justify-content: space-between;\n    align-items: flex-end;\n\n    margin-bottom: 19px;\n\n    h1 {\n      margin: 0;\n      display: inline-block;\n      font-family: 'Open Sans';\n      font-size: 32px;\n      font-weight: bold;\n      line-height: 43px;\n    }\n    .status {\n      font-size: 1.1rem;\n      color: ", ";\n    }\n    .statusIcon {\n      width: 10px;\n      height: 10px;\n      margin-right: 5px;\n    }\n    .statusTime {\n      font-weight: 600;\n    }\n  }\n"])), function (_a) {
    var timedOut = _a.timedOut;
    return (timedOut ? 'red' : 'slategray');
});
var NoPatients = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 17vh;\n  text-align: center;\n  p {\n    margin-top: 45px;\n    font-family: Open Sans;\n    font-size: 32px;\n    color: slategray;\n    font-weight: 600;\n    text-align: center;\n  }\n"], ["\n  margin-top: 17vh;\n  text-align: center;\n  p {\n    margin-top: 45px;\n    font-family: Open Sans;\n    font-size: 32px;\n    color: slategray;\n    font-weight: 600;\n    text-align: center;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;

export const CLEAR_STATE = 'CLEAR_STATE';

export const ON_PATIENT_UPDATE = 'ON_PATIENT_UPDATE';
export const ON_PATIENT_CREATE = 'ON_PATIENT_CREATE';
export const ON_PATIENT_DELETE = 'ON_PATIENT_DELETE';
export const ON_PERSONNEL_UPDATE = 'ON_PERSONNEL_UPDATE';
export const ON_PERSONNEL_CREATE = 'ON_PERSONNEL_CREATE';

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';

export const CHANGE_LOCALE = 'app/LanguageToggle/CHANGE_LOCALE';

export const UPDATE_LOADING = 'UPDATE_LOADING';
export const SET_VISIBLE_USER_LIST = 'SET_VISIBLE_USER_LIST';
export const LOADED_CALENDAR_PATIENTS = 'LOADED_CALENDAR_PATIENTS';
export const LOADED_CALENDAR_PERSONNEL = 'LOADED_CALENDAR_PERSONNEL';
export const LOADED_CALENDAR_LOCATIONS = 'LOADED_CALENDAR_LOCATIONS';
export const LOADED_CALENDAR_CATEGORIES = 'LOADED_CALENDAR_CATEGORIES';
export const LOADED_CALENDAR_DRAFTS = 'LOADED_CALENDAR_DRAFTS';
export const DRAFTS_BOOKED = 'DRAFTS_BOOKED';
export const ADDED_CALENDAR_USER = 'ADDED_CALENDAR_USER';
export const UPDATED_CALENDAR_USER = 'UPDATED_CALENDAR_USER';
export const ADDED_CALENDAR_EVENT = 'ADDED_CALENDAR_EVENT';
export const UPDATED_CALENDAR_EVENT = 'UPDATED_CALENDAR_EVENT';
export const DELETED_CALENDAR_EVENT = 'DELETED_CALENDAR_EVENT';
export const GET_CALENDAR_EVENT = 'GET_CALENDAR_EVENT';
export const EDIT_USER = 'EDIT_USER';
export const CANCEL_EDIT_USER = 'CANCEL_EDIT_USER';
export const EDIT_EVENT = 'EDIT_EVENT';
export const CANCEL_EDIT_EVENT = 'CANCEL_EDIT_EVENT';
export const SELECT_DRAFTS = 'SELECT_DRAFTS';
export const UNSELECT_DRAFTS = 'UNSELECT_DRAFTS';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const LOADED_EVENTS = 'LOADED_EVENTS';
export const FOCUS_USER = 'FOCUS_USER';

export const LOAD_ORGANIZATIONS = 'LOAD_ORGANIZATIONS';
export const SELECT_ORGANIZATION = 'SELECT_ORGANIZATION';
export const CLEAR_SELECTED_ORGANIZATION = 'CLEAR_SELECTED_ORGANIZATION';
export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION';
export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION';
export const EDIT_ORGANIZATION = 'EDIT_ORGANIZATION';

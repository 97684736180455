import React from 'react';

export const PatientIcon = ({ width, height, ml, mr }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 19 25" style={{ marginRight: mr, marginLeft: ml }}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#1C3A54" transform="translate(-2)">
        <path d="M9.204 2.5a3.765 3.765 0 013.55 3.846 3.5 3.5 0 01-6.97-.638A3.479 3.479 0 019.204 2.5M11.5 13.22c4.122 0 7 3.205 7 7.08v2.67a1.25 1.25 0 01-2.5 0V20.3c0-2.518-1.724-4.58-4.5-4.58H7c-2.776 0-4.5 2.062-4.5 4.58V23A1.25 1.25 0 110 23v-2.7c0-3.936 2.813-7.08 7-7.08zM9.25 0a6.259 6.259 0 016 6.5 6 6 0 11-11.958-1A6 6 0 019.25 0z"></path>
      </g>
    </g>
  </svg>
);

export const PersonnelIcon = ({ width, height, ml, mr }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 21" style={{ marginRight: mr, marginLeft: ml }}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#1C3A54" transform="translate(-2 -2)">
        <path
          d="M8.643 13.748c3.097 0 5.26 2.235 5.26 5.437a.939.939 0 01-1.878 0c0-1.722-.889-3.562-3.382-3.562H5.26c-2.494 0-3.382 1.84-3.382 3.562a.939.939 0 01-1.879 0c0-3.252 2.114-5.437 5.26-5.437zm5.26-4.5c3.098 0 5.261 2.235 5.261 5.437a.939.939 0 01-1.879 0c0-1.722-.888-3.562-3.382-3.562h-.716c.238-.601.398-1.23.473-1.875zM6.951 3.436a4.698 4.698 0 014.51 4.875 4.506 4.506 0 01-4.87 4.109 4.503 4.503 0 01-4.117-4.86A4.505 4.505 0 016.95 3.437zM6.917 5.31a2.612 2.612 0 00-2.57 2.406 2.602 2.602 0 00.612 1.913 2.632 2.632 0 004.626-1.435 2.826 2.826 0 00-2.668-2.884zM13.776 0a3.918 3.918 0 013.757 4.065c-.168 1.993-1.871 3.474-3.85 3.418a6.836 6.836 0 00-.472-1.952 1.874 1.874 0 001.79-.348 1.86 1.86 0 00.656-1.234 2.045 2.045 0 00-1.917-2.073 1.86 1.86 0 00-1.806 1.618A7.015 7.015 0 0010.377 2.2 3.75 3.75 0 0113.776 0z"
          transform="translate(2 2)"
        ></path>
      </g>
    </g>
  </svg>
);

export const CategoryIcon = ({ width, height, ml, mr }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 28 27" style={{ marginRight: mr, marginLeft: ml }}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        fill="#1C3A54"
        d="M11.445.014a1.25 1.25 0 01.353 2.475A10.746 10.746 0 004.67 6.704a10.746 10.746 0 00-2.06 8.022 10.75 10.75 0 004.216 7.128 10.772 10.772 0 008.022 2.059 10.8 10.8 0 008.648-7.027 1.25 1.25 0 112.35.851 13.295 13.295 0 01-10.646 8.65c-.636.09-1.273.136-1.905.136-2.864 0-5.64-.92-7.97-2.67a13.227 13.227 0 01-5.19-8.775A13.23 13.23 0 012.67 5.204a13.23 13.23 0 018.775-5.19zm8.367 1.99a1.23 1.23 0 011.12.57l4.08 6.327h1.77a1.25 1.25 0 110 2.5h-2.45c-.426 0-.82-.216-1.05-.573l-3.263-5.057L14.1 17.62a1.25 1.25 0 01-1.07.691l-.048.001c-.439 0-.846-.23-1.072-.608l-3.087-5.152h-1.59a1.25 1.25 0 110-2.5h2.3c.438 0 .845.23 1.071.607l2.28 3.805 5.88-11.77c.2-.403.601-.666 1.05-.69z"
      ></path>
    </g>
  </svg>
);

export const RoomIcon = ({ width, height, ml, mr }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 27 25" style={{ marginRight: mr, marginLeft: ml }}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#1C3A54" transform="translate(-2 -4)">
        <path
          d="M10.251 22.449h6.55l-1.973-5.173.909-.552a4.314 4.314 0 002.093-3.714 4.338 4.338 0 00-4.33-4.337 4.339 4.339 0 00-4.33 4.337c0 1.537.786 2.928 2.103 3.72l.908.548-1.93 5.171zM13.5 6.122c3.794 0 6.878 3.09 6.878 6.888a6.836 6.836 0 01-2.441 5.267l1.376 3.608A2.3 2.3 0 0117.17 25H9.885a2.296 2.296 0 01-2.147-3.102l1.346-3.608a6.942 6.942 0 01-2.462-5.28c0-3.798 3.085-6.888 6.877-6.888zM13.5 0C20.944 0 27 6.065 27 13.52a1.275 1.275 0 11-2.547 0c0-6.049-4.913-10.969-10.953-10.969-6.04 0-10.953 4.92-10.953 10.97 0 .703-.57 1.275-1.273 1.275A1.275 1.275 0 010 13.52C0 6.065 6.056 0 13.5 0z"
          transform="translate(2 4)"
        ></path>
      </g>
    </g>
  </svg>
);

export const TimeIcon = ({ width, height, ml, mr }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 27 27" style={{ marginRight: mr, marginLeft: ml }}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#1C3A54" transform="translate(-13 -12)">
        <path
          d="M13.25 0C20.556 0 26.5 5.944 26.5 13.25S20.556 26.5 13.25 26.5 0 20.556 0 13.25c0-.825.077-1.652.228-2.458a1.25 1.25 0 012.457.461A10.832 10.832 0 002.5 13.25C2.5 19.178 7.322 24 13.25 24S24 19.178 24 13.25 19.178 2.5 13.25 2.5a1.25 1.25 0 010-2.5zm-.2 6c.69 0 1.25.56 1.25 1.25v6.375l3.5 2.625a1.25 1.25 0 01-1.5 2l-4-3c-.024-.018-.035-.045-.058-.064a1.357 1.357 0 01-.171-.192c-.046-.062-.093-.121-.127-.19-.036-.07-.055-.143-.076-.219a1.18 1.18 0 01-.051-.25c-.002-.029-.017-.055-.017-.085v-7c0-.69.56-1.25 1.25-1.25zM8.429 2.433a1.25 1.25 0 01-.764 1.594c-.008.004-.096.044-.313.198l-.166.12c-.154.115-.353.27-.604.479-.784.651-1.14 1.022-1.301 1.218a1.246 1.246 0 01-1.759.175 1.25 1.25 0 01-.174-1.759c.339-.415.874-.924 1.635-1.556.85-.707 1.404-1.076 1.852-1.234a1.25 1.25 0 011.594.765z"
          transform="translate(13 12)"
        ></path>
      </g>
    </g>
  </svg>
);

export const QuestionIcon = ({ width, height, ml, mr, mt }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 22 22"
    style={{ marginRight: mr, marginLeft: ml, marginTop: mt, display: 'inline-block', verticalAlign: 'middle' }}
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#1C3A54" transform="translate(-7 -3)">
        <g transform="translate(7.034 7.034)">
          <path d="M.91 5.055a9 9 0 1116.18 7.89A9 9 0 01.91 5.055zm7.795 6.73c-.572 0-1.036.478-1.036 1.09 0 .611.464 1.09 1.036 1.09.578 0 1.042-.479 1.042-1.09 0-.612-.464-1.09-1.042-1.09zm.241-8.82c-1.366 0-2.383.591-2.79 1.508-.127.264-.19.528-.19.827 0 .528.311.868.813.868.388 0 .674-.194.833-.639.203-.618.623-.952 1.245-.952.7 0 1.183.473 1.183 1.153 0 .64-.248.987-1.068 1.522-.75.48-1.138 1.732-1.138 2.552v.097c0 .57.318.973.858.973.451 0 .7-.27.795-.736.082-.584.273-1.594 1.118-2.129.897-.577 1.36-1.292 1.36-2.341 0-1.619-1.213-2.702-3.019-2.702z"></path>
        </g>
      </g>
    </g>
  </svg>
);

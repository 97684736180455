var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { selectOrganization, jwtLogin, sithsLogin } from '@api/auth/actions';
import { pushErrorAction } from '@hooks/useError/actions';
import Button from '@components/Button';
import useErrorAction from '@hooks/useError/useErrorAction';
import OrganizationModal from '@pages/LoginPage/OrganizationModal';
import globalMessages from '@src/globalMessages';
import messages from '../messages';
var SithsLoginComponent = function (_a) {
    var handleLoginResponse = _a.handleLoginResponse;
    var errorDispatch = useErrorAction();
    var formatMessage = useIntl().formatMessage;
    var _b = useState(undefined), organizations = _b[0], setOrganizations = _b[1];
    var _c = useState(undefined), jwt = _c[0], setJwt = _c[1];
    var handleSithsLogin = function () {
        sithsLogin()
            .then(function (sithsResponse) {
            setJwt(sithsResponse.jwt);
            jwtLogin(sithsResponse.jwt)
                .then(function (response) {
                var data = response.data;
                if (data.organizationIds) {
                    setOrganizations(data.organizationIds);
                }
                else {
                    handleLoginResponse(data);
                }
            })
                .catch(function (err) {
                if (err.response && err.response.status === 401) {
                    errorDispatch(pushErrorAction({
                        title: formatMessage(messages.failedLogin),
                        message: formatMessage(messages.unauthorizedErrorMessage),
                    }));
                }
                else {
                    throwUnknownError();
                }
            });
        })
            .catch(function () {
            errorDispatch(pushErrorAction({
                title: formatMessage(messages.failedLogin),
                message: formatMessage(messages.sithsCardLoginErrorMessage),
            }));
        });
    };
    var handleSelectOrganization = function (organizationId) {
        if (jwt) {
            selectOrganization(jwt, organizationId)
                .then(function (response) {
                handleLoginResponse(response);
            })
                .catch(function () {
                throwUnknownError();
            });
        }
        else {
            throwUnknownError();
        }
    };
    var throwUnknownError = function () {
        errorDispatch(pushErrorAction({
            title: formatMessage(globalMessages.unknownError),
            message: formatMessage(globalMessages.resetBrowserMessage),
        }));
    };
    return (_jsxs("div", __assign({ className: "login-section" }, { children: [_jsx("h3", { children: formatMessage(messages.sithsCardLogin) }), _jsx(LoginButton, __assign({ primary: true, onClick: handleSithsLogin }, { children: formatMessage(globalMessages.login) })), organizations && (_jsx(OrganizationModal, { dismiss: function () { return setOrganizations(undefined); }, organizations: organizations, selectOrganization: handleSelectOrganization }))] })));
};
var LoginButton = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 18px;\n  margin-bottom: 95px;\n  line-height: 24px;\n  height: 48px;\n  width: 234px;\n  padding: 11px 80px;\n  font-weight: 600;\n  margin-top: 24px;\n  letter-spacing: -0.39px;\n  box-shadow: 0 2px 4px 1px rgba(55, 74, 106, 0.2);\n"], ["\n  font-size: 18px;\n  margin-bottom: 95px;\n  line-height: 24px;\n  height: 48px;\n  width: 234px;\n  padding: 11px 80px;\n  font-weight: 600;\n  margin-top: 24px;\n  letter-spacing: -0.39px;\n  box-shadow: 0 2px 4px 1px rgba(55, 74, 106, 0.2);\n"])));
export default SithsLoginComponent;
var templateObject_1;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useIntl } from 'react-intl';
import usePatientDetailState from '@hooks/usePatientDetail/usePatientDetailState';
import messages from '@src/pages/PatientListPage/PatientList/PatientListItem/messages';
var PatientInfo = function (_a) {
    var patient = usePatientDetailState().patient;
    var formatMessage = useIntl().formatMessage;
    var patientAge = patient && patient.age && patient.age >= 1
        ? patient.age + ' ' + formatMessage(messages.ageSubtitle)
        : (patient === null || patient === void 0 ? void 0 : patient.age) + ' ' + formatMessage(messages.ageSubtitleSingular);
    return (_jsx(_Fragment, { children: patient && Object.getOwnPropertyNames(patient).length > 0 && (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "name" }, { children: _jsxs("span", { children: [patient.name, " ", patient.surName] }) })), _jsx("div", __assign({ className: "ssn" }, { children: _jsx("span", { children: patient.ssn }) })), _jsx("div", __assign({ className: "genderAge" }, { children: _jsxs("span", { children: [patient.gender === 'MALE' ? formatMessage(messages.maleSubtitle) : formatMessage(messages.femaleSubtitle), ", ", patientAge] }) }))] })) }));
};
export default PatientInfo;

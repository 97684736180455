import React, { PureComponent } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';
import svLocale from '@fullcalendar/core/locales/sv';
import enLocale from '@fullcalendar/core/locales/en-gb';
import styled from '@emotion/styled';

import { QuestionIcon } from '../../svg/CustomIcons';
import CalendarHelpModal from '../../Calendar/CalendarHelpModal';
import messages from '../EventPane/messages';
import './calendarwidget.css';
import { FormattedMessage } from 'react-intl';

export default class CalendarWidget extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showCalendarHelpModal: false,
    };
  }

  openCalendarHelpModal = () => {
    this.setState({ showCalendarHelpModal: true });
  };

  closeCalendarHelpModal = () => {
    this.setState({ showCalendarHelpModal: false });
  };

  settings = {
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, momentPlugin],
    initialView: 'timeGridWeek',
    weekends: true,
    firstDay: 1,
    eventTimeFormat: {
      hour: 'numeric',
      minute: '2-digit',
      meridiem: false,
      hour12: false,
    },
    slotLabelFormat: {
      hour: 'numeric',
      minute: '2-digit',
      meridiem: false,
      hour12: false,
    },
    eventColor: '#53a0fc',
    eventTextColor: '#ffffff',
    scrollTime: '07:00:00',
    height: '100%',
    allDayText: '',
    allDaySlot: false,
    slotDuration: '00:30:00',
    slotLabelInterval: '01:00:00',
    titleFormat: { year: 'numeric', month: 'short' },
    locale: navigator.language.split('-')[0] === 'en' ? enLocale : svLocale,
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'openInfoModalBtn dayGridMonth,timeGridWeek,timeGridDay',
    },
    eventContent: renderEventContent, // custom render function

    dayMaxEvents: true,
  };

  render() {
    return (
      <>
        <FullCalendar
          {...{
            ...this.settings,
            ...this.props.settings,
            customButtons: {
              openInfoModalBtn: {
                text: <QuestionIcon width={'20px'} height={'20px'} ml={'2px'} mr={'0px'} mt={'-8px'} />,
                click: this.openCalendarHelpModal,
              },
            },
          }}
        />
        {this.state.showCalendarHelpModal && <CalendarHelpModal dismiss={this.closeCalendarHelpModal} />}
      </>
    );
  }
}

function renderEventContent(eventInfo) {
  return (
    <EventContainer focused={eventInfo.event.extendedProps.focused} selected={eventInfo.event.extendedProps.selected}>
      <EventText>{eventInfo.timeText}</EventText>
      <EventText weight={'bold'}>
        {eventInfo.event.title !== 'undefined' ? eventInfo.event.title : <FormattedMessage {...messages.headerNewBooking} />}
      </EventText>
      <EventText>{eventInfo.event.extendedProps.location}</EventText>
      <EventText style={{ right: '4px', top: '3px' }}>{eventInfo.event.extendedProps.participants}</EventText>
    </EventContainer>
  );
}

const EventContainer = styled.div`
  padding: 2px;
  opacity: ${({ focused }) => (focused ? 1 : 0.5)};

  & > span:last-child {
    position: absolute;
    bottom: 2px;
  }
`;

const EventText = styled.span`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  font-size: 0.8em;
  font-weight: ${({ weight }) => weight};
`;

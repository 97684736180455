var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { updatePatient } from '@api/patient/patient';
import { setPatientAction } from '@hooks/usePatientDetail/actions';
import Modal from 'components/Modal/Modal';
import Button from 'components/Button';
import BedRoomSelect from './BedRoomSelect';
import ModalFooter from 'components/Modal/ModalFooterButtons';
import usePatientDetailAction from '@hooks/usePatientDetail/usePatientDetailAction';
import PatientBox from '@components/PatientBox/PatientBox';
import messages from '@src/components/Boarding/messages';
import globalMessages from '@src/globalMessages';
var BedRoomModal = function (_a) {
    var patient = _a.patient, dismiss = _a.dismiss;
    var dispatch = usePatientDetailAction();
    var formatMessage = useIntl().formatMessage;
    var _b = useState(patient.room), selectedRoom = _b[0], setSelectedRoom = _b[1];
    var _c = useState(patient.bed), selectedBed = _c[0], setSelectedBed = _c[1];
    var _d = useState(false), isValid = _d[0], setValid = _d[1];
    var onRoomChanged = function (room, bed, valid) {
        setSelectedRoom(room);
        setSelectedBed(bed);
        setValid(valid);
    };
    var onSave = function () {
        var data = {
            ssn: patient.ssn,
            name: patient.name,
            surName: patient.surName,
            gender: patient.gender,
            diagnosis: patient.diagnosis,
            roomId: selectedRoom === null || selectedRoom === void 0 ? void 0 : selectedRoom.id,
            bedId: selectedBed === null || selectedBed === void 0 ? void 0 : selectedBed.id,
            modules: [],
        };
        updatePatient(patient.id, data).then(function (value) {
            dispatch(setPatientAction(value));
            dismiss();
        });
    };
    return (_jsx(Modal, __assign({ maxWidth: "845px", title: formatMessage(messages.modalHeadline), dismiss: dismiss, tainted: selectedRoom !== patient.room || selectedBed !== patient.bed }, { children: _jsxs(StyledContent, { children: [_jsxs("div", { children: [_jsx(PatientBox, { className: "patientBox", patient: patient }), _jsx("p", __assign({ id: "bedRoomModalRoomTitle", className: "headline" }, { children: formatMessage(messages.room) })), _jsx("div", __assign({ className: "selectRoomBox" }, { children: _jsx(BedRoomSelect, { room: patient.room, bed: patient.bed, onChange: onRoomChanged, validationFailed: false }) }))] }), _jsxs(ModalFooter, { children: [_jsx(Button, __assign({ medium: true, hover: true, onClick: dismiss }, { children: _jsx("span", { children: formatMessage(globalMessages.cancel) }) })), _jsx(Button, __assign({ primary: true, medium: true, hover: true, disabled: !isValid, onClick: onSave }, { children: _jsx("span", { children: formatMessage(globalMessages.save) }) }))] })] }) })));
};
export default BedRoomModal;
var StyledContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  #bedRoomModalRoomTitle {\n    margin: 0;\n    border: 0 solid;\n    width: 100%;\n    border-color: ", ";\n    border-bottom-width: 1px;\n    padding-bottom: 15;\n  }\n  .patientBox {\n    margin: 0 0 31px 0;\n  }\n  .selectRoomBox {\n    margin: 0 0 10px 0;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  #bedRoomModalRoomTitle {\n    margin: 0;\n    border: 0 solid;\n    width: 100%;\n    border-color: ", ";\n    border-bottom-width: 1px;\n    padding-bottom: 15;\n  }\n  .patientBox {\n    margin: 0 0 31px 0;\n  }\n  .selectRoomBox {\n    margin: 0 0 10px 0;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.athensGray;
});
var templateObject_1;

import React, {PureComponent} from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string'
import {applyFilter, loadAll} from '../../actions/calendar';
import Calendar from "components/Calendar/Calendar";
import LoadingIndicatorComponent from "components/Common/LoadingIndicatorComponent/LoadingIndicatorComponent";

class CalendarContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      calendarInitComplete: false,
    };
  }

  componentDidMount()
  {
    this.componentDidMountOrUpdate()
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
    // this.componentDidMountOrUpdate(this.props.state)
  }

  componentDidMountOrUpdate = () => {
    if (!this.state.calendarInitComplete) {
      const value = queryString.parse(this.props.location.search);
      const userId = parseInt(value.userId);
      if (userId) {
        const filter = this.props.calendar.filter;
        const patientIds = [...filter.patientIds, userId];
        const extra = {
          patientIds: new Set(patientIds),
        }
        this.props.applyFilter({
          filter: { ...filter, ...extra },
        });
      }
      this.props.loadAll();
      this.setState({calendarInitComplete: true});
    }
  };

  render() {
    return Object.values(this.props.calendar.loading).some(l => l) ?
      <LoadingIndicatorComponent /> :
      <Calendar />
    ;
  }
}

const mapStateToProps = state => ({
  calendar: state.calendar,
});


const mapDispatchToProps = dispatch => ({
  applyFilter: ({filter, success, fail}) => dispatch(applyFilter({filter, success, fail})),
  loadAll: () => dispatch(loadAll()),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarContainer)

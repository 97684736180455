import { defineMessages } from 'react-intl';
var scope = 'app.pages.Export.ExportPage';
export default defineMessages({
    basicDataLabel: {
        id: "".concat(scope, ".basicDataLabel"),
        defaultMessage: 'Grunddata',
    },
    heading: {
        id: "".concat(scope, ".heading"),
        defaultMessage: 'Historik',
    },
    subHeading: {
        id: "".concat(scope, ".subHeading"),
        defaultMessage: 'Exportera historik från angiven tidsintervall',
    },
    exportButton: {
        id: "".concat(scope, ".exportButton"),
        defaultMessage: 'Exportera',
    },
    medicationPlanLabel: {
        id: "".concat(scope, ".medicationPlanLabel"),
        defaultMessage: 'Medicinschema',
    },
    modalHeadline: {
        id: "".concat(scope, ".modalHeadline"),
        defaultMessage: 'Exportera historik',
    },
    modalSubheadline: {
        id: "".concat(scope, ".modalSubheadline"),
        defaultMessage: 'VÄLJ TIDSINTERVALL',
    },
    taskCompleted: {
        id: "".concat(scope, ".taskCompleted"),
        defaultMessage: 'klar',
    },
    intervalPickerStartDatePlaceholder: {
        id: "".concat(scope, ".intervalPickerStartDatePlaceholder"),
        defaultMessage: 'Välj startdatum',
    },
    intervalPickerEndDatePlaceholder: {
        id: "".concat(scope, ".intervalPickerEndDatePlaceholder"),
        defaultMessage: 'Välj slutdatum',
    },
});

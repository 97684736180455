var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ListItem, Box } from '@components/Admin/common/styling/list';
import { deleteOrganization } from '@api/admin/actions';
import { clearOrganizationsAction } from '@hooks/useAdmin/actions';
import IconEdit from '../../../../resources/img/edit.png';
import IconTrash from '../../../../resources/img/trash.png';
import ConfirmationModal from '@components/Modal/ConfirmationModal';
import useAdminAction from '@hooks/useAdmin/useAdminAction';
import CreateOrganizationModal from '@pages/Admin/OrganizationPage/Modal/CreateOrganizationModal';
import globalMessages from '@src/globalMessages';
import messages from '../messages';
var OrganizationListItem = function (_a) {
    var className = _a.className, organization = _a.organization;
    var dispatch = useAdminAction();
    var formatMessage = useIntl().formatMessage;
    var _b = useState(false), showEditOrganizationModal = _b[0], setShowEditOrganizationModal = _b[1];
    var _c = useState(false), showConfirmationModal = _c[0], setShowConfirmationModal = _c[1];
    var onDelete = function () {
        var id = organization.id;
        if (id != undefined) {
            deleteOrganization(id).then(function () {
                dispatch(clearOrganizationsAction());
            });
        }
    };
    return (_jsxs(React.Fragment, { children: [_jsxs(ListItem, __assign({ className: className }, { children: [_jsx(Box, __assign({ className: "left" }, { children: _jsx(Box, __assign({ className: "title-16" }, { children: _jsx("p", { children: organization.name }) })) })), _jsxs(Box, __assign({ className: "right" }, { children: [_jsxs(Box, __assign({ className: "link", onClick: function () { return setShowEditOrganizationModal(true); } }, { children: [_jsx("img", { src: IconEdit }), _jsx("p", { children: formatMessage(globalMessages.edit) })] })), _jsxs(Box, __assign({ className: "link", onClick: function () { return setShowConfirmationModal(true); } }, { children: [_jsx("img", { src: IconTrash }), _jsx("p", { children: formatMessage(globalMessages.remove) })] }))] }))] })), showEditOrganizationModal && _jsx(CreateOrganizationModal, { organizationId: organization.id, dismiss: function () { return setShowEditOrganizationModal(false); } }), showConfirmationModal && (_jsx(ConfirmationModal, { text: formatMessage(messages.confirmationRemoveText) + "".concat(organization.name, " : ").concat(organization.hsaId, "?"), dismiss: function () { return setShowConfirmationModal(false); }, onConfirm: onDelete, title: formatMessage(messages.confirmationRemoveHeader) }))] }));
};
export default OrganizationListItem;

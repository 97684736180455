import { useReducer } from 'react';
import produce from 'immer';
import { AdminTypeKeys } from '@hooks/useAdmin/types';
var initialState = {
    personnel: undefined,
    organizations: undefined,
    checklistTemplates: undefined,
    organization: undefined,
};
var adminReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    return produce(state, function (draft) {
        switch (action.type) {
            case AdminTypeKeys.SET_PERSONNEL:
                draft.personnel = action.personnel;
                return;
            case AdminTypeKeys.CLEAR_PERSONNEL:
                draft.personnel = undefined;
                return;
            case AdminTypeKeys.SET_ORGANISATIONS:
                draft.organizations = action.organizations;
                return;
            case AdminTypeKeys.CLEAR_ORGANISATIONS:
                draft.organizations = undefined;
                return;
            case AdminTypeKeys.SET_ORGANISATION:
                draft.organization = action.organization;
                return;
            case AdminTypeKeys.SET_CHECKLIST_TEMPLATES:
                draft.checklistTemplates = action.checklistTemplates;
                return;
            case AdminTypeKeys.CLEAR_ADMIN:
                return initialState;
        }
    });
};
export var useAdmin = function () {
    var _a = useReducer(adminReducer, initialState), state = _a[0], dispatch = _a[1];
    return [state, dispatch];
};

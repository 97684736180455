/*
 * Login Messages
 *
 * This contains the messages used on the Login page
 *
 */
import { defineMessages } from 'react-intl';
export var scope = 'app.pages.LoginPage';
export var errorScope = 'app.pages.LoginPage.Errors';
export default defineMessages({
    loginPageInfo: {
        id: "".concat(scope, ".loginPageInfo"),
        defaultMessage: 'För att få tillgång till Zengio behöver din verksamhet ett avtal med Chorus.För mer information hör av dig till oss på ',
    },
    sithsCardLogin: {
        id: "".concat(scope, ".sithsCardLogin"),
        defaultMessage: 'Här loggar du som vårdgivare in med ditt SITHS-kort',
    },
    failedLogin: {
        id: "".concat(errorScope, ".failedLogin"),
        defaultMessage: 'Inloggning misslyckades',
    },
    sithsCardLoginErrorMessage: {
        id: "".concat(errorScope, ".sithsCardLoginErrorMessage"),
        defaultMessage: 'Kontrollera att SITHS-kortet sitter i kortläsaren.\nStäng webläsaren och försök igen.',
    },
    unauthorizedErrorMessage: {
        id: "".concat(errorScope, ".authValidationErrorMessage"),
        defaultMessage: 'Du saknar behörighet att använda tjänsten.\nKontakta Chorus support helgfria vardagar 8-17 på telefon 08-518 315 00',
    },
});

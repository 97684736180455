import { defineMessages } from 'react-intl';
export var scope = 'app.pages.Admin.ChecklistPage.Modal';
export var errorScope = 'app.pages.Admin.ChecklistPage.Modal.Errors';
export default defineMessages({
    fieldLabelChecklistName: {
        id: "".concat(scope, ".fieldLabelChecklistName"),
        defaultMessage: 'RUBRIK',
    },
    fieldLabelChecklistDescription: {
        id: "".concat(scope, ".fieldLabelChecklistDescription"),
        defaultMessage: 'BESKRIVNING',
    },
    headerChecklistContent: {
        id: "".concat(scope, ".headerChecklistContent"),
        defaultMessage: 'INNEHÅLL',
    },
    modalHeaderAdd: {
        id: "".concat(scope, ".modalHeaderAdd"),
        defaultMessage: 'Lägg till checklista',
    },
    modalHeaderEdit: {
        id: "".concat(scope, ".modalHeaderEdit"),
        defaultMessage: 'Redigera checklista',
    },
    creationChecklistErrorMessage: {
        id: "".concat(errorScope, ".creationChecklistErrorMessage"),
        defaultMessage: 'Det gick inte att skapa checklistan',
    },
    descriptionChecklistValidationError: {
        id: "".concat(errorScope, ".descriptionChecklistValidationError"),
        defaultMessage: 'Ange beskrivning',
    },
    duplicateChecklistErrorMessage: {
        id: "".concat(errorScope, ".duplicateChecklistErrorMessage"),
        defaultMessage: 'Det finns redan ett utkast på denna checklista, radera utkastet innan du skapar nytt.',
    },
    editChecklistErrorMessage: {
        id: "".concat(errorScope, ".editChecklistErrorMessage"),
        defaultMessage: 'Det gick inte att redigera checklistan',
    },
    missingContentChecklistValidationError: {
        id: "".concat(errorScope, ".missingContentChecklistValidationError"),
        defaultMessage: 'Listan saknar innehåll.',
    },
    missingTextChecklistValidationError: {
        id: "".concat(errorScope, ".missingTextChecklistValidationError"),
        defaultMessage: 'Det saknas text i fältet',
    },
    nameChecklistValidationError: {
        id: "".concat(errorScope, ".nameChecklistValidationError"),
        defaultMessage: 'Ange rubrik',
    },
    text40MaxLengthValidationError: {
        id: "".concat(errorScope, ".text40MaxLengthValidationError"),
        defaultMessage: 'Du kan ange max 40 tecken',
    },
    text250MaxLengthValidationError: {
        id: "".concat(errorScope, ".text250MaxLengthValidationError"),
        defaultMessage: 'Du kan ange max 250 tecken',
    },
});

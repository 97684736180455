import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faUserAlt,
  faMapMarker,
  faStar,
  faAngleDown,
  faAngleUp,
  faQuestion,
  faPencilAlt,
  faTimes,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';

import * as actions from 'constants/actions';
import { applyFilter } from 'actions/calendar';
import { SidePane, SidePaneHeader, SidePaneHeaderIcon } from 'components/Calendar/SidePane/SidePane';
import { PatientIcon, PersonnelIcon, CategoryIcon, RoomIcon } from '../../svg/CustomIcons';
import eventPaneMessages from '../EventPane/messages';
import messages from './messages';
import './userpane.css';

class UserPane extends PureComponent {
  selectUser = (user) => {
    const filter = this.props.calendar.filter;
    const extra = {};
    switch (user.type) {
      case 'PATIENT':
        extra.patientIds = new Set([...filter.patientIds, user.id]);
        break;
      case 'PERSONNEL':
        extra.personnelIds = new Set([...filter.personnelIds, user.id]);
        break;
      case 'LOCATION':
        extra.locationIds = new Set([...filter.locationIds, user.id]);
        break;
      case 'CATEGORY':
        extra.categoryIds = new Set([...filter.categoryIds, user.id]);
        break;
    }
    this.props.applyFilter({
      filter: { ...filter, ...extra },
      success: () => {
        this.setFocusedUser(user);
      },
    });
  };

  setFocusedUser = (user) => {
    const focused = this.props.calendar.focused;
    if (focused?.id === user.id) {
      return;
    } else {
      this.props.dispatch({
        type: actions.FOCUS_USER,
        data: {
          id: user.id,
          type: user.type,
        },
      });
    }
  };

  unselectUser = (user) => {
    const filter = this.props.calendar.filter;
    const extra = {};
    switch (user.type) {
      case 'PATIENT':
        const patientIds = new Set(filter.patientIds);
        patientIds.delete(user.id);
        extra.patientIds = patientIds;
        break;
      case 'PERSONNEL':
        const personnelIds = new Set(filter.personnelIds);
        personnelIds.delete(user.id);
        extra.personnelIds = personnelIds;
        break;
      case 'LOCATION':
        const locationIds = new Set(filter.locationIds);
        locationIds.delete(user.id);
        extra.locationIds = locationIds;
        break;
      case 'CATEGORY':
        const categoryIds = new Set(filter.categoryIds);
        categoryIds.delete(user.id);
        extra.categoryIds = categoryIds;
        break;
    }
    this.props.applyFilter({
      filter: { ...filter, ...extra },
    });
  };

  resetFilter = () => {
    const filter = this.props.calendar.filter;
    const extra = {};
    extra.categoryIds = new Set();
    extra.locationIds = new Set();
    extra.patientIds = new Set();
    extra.personnelIds = new Set();
    this.props.applyFilter({
      filter: { ...filter, ...extra },
    });
  };

  renderHeader = (title) => (
    <SidePaneHeader>
      <span>{title}</span>
      <SidePaneHeaderIcon
        onClick={() =>
          this.props.dispatch({
            type: actions.EDIT_USER,
            data: {},
          })
        }
      >
        <FontAwesomeIcon icon={faPlus} style={{ cursor: 'pointer' }} />
      </SidePaneHeaderIcon>
      <SidePaneHeaderIcon onClick={this.resetFilter}>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: 'pointer' }} />
      </SidePaneHeaderIcon>
    </SidePaneHeader>
  );

  render() {
    //TODO Fix below
    const filter = this.props.calendar.filter;
    const focused = this.props.calendar.focused;
    const focusedUserId = focused?.id;
    const selectedPatients = this.props.calendar.patients.filter((user) => filter.patientIds.has(user.id));
    const selectedPersonnel = this.props.calendar.personnel.filter((user) => filter.personnelIds.has(user.id));
    const selectedLocations = this.props.calendar.locations.filter((user) => filter.locationIds.has(user.id));
    const selectedCategory = this.props.calendar.categories.filter((user) => filter.categoryIds.has(user.id));
    const selectedUsers = selectedPatients.concat(selectedPersonnel).concat(selectedLocations).concat(selectedCategory);
    return (
      <SidePane
        header={this.renderHeader(
          selectedUsers.length > 0 ? <FormattedMessage {...messages.sidePaneHeaderSelected} /> : <FormattedMessage {...messages.sidePaneHeader} />,
        )}
      >
        <div className={'user-pane-content'}>
          {selectedUsers.length > 0 && (
            <div className={`user-list`}>
              {selectedUsers.map((user) => (
                <SelectedUserCell
                  user={user}
                  focused={user.id === focusedUserId}
                  key={user.id}
                  dispatch={this.props.dispatch}
                  unselect={this.unselectUser}
                  setFocusedUser={this.setFocusedUser}
                />
              ))}
            </div>
          )}
          <div
            className={`user-list-header`}
            onClick={() =>
              this.props.dispatch({
                type: actions.SET_VISIBLE_USER_LIST,
                data: { key: 'patients' },
              })
            }
          >
            <PatientIcon width={'18px'} height={'18px'} mr={'12px'} ml={'16px'} />
            <p>
              <FormattedMessage {...eventPaneMessages.fieldLabelPatients} />
            </p>
            <FontAwesomeIcon className="angle-down-icon" icon={this.props.calendar.visibleUserList === 'patients' ? faAngleUp : faAngleDown} />
          </div>
          {this.props.calendar.visibleUserList === 'patients' && (
            <div className={`user-list`}>
              {this.props.calendar.patients.map((user) => (
                <UserCell user={user} key={user.id} dispatch={this.props.dispatch} select={this.selectUser} focusedUserId={false} />
              ))}
            </div>
          )}
          <div
            className={`user-list-header`}
            onClick={() =>
              this.props.dispatch({
                type: actions.SET_VISIBLE_USER_LIST,
                data: { key: 'personnel' },
              })
            }
          >
            <PersonnelIcon width={'18px'} height={'18px'} mr={'12px'} ml={'16px'} />
            <p>
              <FormattedMessage {...eventPaneMessages.fieldLabelResources} />
            </p>
            <FontAwesomeIcon className="angle-down-icon" icon={this.props.calendar.visibleUserList === 'personnel' ? faAngleUp : faAngleDown} />
          </div>
          {this.props.calendar.visibleUserList === 'personnel' && (
            <div className={`user-list`}>
              {this.props.calendar.personnel.map((user) => (
                <UserCell user={user} key={user.id} dispatch={this.props.dispatch} select={this.selectUser} />
              ))}
            </div>
          )}
          <div
            className={`user-list-header`}
            onClick={() =>
              this.props.dispatch({
                type: actions.SET_VISIBLE_USER_LIST,
                data: { key: 'categories' },
              })
            }
          >
            <CategoryIcon width={'18px'} height={'18px'} mr={'12px'} ml={'16px'} />
            <p>
              <FormattedMessage {...eventPaneMessages.fieldLabelActivity} />
            </p>
            <FontAwesomeIcon className="angle-down-icon" icon={this.props.calendar.visibleUserList === 'categories' ? faAngleUp : faAngleDown} />
          </div>
          {this.props.calendar.visibleUserList === 'categories' && (
            <div className={`user-list`}>
              {this.props.calendar.categories.map((user) => (
                <UserCell user={user} key={user.id} dispatch={this.props.dispatch} select={this.selectUser} />
              ))}
            </div>
          )}
          <div
            className={`user-list-header`}
            onClick={() =>
              this.props.dispatch({
                type: actions.SET_VISIBLE_USER_LIST,
                data: { key: 'locations' },
              })
            }
          >
            <RoomIcon width={'18px'} height={'18px'} mr={'12px'} ml={'16px'} />
            <p>
              <FormattedMessage {...eventPaneMessages.fieldLabelLocation} />
            </p>
            <FontAwesomeIcon className="angle-down-icon" icon={this.props.calendar.visibleUserList === 'locations' ? faAngleUp : faAngleDown} />
          </div>
          {this.props.calendar.visibleUserList === 'locations' && (
            <div className={`user-list`}>
              {this.props.calendar.locations.map((user) => (
                <UserCell user={user} key={user.id} dispatch={this.props.dispatch} select={this.selectUser} />
              ))}
            </div>
          )}
        </div>
      </SidePane>
    );
  }
}

const mapStateToProps = (state) => ({
  calendar: state.calendar,
});

const mapDispatchToProps = (dispatch) => ({
  applyFilter: ({ filter, success, fail }) => dispatch(applyFilter({ filter, success, fail })),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPane);

const UserCell = ({ user, dispatch, select, focusedUserId }) => (
  <div className={`user-item${focusedUserId === user.id ? ' user-selected-disabled' : ''}`} title={user.displayName}>
    <div className="color" style={{ color: user.color || '#2196f3' }}>
      <FontAwesomeIcon className="user-item-icon" icon={iconForUser(user.type)} />
    </div>
    <div className="user-name" onClick={() => select(user)}>
      <span>{user.displayName}</span>
    </div>
    <div
      className="user-edit show-on-hover"
      onClick={() =>
        dispatch({
          type: actions.EDIT_USER,
          data: user,
        })
      }
    >
      <FontAwesomeIcon className="user-edit-icon" icon={faPencilAlt} />
    </div>
  </div>
);

const SelectedUserCell = ({ user, focused, unselect, setFocusedUser }) => (
  <div
    className={`user-item user-item__selected${focused ? ' user-item__selected__focused' : ''}`}
    title={user.displayName}
    onMouseEnter={() => setFocusedUser(user)}
  >
    <div className="color" style={{ color: user.color || '#2196f3' }}>
      <FontAwesomeIcon className="user-item-icon" icon={iconForUser(user.type)} />
    </div>
    <div className="user-name">
      <span>{user.displayName}</span>
    </div>
    <div className="user-edit" onClick={() => unselect(user)}>
      <FontAwesomeIcon className="user-edit-icon" icon={faTimes} />
    </div>
  </div>
);

const iconForUser = (userType) => {
  switch (userType) {
    case 'PATIENT':
      return faUserAlt;
    case 'PERSONNEL':
      return faUser;
    case 'LOCATION':
      return faMapMarker;
    case 'CATEGORY':
      return faStar;
    default:
      return faQuestion;
  }
};

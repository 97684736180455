import * as actions from '../constants/actions';

const initialState = {
  organizations: [],
  selectedOrganization: null,
};

export default function AdminReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CLEAR_STATE:
      return initialState;

    case actions.LOAD_ORGANIZATIONS: {
      return {
        ...state,
        organizations: action.data,
      };
    }

    case actions.CREATE_ORGANIZATION: {
      return {
        ...state,
      };
    }

    case actions.DELETE_ORGANIZATION: {
      return {
        ...state,
      };
    }

    case actions.EDIT_ORGANIZATION: {
      return {
        ...state,
      };
    }

    case actions.SELECT_ORGANIZATION: {
      return {
        ...state,
        selectedOrganization: action.data,
      };
    }

    case actions.CLEAR_SELECTED_ORGANIZATION: {
      return {
        ...state,
        selectedOrganization: null,
      };
    }

    default: {
      return state;
    }
  }
}

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import Select from 'react-select';
import { theme } from 'constants/styling';
import globalMessages from '@src/globalMessages';
import messages from '@src/pages/PatientListPage/PatientList/PatientFormModal/messages';
var MedicineScheduleSelect = function (_a) {
    var _b;
    var module = _a.module, moduleTemplate = _a.moduleTemplate, onChange = _a.onChange, validationFailed = _a.validationFailed;
    var formatMessage = useIntl().formatMessage;
    var _c = useState((_b = moduleTemplate.templates.find(function (o) { var _a, _b; return ((_a = o.scheduleTemplate) === null || _a === void 0 ? void 0 : _a.id) === ((_b = module === null || module === void 0 ? void 0 : module.schedule) === null || _b === void 0 ? void 0 : _b.id); })) === null || _b === void 0 ? void 0 : _b.id), selectedScheduleId = _c[0], setSelectedScheduleId = _c[1];
    var getMedicineScheduleOptions = function () {
        var options = [];
        if (moduleTemplate.templates) {
            options = moduleTemplate.templates.map(function (s) {
                var _a, _b, _c;
                return ({
                    value: s.id,
                    label: formatMessage({
                        id: "app.pages.PatientFormModal.".concat((_a = s.scheduleTemplate) === null || _a === void 0 ? void 0 : _a.name.replace(/\s/g, '').toLocaleLowerCase()),
                        defaultMessage: (_b = s.scheduleTemplate) === null || _b === void 0 ? void 0 : _b.name,
                    }) || '',
                    version: ((_c = s.scheduleTemplate) === null || _c === void 0 ? void 0 : _c.version) || 0,
                    isAvailable: true,
                });
            });
        }
        //if the patient schedule isn't included in the organizaiton module listings it has been removed or updated
        //it is still valid for the patients who already have it set but can no longer be applied for new or existing patients.
        var patientSchedule = module === null || module === void 0 ? void 0 : module.schedule;
        if (patientSchedule && !moduleTemplate.templates.some(function (o) { var _a; return ((_a = o.scheduleTemplate) === null || _a === void 0 ? void 0 : _a.id) === patientSchedule.id; })) {
            options.push({
                value: patientSchedule.id,
                label: "".concat(patientSchedule.name, " - v.").concat(patientSchedule.version),
                version: patientSchedule.version,
                isAvailable: false,
            });
        }
        return options;
    };
    var options = getMedicineScheduleOptions();
    var isAvailableOrNull = true; //this.state.moduleTemplateSelect.selected ? this.state.moduleTemplateSelect.selected.isAvailable : true;
    return (_jsx(StyledContent, __assign({ selectedIsAvailable: isAvailableOrNull }, { children: _jsxs("div", __assign({ className: "select_wrapper" }, { children: [_jsx(Select, { className: "medicine_schedule_select", classNamePrefix: "dropdown", theme: function (selectTheme) { return (__assign(__assign({}, selectTheme), { borderRadius: 5, colors: __assign(__assign({}, selectTheme.colors), { danger: theme.darkRed, dangerLight: theme.lightRed, primary25: theme.lightBlue, primary50: theme.dodgerBlue, primary75: theme.dodgerBlue, primary: theme.lightBlue }) })); }, value: options.find(function (o) { return o.value === selectedScheduleId; }), onChange: function (option) {
                        setSelectedScheduleId(option === null || option === void 0 ? void 0 : option.value);
                        onChange(option === null || option === void 0 ? void 0 : option.value, !!(option === null || option === void 0 ? void 0 : option.isAvailable));
                    }, options: options, placeholder: formatMessage(globalMessages.selectPlaceholder) }), validationFailed && _jsx("span", __assign({ className: 'error' }, { children: formatMessage(messages.medicationPlanValidationError) }))] })) })));
};
export default MedicineScheduleSelect;
var StyledContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .select_wrapper {\n      margin: 0 0 24px 0;\n    }\n  }\n  .medicine_schedule_select {\n    .dropdown__control {\n      height: 34px;\n      min-height: 34px;\n      box-sizing: border-box;\n    }\n\n    .dropdown__indicators {\n      height: 32px;\n    }\n\n    .dropdown__single-value,\n    .dropdown__placeholder {\n      color: ", ";\n      font-weight: 600;\n      font-size: 16px;\n      overflow: visible;\n    }\n\n    .dropdown__option--is-focused {\n      color: ", ";\n    }\n  }\n\n  .error {\n    color: #fa1337;\n    font-size: 1.2rem;\n    margin-left: 5;\n  }\n"], ["\n  .select_wrapper {\n      margin: 0 0 24px 0;\n    }\n  }\n  .medicine_schedule_select {\n    .dropdown__control {\n      height: 34px;\n      min-height: 34px;\n      box-sizing: border-box;\n    }\n\n    .dropdown__indicators {\n      height: 32px;\n    }\n\n    .dropdown__single-value,\n    .dropdown__placeholder {\n      color: ", ";\n      font-weight: 600;\n      font-size: 16px;\n      overflow: visible;\n    }\n\n    .dropdown__option--is-focused {\n      color: ", ";\n    }\n  }\n\n  .error {\n    color: #fa1337;\n    font-size: 1.2rem;\n    margin-left: 5;\n  }\n"])), function (props) { return (props.selectedIsAvailable ? function (_a) {
    var theme = _a.theme;
    return theme.lynch;
} : function (_a) {
    var theme = _a.theme;
    return theme.darkOrange;
}); }, function (_a) {
    var theme = _a.theme;
    return theme.white;
});
var templateObject_1;

import { useReducer } from 'react';
import produce from 'immer';
import { PatientDetailTypeKeys } from './types';
var initialState = {
    patient: undefined,
};
var patientDetailReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    return produce(state, function (draft) {
        switch (action.type) {
            case PatientDetailTypeKeys.SET_PATIENT:
                draft.patient = action.patient;
                return;
            case PatientDetailTypeKeys.CLEAR_PATIENT_DETAIL:
                return initialState;
        }
    });
};
export var usePatientDetail = function () {
    var _a = useReducer(patientDetailReducer, initialState), state = _a[0], dispatch = _a[1];
    return [state, dispatch];
};

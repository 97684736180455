/*
 * Admin Messages
 *
 * This contains the messages to translate the users permissions and role description checkboxes in the permissions modal
 * both when adding new users and editing existing user.
 * Also translations that are used in the role column in the users table -> displays through the helper.ts function
 */
import { defineMessages } from 'react-intl';
export var scope = 'app.components.Admin';
export default defineMessages({
    adminPermission: {
        id: "".concat(scope, ".adminPermission"),
        defaultMessage: 'Administratör',
    },
    adminPermissionDescription: {
        id: "".concat(scope, ".adminPermissionDescription"),
        defaultMessage: 'Lägg till och ta bort behörig personal samt redigera checklista',
    },
    practitionerPermission: {
        id: "".concat(scope, ".practitionerPermission"),
        defaultMessage: 'Personal',
    },
    practitionerPermissionDescription: {
        id: "".concat(scope, ".practitionerPermissionDescription"),
        defaultMessage: 'För personal som hanterar patienter dagligen',
    },
    userPermission: {
        id: "".concat(scope, ".userPermission"),
        defaultMessage: 'Användare',
    },
});

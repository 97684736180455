import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Button from '../Button';
import Step01 from '../../resources/img/cal_instructions_step01.png';
import Step02 from '../../resources/img/cal_instructions_step02.png';
import Step03 from '../../resources/img/cal_instructions_step03.png';
import Modal from '../Modal/Modal';
import ModalFooter from 'components/Modal/ModalFooterButtons';
import globalMessages from '@src/globalMessages';
import messages from './messages';

export default class CalendarHelpModal extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    dismiss: PropTypes.func.isRequired,
  };
  render() {
    return (
      <Modal maxWidth="744px" title={<FormattedMessage {...messages.headerInstructions} />} dismiss={this.props.dismiss} disableBoxShadow={true}>
        <StyledContent>
          {instructions.map((item, i) => (
            <InstructionStep key={`InstructionStep_${i}`} title={item.title} text={item.text} imgSrc={item.imgSrc} />
          ))}
          <ModalFooter className="modalFooter">
            <Button medium hover onClick={() => this.props.dismiss()}>
              <FormattedMessage {...globalMessages.close} />
            </Button>
          </ModalFooter>
        </StyledContent>
      </Modal>
    );
  }
}

let instructions = [
  {
    title: <FormattedMessage {...messages.titleInstructionStep1} />,
    text: <FormattedMessage {...messages.textInstructionStep1} />,
    imgSrc: Step01,
  },
  {
    title: <FormattedMessage {...messages.titleInstructionStep2} />,
    text: <FormattedMessage {...messages.textInstructionStep2} />,
    imgSrc: Step02,
  },
  {
    title: <FormattedMessage {...messages.titleInstructionStep3} />,
    text: <FormattedMessage {...messages.textInstructionStep3} />,
    imgSrc: Step03,
  },
];

const InstructionStep = ({ title, text, imgSrc }) => (
  <div className="stepContainer">
    <div className="textContainer">
      <h3>{title}</h3>
      <p>{text}</p>
    </div>
    <div>
      <img src={imgSrc} alt={title} height="138px" width="214px" />
    </div>
  </div>
);

const StyledContent = styled.div`
  padding-top: 0;
  text-align: center;
  color: ${({ theme }) => theme.cello};
  font-size: 16px;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  .userIcon {
    margin-top: 21px;
    width: 120;
    height: 120;
  }
  h2 {
    margin-bottom: 28px;
    font-size: 32px;
    font-weight: bold;
    line-height: 43px;
  }
  h3 {
    margin-top: 0px;
  }
  p {
    margin: 0;
  }

  .stepContainer {
    display: flex;
    margin: auto;
    padding: 24px 32px;
    align-items: center;
  }

  .stepContainer {
    display: flex;
    margin: auto;
    align-items: center;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-right: 36px;
    max-width: 364px;
  }

  .modalFooter {
    box-shadow: none;
    margin-top: 60px;
  }
`;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import styled from '@emotion/styled';
var FloatingActionButton = function (_a) {
    var actions = _a.actions, icon = _a.icon;
    var ref = useRef(null);
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var handleClickOutside = function () {
        setIsOpen(false);
    };
    useOnClickOutside(ref, handleClickOutside);
    return (_jsxs(ListContainer, __assign({ ref: ref }, { children: [_jsx(Button, __assign({ tabIndex: 0, onClick: function () { return setIsOpen(!isOpen); } }, { children: icon && _jsx("img", { src: icon, alt: "help" }) })), isOpen && (_jsx(ActionsList, { children: actions.map(function (action, index) { return (_jsx("li", { children: _jsxs("span", { children: [_jsx("img", { src: action.icon }), _jsx("a", __assign({ href: action.url, target: "_blank", rel: "noopener noreferrer" }, { children: action.label }))] }) }, index)); }) }))] })));
};
export default FloatingActionButton;
var ListContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  right: 3rem;\n  bottom: 30rem;\n  max-height: 3.25rem;\n"], ["\n  position: fixed;\n  right: 3rem;\n  bottom: 30rem;\n  max-height: 3.25rem;\n"])));
var Button = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 8rem;\n  height: 8rem;\n  background: #374a6a;\n  box-shadow: 3px 7px 8px 0px #a4a5a5;\n  border-radius: 100%;\n  position: fixed;\n  bottom: 2.25rem;\n  right: 2.25rem;\n  color: #fff;\n  text-align: center;\n  font-size: 1.75rem;\n  border: none;\n\n  :hover {\n    background: #53698e;\n    transition: all 0.3s ease-in-out;\n  }\n\n  img {\n    width: 4.25rem;\n    height: 4.25rem;\n    margin-left: 1rem;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 8rem;\n  height: 8rem;\n  background: #374a6a;\n  box-shadow: 3px 7px 8px 0px #a4a5a5;\n  border-radius: 100%;\n  position: fixed;\n  bottom: 2.25rem;\n  right: 2.25rem;\n  color: #fff;\n  text-align: center;\n  font-size: 1.75rem;\n  border: none;\n\n  :hover {\n    background: #53698e;\n    transition: all 0.3s ease-in-out;\n  }\n\n  img {\n    width: 4.25rem;\n    height: 4.25rem;\n    margin-left: 1rem;\n  }\n"])));
var ActionsList = styled.ul(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: #fff;\n  list-style: none;\n  box-shadow: 0 3px 6px #d3d3d3;\n  padding: 0.75rem;\n  margin: 0;\n  border-radius: 8px;\n\n  li {\n    padding: 5px;\n    border-bottom: 1px solid #d3d3d3;\n    cursor: pointer;\n\n    :last-of-type {\n      border-bottom: none;\n    }\n\n    span {\n      display: flex;\n      width: 100%;\n      font-size: 1.75rem;\n      padding: 1.5rem;\n      border-radius: 8px;\n      :hover {\n        background: #e4ebf6;\n        transition: all 0.3s ease-in-out;\n      }\n      img {\n        margin-right: 1rem;\n      }\n      a {\n        font-size: 1.75rem;\n        text-decoration: none;\n        color: inherit;\n      }\n    }\n  }\n"], ["\n  background: #fff;\n  list-style: none;\n  box-shadow: 0 3px 6px #d3d3d3;\n  padding: 0.75rem;\n  margin: 0;\n  border-radius: 8px;\n\n  li {\n    padding: 5px;\n    border-bottom: 1px solid #d3d3d3;\n    cursor: pointer;\n\n    :last-of-type {\n      border-bottom: none;\n    }\n\n    span {\n      display: flex;\n      width: 100%;\n      font-size: 1.75rem;\n      padding: 1.5rem;\n      border-radius: 8px;\n      :hover {\n        background: #e4ebf6;\n        transition: all 0.3s ease-in-out;\n      }\n      img {\n        margin-right: 1rem;\n      }\n      a {\n        font-size: 1.75rem;\n        text-decoration: none;\n        color: inherit;\n      }\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;

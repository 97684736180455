import { defineMessages } from 'react-intl';
var scope = 'app.pages.Admin.OrganizationPage.Modal.CreateOrganizationModal';
var errorScope = 'app.pages.Admin.OrganizationPage.Modal.CreateOrganizationModal.Errors';
export default defineMessages({
    headerAdd: {
        id: "".concat(scope, ".headerAdd"),
        defaultMessage: 'Lägg till organisation',
    },
    headerEdit: {
        id: "".concat(scope, ".headerEdit"),
        defaultMessage: 'Hantera organisation',
    },
    hsaidFieldLabel: {
        id: "".concat(scope, ".hsaidFieldLabel"),
        defaultMessage: 'HSA-ID',
    },
    icon: {
        id: "".concat(scope, ".icon"),
        defaultMessage: 'Ikon',
    },
    iconUpload: {
        id: "".concat(scope, ".iconUpload"),
        defaultMessage: 'Välj ikon',
    },
    iconDescription: {
        id: "".concat(scope, ".iconDescription"),
        defaultMessage: 'Ladda upp ikon på minst 90x90 pixlar',
    },
    nameFieldLabel: {
        id: "".concat(scope, ".nameFieldLabel"),
        defaultMessage: 'NAMN',
    },
    moduleBoarding: {
        id: "".concat(scope, ".moduleBoarding"),
        defaultMessage: 'Sängplatser',
    },
    moduleBoardingDescription: {
        id: "".concat(scope, ".moduleBoardingDescription"),
        defaultMessage: 'Lägg till sängplatser vid patientregistrering',
    },
    moduleChecklist: {
        id: "".concat(scope, ".moduleChecklist"),
        defaultMessage: 'Checklistor',
    },
    moduleChecklistDescription: {
        id: "".concat(scope, ".moduleChecklistDescription"),
        defaultMessage: 'Fyll i och se checklista',
    },
    moduleDiagnosis: {
        id: "".concat(scope, ".moduleDiagnosis"),
        defaultMessage: 'Diagnoskoder',
    },
    moduleDiagnosisDescription: {
        id: "".concat(scope, ".moduleDiagnosisDescription"),
        defaultMessage: 'Skapa diagnoskoder',
    },
    moduleExportHistory: {
        id: "".concat(scope, ".moduleExportHistory"),
        defaultMessage: 'Exportera historik',
    },
    moduleExportHistoryDescription: {
        id: "".concat(scope, ".moduleExportHistoryDescription"),
        defaultMessage: 'Exportera smärtskattningshistorik',
    },
    moduleInformation: {
        id: "".concat(scope, ".moduleInformation"),
        defaultMessage: 'Information',
    },
    moduleInformationDescription: {
        id: "".concat(scope, ".moduleInformationDescription"),
        defaultMessage: 'Viktig information för patienter via app',
    },
    modulePainAssessment: {
        id: "".concat(scope, ".modulePainAssessment"),
        defaultMessage: 'Smärtskattning',
    },
    modulePainAssessmentDescription: {
        id: "".concat(scope, ".modulePainAssessmentDescription"),
        defaultMessage: 'Låt patienter smärtskatta via app',
    },
    moduleMedicationPlan: {
        id: "".concat(scope, ".moduleMedicationPlan"),
        defaultMessage: 'Medicinschema',
    },
    moduleMedicationPlanDescription: {
        id: "".concat(scope, ".moduleMedicationPlanDescription"),
        defaultMessage: 'Se när du ska ta din medicin',
    },
    moduleOrganizationInfo: {
        id: "".concat(scope, ".moduleOrganizationInfo"),
        defaultMessage: 'Information om organisationen',
    },
    moduleOrganizationInfoDescription: {
        id: "".concat(scope, ".moduleOrganizationInfoDescription"),
        defaultMessage: 'Länk till hemsida',
    },
    moduleTvMonitor: {
        id: "".concat(scope, ".moduleTvMonitor"),
        defaultMessage: 'TV-monitor',
    },
    moduleTvMonitorDescription: {
        id: "".concat(scope, ".moduleTvMonitorDescription"),
        defaultMessage: 'Visa patientöversikt på tv',
    },
    urlFieldLabel: {
        id: "".concat(scope, ".urlFieldLabel"),
        defaultMessage: 'URL',
    },
    createOrganizationErrorMessage: {
        id: "".concat(errorScope, ".createOrganizationErrorMessage"),
        defaultMessage: 'Det gick inte att skapa organisationen',
    },
    hsaidFieldValidationError: {
        id: "".concat(errorScope, ".hsaidFieldValidationError"),
        defaultMessage: 'HSA-ID saknas',
    },
    modulesValidationErrorMessage: {
        id: "".concat(errorScope, ".modulesValidationErrorMessage"),
        defaultMessage: 'Välj minst en modul',
    },
    nameFieldValidationError: {
        id: "".concat(errorScope, ".nameFieldValidationError"),
        defaultMessage: 'Namn saknas',
    },
    urlFieldValidationError: {
        id: "".concat(errorScope, ".urlFieldValidationError"),
        defaultMessage: 'Felaktig URL, ex: https://zengio.se',
    },
});

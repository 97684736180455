import React from 'react';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';

import PropTypes from 'prop-types';
import DatePicker from '../DatePicker';
import messages from '@src/pages/Export/messages';

export default class IntervalPicker extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    disableHeadline: PropTypes.bool,
    startTitle: PropTypes.string,
    endTitle: PropTypes.string,
    colorful: PropTypes.bool,
  };

  static defaultProps = {
    startTitle: 'Välj startdatum',
    endTitle: 'Välj slutdatum',
  };

  state = {
    startDate: null,
    endDate: null,
  };

  startDateChangeHandler = (date) => {
    this.setState({ startDate: date }, this.raiseOnChangeEvent);
  };

  endDateChangeHandler = (date) => {
    this.setState({ endDate: date }, this.raiseOnChangeEvent);
  };

  raiseOnChangeEvent = () => {
    if (this.props.onChange) {
      this.props.onChange({ startDate: this.state.startDate, endDate: this.state.endDate });
    }
  };

  render() {
    const { disableHeadline, className, colorful, startTitle, endTitle, clearedDates, ...rest } = this.props;

    return (
      <StyledIntervalPicker disableHeadline={disableHeadline} className={className}>
        {!disableHeadline && (
          <div className="intervalpicker__headline">
            <p className="headline">
              <FormattedMessage {...messages.modalSubheadline} />
            </p>
          </div>
        )}
        <div className="intervalpicker__datepickers">
          <div className="intervalpicker__datepicker-start">
            <DatePicker {...rest} cleared={clearedDates} colorful={colorful} placeholderText={startTitle} onChange={this.startDateChangeHandler} />
          </div>
          <span id="intervalpicker__splitter">-</span>
          <div className="intervalpicker__datepicker-end">
            <DatePicker {...rest} cleared={clearedDates} colorful={colorful} placeholderText={endTitle} onChange={this.endDateChangeHandler} />
          </div>
        </div>
      </StyledIntervalPicker>
    );
  }
}

const StyledIntervalPicker = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .intervalpicker__headline {
    width: 100%;
    color: ${({ theme }) => theme.slateGray};
    border-bottom: 1px solid ${({ theme }) => theme.athensGray};
    p {
      margin: 0 0 10px 0;
      font-size: 11px;
    }
  }
  .intervalpicker__datepickers {
    margin-top: ${({ disableHeadline }) => (disableHeadline ? 0 : '23px')};
  }
  #intervalpicker__splitter {
    display: inline-block;
    margin: 0 10px;
  }
  .intervalpicker__datepicker-start {
    display: inline-block;
  }
  .intervalpicker__datepicker-end {
    display: inline-block;
  }
`;

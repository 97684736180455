var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import Modal from '@components/Modal/Modal';
import Button from '@components/Button';
import ModalFooter from '@components/Modal/ModalFooterButtons';
import globalMessages from '@src/globalMessages';
var OrganizationModal = function (_a) {
    var dismiss = _a.dismiss, organizations = _a.organizations, selectOrganization = _a.selectOrganization;
    var formatMessage = useIntl().formatMessage;
    var _b = useState(-1), selectedOrganizationId = _b[0], setSelectedOrganizationId = _b[1];
    var handleSelectOrganization = function () {
        if (selectedOrganizationId !== -1) {
            selectOrganization(selectedOrganizationId);
        }
    };
    return (_jsx(Modal, __assign({ maxWidth: "845px", title: "V\u00E4lj organisation", dismiss: dismiss }, { children: _jsxs(StyledContent, { children: [_jsx("div", { children: _jsxs("select", __assign({ value: selectedOrganizationId, onChange: function (e) { return setSelectedOrganizationId(parseInt(e.target.value)); } }, { children: [_jsxs("option", __assign({ value: -1 }, { children: [formatMessage(globalMessages.selectPlaceholder), " "] })), organizations.map(function (organization) { return (_jsx("option", __assign({ value: organization.id }, { children: "".concat(organization.name, " (").concat(organization.hsaId, ")") }), organization.id)); })] })) }), _jsxs(ModalFooter, { children: [_jsx(Button, __assign({ medium: true, hover: true, onClick: function () { return dismiss(); } }, { children: _jsx("span", { children: formatMessage(globalMessages.cancel) }) })), _jsx(Button, __assign({ primary: true, medium: true, hover: true, disabled: selectedOrganizationId === -1, onClick: handleSelectOrganization }, { children: _jsx("span", { children: formatMessage(globalMessages.login) }) }))] })] }) })));
};
var StyledContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n"])));
export default OrganizationModal;
var templateObject_1;

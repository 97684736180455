import styled from '@emotion/styled';
export default styled.div`
  display: flex;
  min-width: 0;
  overflow-x: auto;

  .scrollItem:focus {
    outline: none;
  }

  .scrollItem {
    cursor: pointer;
    font-weight: bold;
    font-size: 2.5rem;
    padding: 2rem 0;
    min-width: 11rem;
    margin: 1rem 0;
    margin-right: 10px;
    background: ${({ theme }) => theme.blueGrey};
    border: 3px solid ${({ theme }) => theme.blueGrey};
  }
  ::-webkit-scrollbar {
    height: 0.75rem;
    padding: 1rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.blueGrey};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.loblolly};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.loblolly};
  }
  @media (hover: none) {
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
var InputBox = function (_a) {
    var onChange = _a.onChange, title = _a.title, defaultValue = _a.defaultValue, readOnly = _a.readOnly, errorMessage = _a.errorMessage, onBlur = _a.onBlur, className = _a.className, tabIndex = _a.tabIndex, value = _a.value;
    return (_jsxs(StyledContent, __assign({ className: className }, { children: [title && _jsx("p", { children: title }), _jsx("input", { className: readOnly ? 'readonly-input' : '', readOnly: readOnly, defaultValue: defaultValue, value: value, onChange: onChange, onBlur: onBlur, tabIndex: tabIndex }), errorMessage && (_jsx("div", __assign({ className: "error" }, { children: _jsx("p", { children: errorMessage }) })))] })));
};
export default InputBox;
var StyledContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .error {\n    min-height: 14px;\n    p {\n      margin: 6px 0;\n      padding: 0;\n      font-family: 'Open Sans', sans-serif;\n      font-size: 10px;\n      font-weight: 600;\n      color: ", ";\n      letter-spacing: -0.22px;\n      line-height: 14px;\n    }\n  }\n  input {\n    height: 34px;\n    width: 100%;\n    margin: 2px 0;\n    padding: 8px 10px;\n    border: 1px solid #d2d8dd;\n    border-radius: 3px;\n  }\n\n  .readonly-input {\n    cursor: not-allowed;\n    border-style: dashed;\n  }\n"], ["\n  .error {\n    min-height: 14px;\n    p {\n      margin: 6px 0;\n      padding: 0;\n      font-family: 'Open Sans', sans-serif;\n      font-size: 10px;\n      font-weight: 600;\n      color: ", ";\n      letter-spacing: -0.22px;\n      line-height: 14px;\n    }\n  }\n  input {\n    height: 34px;\n    width: 100%;\n    margin: 2px 0;\n    padding: 8px 10px;\n    border: 1px solid #d2d8dd;\n    border-radius: 3px;\n  }\n\n  .readonly-input {\n    cursor: not-allowed;\n    border-style: dashed;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.flushMahogany;
});
var templateObject_1;

import { defineMessages } from 'react-intl';
var scope = 'app.components.Calendar';
var errorScope = 'app.components.Calendar.Errors';
var modalScope = 'app.components.Calendar.Modal';
export default defineMessages({
    buttonPlan: {
        id: "".concat(scope, ".buttonPlan"),
        defaultMessage: 'Planera',
    },
    buttonShare: {
        id: "".concat(scope, ".buttonShare"),
        defaultMessage: 'Dela',
    },
    fieldLabelColor: {
        id: "".concat(modalScope, ".fieldLabelColor"),
        defaultMessage: 'Färg',
    },
    fieldLabelEmail: {
        id: "".concat(modalScope, ".fieldLabelEmail"),
        defaultMessage: 'Email',
    },
    fieldLabelName: {
        id: "".concat(modalScope, ".fieldLabelName"),
        defaultMessage: 'Namn',
    },
    labelType: {
        id: "".concat(scope, ".labelType"),
        defaultMessage: 'TYP',
    },
    typeLabelResource: {
        id: "".concat(scope, ".typeLabelResource"),
        defaultMessage: 'Resurs',
    },
    typeLabelRoom: {
        id: "".concat(scope, ".typeLabelRoom"),
        defaultMessage: 'Rum',
    },
    typeLabelActivity: {
        id: "".concat(scope, ".typeLabelActivity"),
        defaultMessage: 'Aktivitet',
    },
    headerInstructions: {
        id: "".concat(modalScope, ".headerInstructions"),
        defaultMessage: 'Instruktioner',
    },
    shareCalendar: {
        id: "".concat(modalScope, ".shareCalendar"),
        defaultMessage: 'Dela kalender',
    },
    titleInstructionStep1: {
        id: "".concat(modalScope, ".titleInstructionStep1"),
        defaultMessage: '1. Välj deltagare',
    },
    titleInstructionStep2: {
        id: "".concat(modalScope, ".titleInstructionStep2"),
        defaultMessage: '2. Välj dag och tid',
    },
    titleInstructionStep3: {
        id: "".concat(modalScope, ".titleInstructionStep3"),
        defaultMessage: '3. Välj detaljer och slutför bokning',
    },
    textInstructionStep1: {
        id: "".concat(modalScope, ".textInstructionStep1"),
        defaultMessage: 'Tryck på en kategori i vänsterpanelen. Välj en eller flera patienter, kombinera med en aktivitet, resurs eller rum som du vill se schema för och/eller boka in en tid med.',
    },
    textInstructionStep2: {
        id: "".concat(modalScope, ".textInstructionStep2"),
        defaultMessage: 'Navigera dig till rätt dag och välj en tid i kalendern genom att trycka i ett ledigt block. Högerpanelen öppnas automatiskt för mer detaljer om bokningen.',
    },
    textInstructionStep3: {
        id: "".concat(modalScope, ".textInstructionStep3"),
        defaultMessage: 'I högerpanelen kontrollerar/ändrar du och lägger till detaljer i bokningen. Tryck på spara och välj sedan att lägga till flera bokningar eller bekräfta bokningen genom att markera den, och sedan trycka på boka (antal) rader.',
    },
    nameFieldValidationError: {
        id: "".concat(errorScope, ".nameFieldValidationError"),
        defaultMessage: 'Ange namn',
    },
    typeValidationError: {
        id: "".concat(errorScope, ".typeValidationError"),
        defaultMessage: 'Välj typ',
    },
});

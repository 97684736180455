import { defineMessages } from 'react-intl';
export var scope = 'app.pages.MonitorConnectPage';
export var modalScope = 'app.pages.MonitorConnectPage.Modal';
export var errorScope = 'app.pages.MonitorConnectPage.Modal.Errors';
export default defineMessages({
    monitorConnectPageSubHeader: {
        id: "".concat(scope, ".monitorConnectPageSubHeader"),
        defaultMessage: 'Här kan du starta visning av den anonymiserade patientöversikten (https://personal.zengio.se/tv) på TV-monitor. Visningen är aktiv 24 h och därefter krävs att den startas på nytt.',
    },
    monitorConnectTvMonitor: {
        id: "".concat(scope, ".monitorConnectTvMonitor"),
        defaultMessage: 'Starta TV-monitor',
    },
    monitorConnectInstructions: {
        id: "".concat(modalScope, ".monitorConnectInstructions"),
        defaultMessage: 'För att starta - ange koden du ser på TV-monitorn',
    },
    codeValidationError: {
        id: "".concat(errorScope, ".codeValidationError"),
        defaultMessage: 'Koden måste innehålla 6 siffror',
    },
    invalidCodeErrorMessage: {
        id: "".concat(errorScope, ".invalidCodeErrorMessage"),
        defaultMessage: 'Fel kod! Återställ och försök igen!',
    },
});

/*
 * Data table and Graph Messages
 *
 * This contains the message translations that are used in the patient detail page data table and graph
 */
import { defineMessages } from 'react-intl';
var scope = 'app.components.Vas.DataTableGraph';
export default defineMessages({
    alertCopiedSuccess: {
        id: "".concat(scope, ".alertCopiedSuccess"),
        defaultMessage: 'Värden sparade i urklipp.',
    },
    copiedClipboardDataHeadlines: {
        id: "".concat(scope, ".copiedClipboardDataHeadlines"),
        defaultMessage: 'Datum        Tid     Värde',
    },
    date: {
        id: "".concat(scope, ".date"),
        defaultMessage: 'DATUM',
    },
    fromDate: {
        id: "".concat(scope, ".fromDate"),
        defaultMessage: 'Från datum',
    },
    pain: {
        id: "".concat(scope, ".pain"),
        defaultMessage: 'Smärta',
    },
    nausea: {
        id: "".concat(scope, ".nausea"),
        defaultMessage: 'Illamående',
    },
    time: {
        id: "".concat(scope, ".time"),
        defaultMessage: 'TID',
    },
    toDate: {
        id: "".concat(scope, ".toDate"),
        defaultMessage: 'Till datum',
    },
    todaysDate: {
        id: "".concat(scope, ".todaysDate"),
        defaultMessage: 'Idag',
    },
    tooltipCopyClipboard: {
        id: "".concat(scope, ".tooltipCopyClipboard"),
        defaultMessage: 'Kopiera värden',
    },
    tooltipToggleGraph: {
        id: "".concat(scope, ".tooltipToggleGraph"),
        defaultMessage: 'Graf',
    },
    tooltipToggleTable: {
        id: "".concat(scope, ".tooltipToggleTable"),
        defaultMessage: 'Tabell',
    },
    tooltipZoomIn: {
        id: "".concat(scope, ".tooltipZoomIn"),
        defaultMessage: 'Zooma in',
    },
    tooltipZoomOut: {
        id: "".concat(scope, ".tooltipZoomOut"),
        defaultMessage: 'Zooma ut',
    },
    unknown: {
        id: "".concat(scope, ".unknown"),
        defaultMessage: 'OKÄND',
    },
});

/*
 * Patient Form Messages
 *
 * This contains the message translations that are used in the patient form
 * modal and associated validation errors
 */
import { defineMessages } from 'react-intl';
var scope = 'app.pages.PatientFormModal';
var errorScope = 'app.pages.PatientFormModal.Errors';
export default defineMessages({
    modalHeaderAdd: {
        id: "".concat(scope, ".modalHeaderAdd"),
        defaultMessage: 'Lägg till patient',
    },
    modalHeaderEdit: {
        id: "".concat(scope, ".modalHeaderEdit"),
        defaultMessage: 'Ändra uppgifter',
    },
    save: {
        id: "".concat(scope, ".save"),
        defaultMessage: 'Spara',
    },
    dateOfBirth: {
        id: "".concat(scope, ".dateOfBirth"),
        defaultMessage: 'Födelseår',
    },
    genderLabel: {
        id: "".concat(scope, ".gender"),
        defaultMessage: 'KÖN',
    },
    genderMale: {
        id: "".concat(scope, ".genderMale"),
        defaultMessage: 'Man',
    },
    genderFemale: {
        id: "".concat(scope, ".genderFemale"),
        defaultMessage: 'Kvinna',
    },
    initials: {
        id: "".concat(scope, ".initials"),
        defaultMessage: 'Initialer',
    },
    ssn: {
        id: "".concat(scope, ".ssn"),
        defaultMessage: 'PERSONNUMMER',
    },
    name: {
        id: "".concat(scope, ".name"),
        defaultMessage: 'FÖRNAMN',
    },
    surName: {
        id: "".concat(scope, ".surName"),
        defaultMessage: 'EFTERNAMN',
    },
    // room: {
    //   id: `${scope}.room`,
    //   defaultMessage: 'RUM'
    // },
    bed: {
        id: "".concat(scope, ".bed"),
        defaultMessage: 'PLATS',
    },
    diagnosis: {
        id: "".concat(scope, ".diagnosis"),
        defaultMessage: 'DIAGNOS',
    },
    medicationSchema: {
        id: "".concat(scope, ".medicationSchema"),
        defaultMessage: 'MEDICINSCHEMA',
    },
    ssnConflictError: {
        id: "".concat(errorScope, ".ssnConflictError"),
        defaultMessage: 'Patient finns redan inskriven',
    },
    ssnValidationError: {
        id: "".concat(errorScope, ".ssnValidationError"),
        defaultMessage: 'Inget eller felaktigt format på angivet personnummer',
    },
    genderValidationError: {
        id: "".concat(errorScope, ".genderValidationError"),
        defaultMessage: 'Välj kön',
    },
    firstNameValidationError: {
        id: "".concat(errorScope, ".firstNameValidationError"),
        defaultMessage: 'Ange förnamn',
    },
    surnameValidationError: {
        id: "".concat(errorScope, ".surnameValidationError"),
        defaultMessage: 'Ange efternamn',
    },
    diagnoseValidationError: {
        id: "".concat(errorScope, ".diagnosValidationError"),
        defaultMessage: 'Välj diagnos',
    },
    medicationPlanValidationError: {
        id: "".concat(errorScope, ".medicationPlanValidationError"),
        defaultMessage: 'Välj medicinschema',
    },
});

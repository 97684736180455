import React, { PureComponent } from 'react';
import * as moment from 'moment';
import * as actions from 'constants/actions';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { faCircle, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import PropTypes from 'prop-types';

import { bookDrafts, getEvent } from 'actions/calendar';
import { SidePane, SidePaneFooter, SidePaneHeader, SidePaneHeaderIcon } from 'components/Calendar/SidePane/SidePane';
import Button from 'components/Button';
import messages from './messages';
import './draftspane.css';

class DraftsPane extends PureComponent {
  static propTypes = {
    startEditEvent: PropTypes.func.isRequired,
  };

  toggleSelectDraft = (event) => {
    if (this.props.calendar.selectedDrafts.has(event.id)) {
      this.props.dispatch({
        type: actions.UNSELECT_DRAFTS,
        data: [event.id],
      });
    } else {
      this.props.dispatch({
        type: actions.SELECT_DRAFTS,
        data: [event.id],
      });
    }
  };

  selectAllDrafts = () => {
    const ids = this.props.calendar.drafts.map((draft) => draft.id);
    this.props.dispatch({
      type: actions.SELECT_DRAFTS,
      data: ids,
    });
  };

  bookSelectedDrafts = () => {
    const selected = this.props.calendar.selectedDrafts;
    const drafts = this.props.calendar.drafts.filter((draft) => selected.has(draft.id)).map((draft) => ({ id: draft.id }));

    if (drafts.length > 0) {
      this.props.bookDrafts({
        data: drafts,
      });
    }
  };

  showEventDetails = (event) => {
    this.props.getEvent({
      eventId: event.id,
      success: this.props.startEditEvent,
    });
  };

  renderHeader = () => (
    <SidePaneHeader>
      <span>
        <FormattedMessage {...messages.headerDetails} />
      </span>
      <SidePaneHeaderIcon onClick={() => {}}>{/* <FontAwesomeIcon icon={faPrint} /> */}</SidePaneHeaderIcon>
    </SidePaneHeader>
  );

  renderFooter = (selected, drafts) => (
    <SidePaneFooter>
      <Button className="button" disabled={selected.size === drafts.length} hover medium onClick={this.selectAllDrafts}>
        <span>
          <FormattedMessage {...messages.buttonSelectAll} />
        </span>
      </Button>
      <Button className="button" disabled={selected.size === 0} primary hover medium onClick={this.bookSelectedDrafts}>
        <span>
          <FormattedMessage
            {...messages.buttonBookSelected}
            values={{
              size: selected.size,
            }}
          />
        </span>
      </Button>
    </SidePaneFooter>
  );

  render() {
    const drafts = this.props.calendar.drafts;
    const selected = this.props.calendar.selectedDrafts;
    return (
      <SidePane header={this.renderHeader()} footer={this.renderFooter(selected, drafts)}>
        <div className={'drafts-list'}>
          {drafts.map((draft) => (
            <DraftCell
              event={draft}
              selected={selected.has(draft.id)}
              onDetails={this.showEventDetails}
              onSelect={this.toggleSelectDraft}
              key={draft.id}
            />
          ))}
        </div>
      </SidePane>
    );
  }
}

const mapStateToProps = (state) => ({
  calendar: state.calendar,
});

const mapDispatchToProps = (dispatch) => ({
  getEvent: ({ eventId, success, fail }) => dispatch(getEvent({ eventId, success, fail })),
  bookDrafts: ({ data, success, fail }) => dispatch(bookDrafts({ data, success, fail })),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(DraftsPane);

const DraftCell = ({ event, selected, onDetails, onSelect }) => (
  <div className={`draft-item`}>
    <div className="draft-item-selected-icon" onClick={() => onSelect(event)}>
      <FontAwesomeIcon icon={selected ? faCheckCircle : faCircle} />
    </div>
    <div className="draft-item-edit-icon" onClick={() => onDetails(event)}>
      <FontAwesomeIcon icon={faPencilAlt} />
    </div>
    <div className="draft-item-title">{event.title}</div>
    <div className="draft-item-start">{moment.unix(event.start).format('YYYY/MM/DD HH:mm')}</div>
    <div className="draft-item-location">{event.location?.displayName}</div>
    <div className="draft-item-participants">
      {event.participants.length} <FormattedMessage {...messages.eventParticipants} />
    </div>
  </div>
);

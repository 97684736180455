var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import Cell from './Cell';
import { css } from '@emotion/react';
var StyledSubTitleCell = styled(Cell)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", "\n"], ["\n  ", ";\n  ", ";\n  ", "\n"])), function (_a) {
    var compact = _a.compact, subtitle = _a.subtitle;
    if (compact) {
        return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        display: flex;\n        align-items: center;\n        justify-items: center;\n        .title {\n          font-size: 22px;\n          font-weight: bold;\n        }\n\n        .subtitle {\n          white-space: nowrap;\n          font-size: 21px;\n          margin-left: 10px;\n          font-weight: 600;\n        }\n      "], ["\n        display: flex;\n        align-items: center;\n        justify-items: center;\n        .title {\n          font-size: 22px;\n          font-weight: bold;\n        }\n\n        .subtitle {\n          white-space: nowrap;\n          font-size: 21px;\n          margin-left: 10px;\n          font-weight: 600;\n        }\n      "])));
    }
    else {
        var fontSize = subtitle ? '28px' : '20px';
        return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        .title {\n          font-size: ", ";\n          font-weight: bold;\n        }\n\n        .subtitle {\n          white-space: nowrap;\n          font-size: 16px;\n          font-weight: normal;\n        }\n      "], ["\n        .title {\n          font-size: ", ";\n          font-weight: bold;\n        }\n\n        .subtitle {\n          white-space: nowrap;\n          font-size: 16px;\n          font-weight: normal;\n        }\n      "])), fontSize);
    }
}, function (_a) {
    var width = _a.width;
    if (width) {
        return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        width: ", ";\n      "], ["\n        width: ", ";\n      "])), width || '110px');
    }
    else {
        return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n        flex: 2;\n      "], ["\n        flex: 2;\n      "])));
    }
}, function (_a) {
    var minWidth = _a.minWidth;
    if (minWidth) {
        return css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n        min-width: ", ";\n      "], ["\n        min-width: ", ";\n      "])), minWidth);
    }
});
var SubTitleCell = function (_a) {
    var title = _a.title, subtitle = _a.subtitle, compact = _a.compact, width = _a.width, minWidth = _a.minWidth;
    return (_jsxs(StyledSubTitleCell, __assign({ subtitle: subtitle, compact: compact, width: width, minWidth: minWidth }, { children: [_jsx("div", __assign({ className: "title" }, { children: title })), _jsx("div", __assign({ className: "subtitle" }, { children: subtitle }))] })));
};
export default SubTitleCell;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import Button from '@components/Button';
import iconTv from '@resources/img/ikon-tv.svg';
import MonitorConnectModal from '@pages/MonitorConnectPage/MonitorConnectModal';
import messages from './messages';
var MonitorConnectPage = function (_a) {
    var formatMessage = useIntl().formatMessage;
    var _b = useState(false), showModal = _b[0], setShowModal = _b[1];
    return (_jsxs(Wrapper, { children: [_jsx(Heading, __assign({ className: "headlineH1" }, { children: formatMessage(messages.monitorConnectTvMonitor) })), _jsxs(Row, { children: [_jsx("p", __assign({ className: "headline" }, { children: formatMessage(messages.monitorConnectPageSubHeader) })), _jsxs(Button, __assign({ hover: true, onClick: function () {
                            setShowModal(true);
                        } }, { children: [_jsx("img", { src: iconTv }), _jsx("p", { children: formatMessage(messages.monitorConnectTvMonitor) })] }))] }), showModal && (_jsx(MonitorConnectModal, { dismiss: function () {
                    setShowModal(false);
                } }))] }));
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 3rem;\n"], ["\n  padding: 3rem;\n"])));
var Heading = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: inline-block;\n  width: 100%;\n  padding-bottom: 9px;\n  margin-bottom: 24px;\n  border: 0 solid ", ";\n  border-bottom-width: 1px;\n"], ["\n  display: inline-block;\n  width: 100%;\n  padding-bottom: 9px;\n  margin-bottom: 24px;\n  border: 0 solid ", ";\n  border-bottom-width: 1px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.loblolly;
});
var Row = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  p {\n    margin: 0;\n    flex: 1;\n  }\n  button {\n    padding: 0 0rem;\n    display: inline-flex;\n    align-items: center;\n    img {\n      display: inline-block;\n      width: 17px;\n      height: 21px;\n      margin: 5px 6px 5px 12px;\n    }\n    p {\n      display: inline-block;\n      margin-right: 20px;\n    }\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  p {\n    margin: 0;\n    flex: 1;\n  }\n  button {\n    padding: 0 0rem;\n    display: inline-flex;\n    align-items: center;\n    img {\n      display: inline-block;\n      width: 17px;\n      height: 21px;\n      margin: 5px 6px 5px 12px;\n    }\n    p {\n      display: inline-block;\n      margin-right: 20px;\n    }\n  }\n"])));
export default MonitorConnectPage;
var templateObject_1, templateObject_2, templateObject_3;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import InformationBox from './InformationBox';
import iconEdit from '../../resources/img/ikon-andra-200.png';
import messages from '@src/pages/PatientListPage/PatientList/PatientFormModal/messages';
import globalMessages from '@src/globalMessages';
import MedicineScheduleModal from 'components/MedicineSchedule/MedicineScheduleModal';
var MedicineScheduleBox = function (_a) {
    var patient = _a.patient, module = _a.module, moduleTemplate = _a.moduleTemplate;
    var _b = useState(false), showModal = _b[0], setShowModal = _b[1];
    var formatMessage = useIntl().formatMessage;
    return (_jsxs(_Fragment, { children: [_jsx(InformationBox, __assign({ textLeft: formatMessage(messages.medicationSchema), textRight: formatMessage(globalMessages.edit), icon: iconEdit, iconStyle: { width: '9px', height: '9px', marginRight: '6px' }, onLinkClick: function () { return setShowModal(true); }, onBoxClick: function () { return setShowModal(true); } }, { children: _jsx(StyledConcent, { children: _jsx("p", { children: formatMessage({
                            id: "app.pages.PatientFormModal.".concat(module.schedule.name.replace(/\s/g, '').toLocaleLowerCase()),
                            defaultMessage: module.schedule.name,
                        }) || '' }) }) })), showModal && _jsx(MedicineScheduleModal, { patient: patient, module: module, moduleTemplate: moduleTemplate, dismiss: function () { return setShowModal(false); } })] }));
};
export default MedicineScheduleBox;
var StyledConcent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  padding: 17px 15px 15px 15px;\n  p {\n    font-size: 18px;\n    line-height: 27px;\n    font-weight: bold;\n    margin: 0;\n  }\n  .invalid {\n    color: ", ";\n  }\n"], ["\n  color: ", ";\n  padding: 17px 15px 15px 15px;\n  p {\n    font-size: 18px;\n    line-height: 27px;\n    font-weight: bold;\n    margin: 0;\n  }\n  .invalid {\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.cello;
}, function (_a) {
    var theme = _a.theme;
    return theme.darkOrange;
});
var templateObject_1;

import { useContext } from 'react';
import { AuthActionContext } from '@hooks/useAuth/AuthProvider';
var useAuthAction = function () {
    var context = useContext(AuthActionContext);
    if (context === undefined) {
        throw new Error('useAuthAction must be used within a AuthProvider');
    }
    return context;
};
export default useAuthAction;

import { useContext } from 'react';
import { PatientDetailActionContext } from './PatientDetailProvider';
var usePatientDetailAction = function () {
    var context = useContext(PatientDetailActionContext);
    if (context === undefined) {
        throw new Error('usePatientDetailAction must be used within a PatientDetailProvider');
    }
    return context;
};
export default usePatientDetailAction;

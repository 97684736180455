import * as actions from 'constants/actions';

export const initialState = {
  locale: navigator.language ? navigator.language.split('-')[0] : 'sv',
};

const languageProviderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CHANGE_LOCALE:
      return { ...state, locale: action.locale };
    default:
      return state;
  }
};

export default languageProviderReducer;

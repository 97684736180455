import '@babel/polyfill';
import * as am4core from '@amcharts/amcharts4/core';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChartLine,
  faCheckCircle,
  faCheckSquare,
  faChevronLeft,
  faChevronRight,
  faEllipsisH,
  faLongArrowAltRight,
  faLongArrowAltUp,
  faPen,
  faPlus,
  faSave,
  faSignOutAlt,
  faSpinner,
  faSyncAlt,
  faListUl,
  faTimes,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

import { ThemeProvider } from '@emotion/react';

import LanguageProvider from './containers/LanguageProvider';
import configureStore from './store/configureStore';
import { theme } from 'constants/styling';
import EnvironmentBanner from 'components/EnvironmentBanner/EnvironmentBanner';
import 'react-datepicker/dist/react-datepicker.css';
import './main.scss';
import moment from 'moment';
import { enableMapSet } from 'immer';
import App from './app/App';

enableMapSet();
moment.locale('sv');

// import { PersistGate } from 'redux-persist/integration/react';
// const { store, persistor } = configureStore();
am4core.addLicense('CH202082489');
const store = configureStore();
library.add(faChartLine);
library.add(faCheckCircle);
library.add(faCheckSquare);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faEllipsisH);
library.add(faListUl);
library.add(faLongArrowAltRight);
library.add(faLongArrowAltUp);
library.add(faPen);
library.add(faPlus);
library.add(faSave);
library.add(faSignOutAlt);
library.add(faSpinner);
library.add(faSyncAlt);
library.add(faTimes);
library.add(faTimesCircle);

ReactDOM.render(
  <Provider store={store}>
    {/* <PersistGate loading={null} persistor={persistor}> */}
    <LanguageProvider>
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <EnvironmentBanner />
          <App />
        </React.Fragment>
      </ThemeProvider>
    </LanguageProvider>
    {/* </PersistGate> */}
  </Provider>,
  document.getElementById('app'),
);
